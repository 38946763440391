import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

import { fetchComments, setChosenCommentDisplay } from '../../redux/actions'
import { Comment } from './Comment';
import { CommentTitleBar } from './CommentTitleBar'
import { getItemsFromState } from '../common/utility/getItemsFromState';
//import { Link } from '@reach/router';
import { DISPLAY_BOARD, DISPLAY_CLOUD, DISPLAY_NORMAL, DISPLAY_STATISTICS } from '../../redux/actions/types';
import { WordCloud } from './WordCloud';
import { PostItBoard } from './PostItBoard';
import { COLOR_GREEN } from '../../constants'
import { StandaloneStatistics } from './StandaloneStatistics'
//import { Title3 } from '../common/text/Title3';
//import Subtitle from '../common/text/Subtitle';

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	>*
	{
		margin-left: 16px;
	}
`

const Button = styled.button`
	background: ${COLOR_GREEN};
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
	padding: 8px 16px;
	color: white;
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 1.25px;
	font-weight: 500;
	text-align: center;
	border-style: none;
	cursor: pointer;
`

const NoCommentsText = styled.p`
	font-size: 28px;
	font-weight: 500;
	font-style: italic;
	margin: 0;
	text-align: center;
`

class CommentsComponent extends React.Component {

	renderBackButton = () => {
		const { t } = this.props
		return (
			<button onClick={() => setChosenCommentDisplay(DISPLAY_NORMAL)}>{t("lalala??.lalala")}</button>
		)
	}

	componentDidMount() {
		const { children, fetchComments } = this.props
		fetchComments(children)
	}
	
	commentsToCountedWords = (comments) => {
		//console.log("Comments", comments)
		let words = []
		comments.forEach((comment) => {
			//console.log("Comment:", comment)
			if (!comment.hidden) {
				const message = comment.message
					.toLowerCase()
					.replace(new RegExp('[^a-zA-ZæøåÆØÅ* ]', 'g'), "")
					//.replace(new RegExp('\\s+', 'g'), " ")
					.split(" ")
					.filter((item) => item !== "")
				/*console.log({
					message: comment.message,
					lowerCase: comment.message.toLowerCase(),
					replace: comment.message.toLowerCase().replace(new RegExp('[^a-zA-ZæøåÆØÅ* ]'), ""),
					replace2: comment.message
					.toLowerCase()
					.replace(new RegExp('[^a-zA-ZæøåÆØÅ* ]', 'g'), "")
					.replace(new RegExp('\\s+', 'g'), " "),
					result: message
				})*/
				words = [...words, ...message]
			}
			
		})
		//console.log("words:", words)
		let wordsAndCounts = {}
		//console.log("wordsAnd", wordsAndCounts)


		for (const word of words) {
			if (!wordsAndCounts[word]) {
				/*wordsAndCounts[word] = {
					word: word, amount: 0
				}*/
				//console.log("1Word is not in wordsAnd yet", word, wordsAndCounts[word])
				const wordObject = {
					[word]: {
						text: word,
						value: 1
					}
				}
				//console.log("2wordObject", wordObject)
				const newWordsAndCounts = {
					...wordsAndCounts
				}
				//console.log("3newWordsAndCounts", newWordsAndCounts)
				const newExtendedWordsAndCounts = {
					...newWordsAndCounts,
					...wordObject
				}
				//console.log("4newExtendedWordsAndCounts", newExtendedWordsAndCounts)
				wordsAndCounts = newExtendedWordsAndCounts
				//console.log("5overwritten!", wordsAndCounts)
				//console.log(wordsAndCounts[word])
			} else {
				wordsAndCounts[word] = {
					...wordsAndCounts[word],
					value: wordsAndCounts[word].value + 1
				}
			}
		};
		//console.log(wordsAndCounts)
		return Object.values(wordsAndCounts)
		//console.log(values)
	}

	render() {
		const { t, comments, displayMode, setChosenCommentDisplay, statistics } = this.props

		if (!comments.length) return <NoCommentsText>{t("dashboard.comments.no-comments")}</NoCommentsText>
		
		switch (displayMode) {
			case DISPLAY_BOARD:
				return <PostItBoard comments={comments} />
			case DISPLAY_CLOUD:
				const words = this.commentsToCountedWords(comments)
				return <WordCloud words={words} statistics={statistics} />
			case DISPLAY_STATISTICS:
				return <StandaloneStatistics statistics={statistics} />
			case DISPLAY_NORMAL:
			default:
				break
		}

		return (
			<div>
				<CommentTitleBar />

				{
					console.log("Mapping comments", comments)
				}
				{comments.map((comment) => {
					//console.log(">", comment)
					return <Comment key={comment.id}>{comment}</Comment>
				})}

				<ButtonWrapper>
					<Button onClick={() => setChosenCommentDisplay(DISPLAY_STATISTICS)}>
						{t("dashboard.comments.buttons.statistics")}
					</Button>
					<Button onClick={() => setChosenCommentDisplay(DISPLAY_CLOUD)}>
						{t("dashboard.comments.buttons.word-cloud")}
					</Button>
					<Button onClick={() => setChosenCommentDisplay(DISPLAY_BOARD)}>
						{t("dashboard.comments.buttons.note-board")}
					</Button>
				</ButtonWrapper>
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {

	//getFromState(state, "")
	//const commentsExist = 
	/*const comments = commentsExist && ownProps.children.map( (commentId) => (
		state.comments[commentId]
	))*/
	//console.log("mapState", state, ownProps)
	return {
		displayMode: state.selection.commentDisplayMode,
		comments: getItemsFromState(state, ownProps.children, "comments")
	}
}

export const Comments = connect(mapStateToProps, { fetchComments, setChosenCommentDisplay })(
	withTranslation()(CommentsComponent)
)
import React from 'react'
import { Link } from '@reach/router'
import { useTranslation } from 'react-i18next'

import Title from '../common/text/Title'
import TopBarFiller from './TopBarFiller'
import { PATH_CHOOSE_GROUP } from '../../constants';

export const MustChooseGroup = () => {
	const { t } = useTranslation()
	return (
		<div>
			<TopBarFiller />
			
			<Link to={`..${PATH_CHOOSE_GROUP}`}>
				<Title>
					{t("dashboard.elements.choose-group")}
				</Title>
			</Link>
		</div>
	)
}
import styled from 'styled-components'

const TopBarFiller = styled.div`
	height: 80px;
	width: 100%;
	@media print
	{
		display: none;
	}
`

export default TopBarFiller
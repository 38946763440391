import styled from 'styled-components'

export const Item = styled.div`
	width: 15%;
	flex-shrink: 0;
`

export const ItemWide = styled.div`
	width: 100%;
`

export const Fat = styled.div`
	font-weight: 500;
`

export const Centered = styled.div`
	text-align: center;
`

export const Right = styled.div`
	text-align: right;
`

export const SearchBar= styled.input`
	width: 30%;
	height: 35px;
	font-size: 15px;
`
import backend from './backend';

const setAcceptLanguage = language => {
	console.log(`Set 'Accept-Language': '${language}'`)
	if (language === "nb-NO" ||
		language === "no" ||
		language === "nn-NO") {
		// Apply authorization token to every request if logged in
		backend.defaults.headers.common["Accept-Language"] = "no"
	} else {
		// Delete auth header
		backend.defaults.headers.common["Accept-Language"] = "en"
	}
};

export default setAcceptLanguage;
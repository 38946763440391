import {
	CREATE_GROUP, FETCH_GROUPS, FETCH_GROUP, DELETE_GROUP
} from "../actions/types";
import _ from 'lodash';

export default (state = {}, action) => {
	switch (action.type) {
		case FETCH_GROUP:
			return {
				...state,
				[action.payload.id]: {
					...action.payload
				}
			}
		case FETCH_GROUPS:
			return {
				...state,
				...action.payload
			}
		case CREATE_GROUP:
			return {
				...state,
				[action.payload.id]: {
					...action.payload
				}
			}
		case DELETE_GROUP:
			return {
				..._.omit(state, action.payload.id)
			}
		default:
			return state;
	}
}
//import _ from 'lodash';
import {
	SET_CHOSEN_GROUP,
	SET_CHOSEN_CHAPTER,
	SET_CHOSEN_COMMENT_DISPLAY,
	FETCH_LOCAL_SELECTION,
	WIPE_SELECTIONS,
	//DISPLAY_NORMAL
} from '../actions/types';

export default (state = {
	/*
		No default === problems
	*/
	//commentDisplayMode: DISPLAY_NORMAL
}, action) => {
	switch (action.type) {
		case FETCH_LOCAL_SELECTION:
			return {
				...state,
				...action.payload
			}
		case SET_CHOSEN_GROUP:
			//console.log("selectionReducer: action", action)
			return {
				...state,
				...action.payload
			}
		case SET_CHOSEN_CHAPTER:
			return {
				...state,
				chapter: action.payload
			}
		case SET_CHOSEN_COMMENT_DISPLAY:
			return {
				...state,
				commentDisplayMode: action.payload
			}
		case WIPE_SELECTIONS:
			return {}
		default:
			return state;
	}
}
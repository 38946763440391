import styled from 'styled-components'
import LanguageSwitch from '../LanguageSwitch'

export const AbsoluteLanguageSwitch = styled(LanguageSwitch)`
	position: absolute;
	top: 8px;
	right: 16px;
	color: white;
	text-shadow: 0px 0px 10px black;
	width: fit-content;
	font-size: 20px;
	padding: 8px;
`
import React from 'react'
import { connect } from 'react-redux'

import { ArticleEditor } from '../articles/ArticleEditor';
import Title from '../common/text/Title'
import backend from '../../apis/backend';
import { withTranslation } from 'react-i18next';

import { fetchArticle, editArticle } from '../../redux/actions'
import { Tip } from '../common/text/Tip';
import TopBar from '../dashboard/TopBar';
//import { Loading } from '../dashboard/Loading';
//import { LoadingError } from '../dashboard/LoadingError';

class AdminArticleEditComponent extends React.Component {
	
	/*state = {
		fetchSuccess: undefined
	}*/
	
	componentDidMount() {
		//fetch single article
		const { id, fetchArticle } = this.props
		fetchArticle(id)
	}
	
	render() {
		const { t, i18n, editArticle, id, article } = this.props
		
		/*if (this.state.fetchSuccess === undefined) {
			return <Loading />
		}
		if (!this.state.fetchSuccess) {
			return <LoadingError />
		}*/
		if (!article) {
			return <Title>{t("admin.article.edit.article-does-not-exist")}</Title>
		}
		//console.log("ArticleEdit", article)
		return (
			<div>
				<TopBar />
				
				<Title>{t("admin.article.edit.title")}</Title>
				<Tip>{t("admin.article.tip")}</Tip>
		
				<ArticleEditor
					initialValues={{
						...article
					}}
					submitButtonText={t("admin.article.edit.submit")}
					onSubmit={(values, actions) => {
						backend.put(`/articles/${id}`, {
							...values,
							language: i18n.language
						})
							.then( (response) => {
								console.log("Successful response", response)
								const data = response.data.data
								editArticle(id, data)
							})
							.catch( (error) => {
								console.log("Failure response", error, error.response)
								actions.resetForm({...values})
								for(const i in error.response.data.errors) {
									const e = error.response.data.errors[i]
									//console.log("Looping", e)
									
									if (e.code === 10000) {
										//console.log("Code is 10000, setting status: ", e.message)
										actions.setStatus(e.message)
										break
									}
									else {
										//console.log("Code is not 10000, setting error: e.field", e.field, "e.message", e.message)
										actions.setErrors({
											[e.field]: e.message
										})
									}
								}
								//actions.setErrors(error.response)
							})
					}}
				/>
			</div>
		)
	}
	
}

const mapStateToProps = (state, ownProps) => {
	const uncheckedArticle = state.articles.find( article => article.id.toString() === ownProps.id.toString() )
	//Now, ensure the article has a data field. If not, it's only been fetched from the article list and does not have all the fields we need, so make sure it stays undefined!
	const article = uncheckedArticle && uncheckedArticle.data && uncheckedArticle
	
	return {
		isAdmin: state.auth.isAdmin,
		article
	}
}

export const AdminArticleEdit = connect(mapStateToProps, { fetchArticle, editArticle })(
	withTranslation()(AdminArticleEditComponent)
)
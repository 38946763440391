import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { editComment } from '../../redux/actions'

const Wrapper = styled.table`
	width: 100%;
	border-spacing: 0 8px;
	margin: auto;
`

const Header = styled.thead`
	background-color: transparent;
	border: none;
	text-transform: uppercase;
	color: #4F4F4F;
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 8px;
`

const Row = styled.tr`
	
`

const Body = styled.tbody`
	${Row}
	{
		width: 100%;
		background-color: white;
		padding: 16px 24px;
		font-size: 16px;
		margin-bottom: 16px;
		border-bottom: 1px solid #E5E5E5;
	}
`

export const HeadItem = styled.th`
	text-align: ${props => props.align ? props.align : "left"};
	width: ${props => props.width ? props.width : "unset"};
	padding: 16px 8px;
	&:first-child
	{
		padding-left: 24px;
	}
	&:last-child
	{
		padding-right: 24px;
	}
`

export const Item = styled.td`
	text-align: ${props => props.align ? props.align : "left"};
	width: ${props => props.width ? props.width : "unset"};
	padding: 16px 8px;
	&:first-child
	{
		padding-left: 24px;
	}
	&:last-child
	{
		padding-right: 24px;
	}
`

export const CardTableHeader = ({ children }) => (
	<Row>
		{children}
	</Row>
)

export const CardTableItem = ({ children }) => (
	<Row>
		{children}
	</Row>
)

const CardTableComponent = ({header, items, ...rest}) => {
	return (
		<Wrapper {...rest}>
			<Header>
				{header}
			</Header>
			<Body>
				{items}
			</Body>
		</Wrapper>
	)
}
export const CardTable = connect(null, { editComment })(CardTableComponent)
import React from 'react'
//import './MenuItem.scss'

import styled from 'styled-components'
import { COLOR_GREEN } from '../../constants'

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	
	color: ${props => props.color || "rgba(0, 0, 0, 0.87)"};
	font-size: 14px;
	font-weight: 500;
	line-height: 21px;
	letter-spacing: 0.1px;
	margin-top: 0;
	margin-bottom: 4px;
	padding: 11px;
	p
	{
		margin: 0;
	}
	${props => {
		//console.log("Sl", props, props.selected, props.selected && props.selected)
		return (
			props.selected && `
				color: white;
				background-color: ${COLOR_GREEN};
				border-radius: 4px;
				>*:first-child
				{
					color: white;
				}
			`
		)
	}}
`

const IconStyle = styled.div`
	color: ${props => props.color || props.parentColor || "rgba(0,0,0,.54)"};
	display: flex;
	flex-direction: center;
	justify-content: center;
	margin-right: 35px;
`

const Text = styled.p`
	width: 100%;
	text-decoration: none;
	margin: 0;
`

export const MenuItem = ({
	icon,
	color,
	iconColor,
	children,
	text,
	selected,
	...rest
}) => {
	//console.log("text", text, selected)
	return (
		<Wrapper selected={selected} {...rest}>
			<IconStyle>
				{icon}
			</IconStyle>
			{
				text ? (
					<Text>
						{text}
					</Text>
				) :
				<>
					{children}
				</>
			}
		</Wrapper>
	)
}

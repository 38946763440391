import React from 'react';
import styled from 'styled-components'
//import './VersionNumber.scss';
const Wrapper = styled.div`
  position: absolute;
  color: red;
  z-index: 9;
  top: .5rem;
  left: .5rem;
`

export const VersionNumber = ({version}) => {
  if (!version) return <></>
  return (
    <Wrapper>
      <span> {/*className="version-number__value">*/}
        Build: {version || ''}
      </span>
    </Wrapper>
  );
}
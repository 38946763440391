import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link } from '@reach/router'

import shoppingCart from '../../../img/icons/shopping-cart.svg'
import { PATH_ORDER } from '../../../constants'

const Button = styled(Link)`
	background: #BEABC3;
	border: 3px solid #424242;
	border-radius: 13px;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
	margin: 0;
	padding: 0;
`

const InnerButton = styled.div`
	background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%), #E4CEE0;
	border: 3px solid #BEABC3;
	box-sizing: border-box;
	border-radius: 10px;
	padding: 6px 12px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	>*
	{
		margin-right: 0;
		&:not(:last-child)
		{
			margin-right: 16px;
		}
	}
`

const Text = styled.p`
	font-size: 17px;
	text-align: center;
	font-weight: bold;
	letter-spacing: 0.5px;
	color: #000000;
	margin: 0;
`

const Inner = () => {
	const { t } = useTranslation()
	return (
		<InnerButton>
			<img src={shoppingCart} alt="" />
			<Text>{t("landing.order-license")}</Text>
		</InnerButton>
	)
}
export const OrderButtonSmall = () => {
	return (
		<Button to={PATH_ORDER}>
			<Inner />
		</Button>
	)
}
import axios from 'axios';

export const baseURL = process.env.REACT_APP_API_ENDPOINT || 'https://api.mychildlebensbornedu.no/dash/v1/';

console.log('========== ENV. CHECK ==========')
console.log(process.env.REACT_APP_API_ENDPOINT ? 'URL set from .env-file' : 'No URL set in .env-file - using default value.');
console.log('Environment:', (process.env.REACT_NODE_ENV || process.env.NODE_ENV));
console.log('API URL:', baseURL);
console.log('================================')

//const baseURL = (process.env.NODE_ENV === 'prod' || process.env.NODE_ENV === 'production') ? 'https://api-lebensborn.test.ways.as/dash/v1/' : 'https://api-lebensborn.test.ways.as/dash/v1/';

export default axios.create({ baseURL });
import React from 'react'
import styled from 'styled-components'
import { Delete } from '@material-ui/icons'

const Button = styled.button`
	padding: 4px;
	background: none;
	border: none;
	cursor: pointer;
	color: #EB5757;
`

const DefaultText = styled.p`
	color: black;
`

export const DeleteButton = ({ text, textComponent, ...rest }) => {
	const Text = textComponent || DefaultText
	return (
		<Button {...rest}>
			{text && <Text>{text}</Text>}
			<Delete />
		</Button>
	)
}
//import React from 'react'
import styled from 'styled-components'

export const FlexRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: ${props => props.align || "center"};
	justify-content: ${props => props.justify || "center"};
	margin-bottom: ${props => props.margin || "0"};
	>*
	{
		margin-right: ${props => props.gap || "0"};
		&:last-child
		{
			margin-right: 0;
		}
	}
`

export default FlexRow
/*
const FlexRow = ({justifyContent="center", alignItems="center", children, style, ...rest}) => (
	<div
		style={{
			display: "flex",
			alignItems: alignItems,
			justifyContent: justifyContent,
			flexDirection: "row",
			...style
		}}
		{...rest}
	>
		{children}
	</div>
)

export default FlexRow*/
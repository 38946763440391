import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { fetchArticle } from '../../redux/actions'
import { RESOURCES_ARTICLE_NO, RESOURCES_ARTICLE_EN, PATH_SUBJECT_MATERIAL } from '../../constants'
import TopBar from '../dashboard/TopBar'
import { ArticleStyle } from '../articles/ArticleStyle'
import { Loading } from '../dashboard/Loading'

/*
	There should be a nice way of making this thing load the new text without showing a loading screen each time. I'm thinking shifting the some logic into the update function. It's okay if an article is not the right language, so long as it's about to change :)
*/

class ResourcesComponent extends React.Component {
	
	state = {
		fetching: false,
		fetchedLanguage: undefined
	}
	
	getArticle() {
		const { i18n, fetchArticle } = this.props
		fetchArticle(i18n.language === "no" ? RESOURCES_ARTICLE_NO : RESOURCES_ARTICLE_EN)
	}
	
	componentDidUpdate() {
		const { i18n, article } = this.props
		//console.log("Resources.componentDidUpdate()", article, !article, article && article.language, i18n.language)
		if (!article || article.language !== i18n.language) {
			this.getArticle()
		}
	}
	
	componentDidMount() {
		this.getArticle()
	}
	
	render() {
		const { article } = this.props
		if (!article) return <Loading />
		
		return (
			<div>
				<TopBar backPath={`..${PATH_SUBJECT_MATERIAL}`} />
				<ArticleStyle>
					<div dangerouslySetInnerHTML={{ __html: article.data}} />
				</ArticleStyle>
			</div>
		)
	}
};

const mapStateToProps = (state) => {
	const article = state.articles.find( article => (
		article.id.toString() === RESOURCES_ARTICLE_NO.toString() ||
		article.id.toString() === RESOURCES_ARTICLE_EN.toString()
	))
	return {
		article
	}
}

export const Resources = withTranslation()(
	connect(mapStateToProps, { fetchArticle })(ResourcesComponent)
)
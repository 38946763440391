import {
	FETCH_PROFILE,
	EDIT_PROFILE
} from "../actions/types";

export default (state = {}, action) => {
	switch (action.type) {
		case FETCH_PROFILE:
		case EDIT_PROFILE:
			const setFields = Object.keys(action.payload).filter( key => {
				return action.payload[key] !== null
			})
			//console.log("setFields", setFields)
			const setFieldsObject = {}
			setFields.forEach( key => setFieldsObject[key] = action.payload[key] )
			//console.log("setFieldsObject", setFieldsObject)
			return {
				...state,
				...setFieldsObject
			}
		default:
			return state;
	}
}
import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

//import CKEditor from '@ckeditor/ckeditor5-react'
//import LebensbornEditor from 'lebensborneditor'

import { Checkbox } from './Checkbox'
//import backend from '../../apis/backend';
import { fetchFilters } from '../../redux/actions'
import { PrettyField } from '../forms/PrettyField'
import FlexRow from '../common/flex/FlexRow'
import { SquareButton } from '../dashboard/SquareButton'
//import backend from '../../apis/backend';
//import { navigate } from '@reach/router';
import { Loading } from '../dashboard/Loading'
import backend from '../../apis/backend'
//import { DeleteButton } from '../dashboard/DeleteButton'
import { Delete } from '@material-ui/icons'
import { Editor } from '../dashboard/Editor'

const ArticleField = ({name, ...rest}) => {
	return (
		<PrettyField name={name} i18nPath="admin.article.editor" {...rest} />
	)
}

/*const EditorWrapper = styled.div`
	margin-bottom: 16px;
`*/

const Preview = styled.img`
	max-width: 500px;
	height: auto;
`

const Text = styled.p`
	color: white;
	margin: 0;
`

const FilterGroup = styled.div`
	display: block;
`

class ArticleEditorComponent extends React.Component {
	
	componentDidMount() {
		this.props.fetchFilters()
	}
	
	render() {
		const { t, filters, initialValues, onSubmit, submitButtonText } = this.props
		//console.log("InitialValues", initialValues)
		//console.log("Filters:", filters)
		
		if (!filters.length) return <Loading />
		
		let filterDefaults = {}
		
		filters.forEach( (filter) => {
			//console.log("Filter:", filter)
			filterDefaults = {
				...filterDefaults,
				[filter.name]: []
			}
		})
		
		//console.log("FilterDefaults:", filterDefaults)
		
		const defaultValues = {
			title: "",
			description: "",
			data: "",
			imageUrl: "",
			filters: {
				...filterDefaults
			}
			/*
				ageGroup: [],
				topic: [],
				goal: [],
				subject: []
			}*/
		}
		//console.log({defaultValues, initialValues})
		let extantInitialValues = {}
		let initialFilters = {}
		if (initialValues) {
			Object.keys(initialValues).forEach(key => {
				const value = initialValues[key]
				if (value) extantInitialValues = { ...extantInitialValues, [key]: value}
			});
			
			if (initialValues.filters) {
				Object.keys(initialValues.filters).forEach(key => {
					const value = initialValues.filters[key]
					if (value) initialFilters = { ...initialFilters, [key]: value}
				});
			}
		}
		//console.log({extantInitialValues, initialFilters})
		return (
			<Formik
				enableReinitialize //TODO: FIX: controlled to uncontrolled when editing article after enabling this, needs fix!
				initialValues={{
					...defaultValues,
					...extantInitialValues,
					filters: {
						...filterDefaults,
						...initialFilters
					}
				}}
				onSubmit={(values, actions) => {
					//console.log("Submitted from ArticleEditor")
					onSubmit(values, actions)
					
					/*
					actions.resetForm({...values})
					let errors = {}
					for(const i in error.response.data.errors) {
						const e = error.response.data.errors[i]
						console.log("Looping", e)
						
						if (e.code === 10000) {
							console.log("Code is 10000, setting status: ", e.message)
							actions.setStatus(e.message)
							break
						}
						else {
							console.log("Code is not 10000, setting error: e.field", e.field, "e.message", e.message)
							errors = {
								...errors,
								[e.field]: e.message
							}
						}
					}*/
				}}
				render={ ({ values, setFieldValue, touched, errors }) => (
					<>
						<Form>
							<FlexRow gap="16px">
								<ArticleField
									name="title"
									touched={touched}
									errors={errors}
								/>
								
								<ArticleField
									name="description"
									touched={touched}
									errors={errors}
								/>
							</FlexRow>
							
							{
								values.imageUrl === "" ? (
									<input
										id="image"
										type="file"
										name="image"
										accept="image/png, image/jpeg"
										onChange={e => {
											if (e.target && e.target.files && e.target.files.length > 0) {
												const image = e.target.files[0]
												const formData = new FormData()
												formData.append("image", image)
												e.persist()
												console.log({ e, image })
												backend.post("/upload", formData, {
													headers: {
														'content-type': 'multipart/form-data'
													}
												})
													.then( response => {
														const { url } = response.data.data
														console.log({ response })
														setFieldValue("imageUrl", url)
													})
													.catch( error => console.error(error))
												}
											else {
												setFieldValue("imageUrl", "")
											}
										}}
									/>
								) : (
									<FlexRow>
										<Preview src={values.imageUrl} alt="preview" />
										<SquareButton onClick={e => {
											e.preventDefault()
											setFieldValue("imageUrl", "")
										}}>
											<FlexRow>
												<Text>Fjern bilde</Text>
												<Delete />
											</FlexRow>
										</SquareButton>
									</FlexRow>
								)
							}
							
							
							{/*
							<ArticleField
								name="imageUrl"
								touched={touched}
								errors={errors}
								type="file"
								accept="image/png, image/jpeg"
							/>
							*/}
							{
								/*
								<label htmlFor="title">{t("admin.article.editor.title")}</label>
								<Field name="title" />
								<label htmlFor="description">{t("admin.article.editor.description")}</label>
								<Field name="description" />
								*/
							}
							
							{
								//console.log("Values:", values)
							}
							{
								filters.map( (filter) => {
									//console.log(filter)
									//const filter = values.filters[filterKey]
									//console.log("Filter:", filter)
									return (
										<FilterGroup key={filter.name}>
											<h3>{t(`filters.${filter.name}`)}</h3>
											{
												filter.items.map( (item) => {
													return (
														<Checkbox
															key={item.id}
															name={`filters.${filter.name}`}
															value={item.id}
															label={t(`filters.${item.translationKey}`)}
														/>
													)
												})
											}
										</FilterGroup>
									)
								})
							}
							
							<Editor
								data={values.data}
								setFieldValue={setFieldValue}
							/>
							
							{
								/*
							<EditorWrapper>
								<CKEditor
									editor={ LebensbornEditor }
									data={values.data}
									onChange={ ( event, editor ) => {
										const data = editor.getData();
										setFieldValue("data", data)
									}}
									config={{
										simpleUpload: {
											// The URL that the images are uploaded to.
											uploadUrl: 'http://example.com',
								
											// Headers sent along with the XMLHttpRequest to the upload server.
											headers: {
												'X-CSRF-TOKEN': 'CSFR-Token',
												Authorization: 'Bearer <JSON Web Token>'
											}
										}
									}}
								/>
							</EditorWrapper>
								*/
							}
							
							<SquareButton type="submit">
								{submitButtonText}
							</SquareButton>
							
						</Form>
						{
							/*
							<pre>
								{ JSON.stringify(values, null, 3) }
							</pre>
							*/
						}
					</>
				)}
			/>
		)
	}
}
	
const mapStateToProps = state => {
	//const filtersArray = Object.values(state.filters)
	const keys = Object.keys(state.filters)
	const filters = keys.map( (key) => (
		{
			name: key,
			items: [
				...Object.values(state.filters[key])
			]
		}
	))
	//console.log(filtersArray)
	return {
		//filters: Object.values(state.filters),
		filters
	}
}

export const ArticleEditor = connect(mapStateToProps, { fetchFilters })(
	withTranslation()(ArticleEditorComponent)
)
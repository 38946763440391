import React from 'react'
//import { Link } from '@reach/router';

import TopBar from '../../dashboard/TopBar'
import ArticleFilterButton from '../../articles/ArticleFilterButton'
import ArticleFilters from '../../articles/ArticleFilters'
import { ArticleList } from '../../articles/ArticleList'
import { PATH_CREATE, PATH_VIEW } from '../../../constants'
import { useTranslation } from 'react-i18next'
import { CircularLink } from '../../dashboard/CircularLink'

import FlexRow from '../../common/flex/FlexRow'

export const AdminArticles = () => {
	const { t } = useTranslation()
	return (
		<div>
			<TopBar>
				<ArticleFilterButton />
			</TopBar>
			
			<ArticleFilters />
			
			<ArticleList edit viewPath={PATH_VIEW} />
			
			<FlexRow>
				<CircularLink to={`.${PATH_CREATE}`}>
					{t("admin.create-button")}
				</CircularLink>
			</FlexRow>
			
		</div>
	)
}

import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

//import backend from '../../apis/backend';
import { fetchUserData, editUserData } from '../../../redux/actions'
import backend from '../../../apis/backend';
import { PrettyField } from '../../forms/PrettyField';
import { SquareButton } from '../../dashboard/SquareButton';
import { navigate } from '@reach/router';

const StyledForm = styled(Form)`
	/*max-width: 600px;*/
	width: 100%;
	word-break: break-word;
	/*margin: 0 8px;*/
	/*display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;*/
`

const PostalPlaceWrapper = styled.div`
	display: flex;
	flex-direction: row;
	>*:first-of-type
	{
		margin-right: 20px;
	}
`

const FlexRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`

const UserDataFormComponent = ({ userData, children }) => {
	
	const { t } = useTranslation()
	
	const { id, isCompany } = userData
	console.log("User Data", userData)
	
	const commonFields = {
		firstName: "",
		lastName: "",
		email: "",
		address: "",
		city: "",
		postalCode: "",
		country: ""
	}
	
	const companyFields = {
		companyName: "",
		orgNumber: ""
	}
	
	const extraFields = isCompany ? {
		...companyFields
	} : {}
	
	const defaultValues = {
		...commonFields,
		...extraFields
	}

	//console.log("Default Values", defaultValues)
	//console.log("Remaining Fields", remainingFields)
	
	const UserDataField = ({name, ...rest}) => {
		return (
			<PrettyField name={name} i18nPath="form.fields" {...rest} />
		)
	}

	let initialValues= {...userData}
	let extantInitialValues = {}
	if (initialValues) {
		Object.keys(initialValues).forEach(key => {
			const value = initialValues[key]
			if (value) extantInitialValues = { ...extantInitialValues, [key]: value}
		});
	}
	
	return (
		<Formik
			enableReinitialize
			initialValues={{
				...defaultValues,
				...extantInitialValues,
			}}
			onSubmit={(values, actions) => {
				//console.log("Submitted from UserData!")
				backend.put(`/users/${id}`, {
					...values
				})
					.then( (response) => {
						console.log("Successful response", response)
						//console.log("UserData was updated!")
						//navigate(`./`)
						actions.resetForm(values)
					})
					.catch( (error) => {
						console.log("Failure response", error, error.response)
						actions.resetForm({...values})
						let errors = {}
						for(const i in error.response.data.errors) {
							const e = error.response.data.errors[i]
							
							if (e.code === 10000) {
								actions.setStatus(e.message)
								break
							}
							else {
								errors = {
									...errors,
									[e.field]: e.message
								}
							}
						}
						actions.setErrors(errors)
					})
			}}
			render={ props  => {
				console.log("Rendering UserDataForm: Props", props)
				return (
					<StyledForm>
						<UserDataField
							name="firstName"
							touched={props.touched}
							errors={props.errors}
						/>
						
						<UserDataField
							name="lastName"
							touched={props.touched}
							errors={props.errors}
						/>
						
						<UserDataField
							name="email"
							type="email"
							touched={props.touched}
							errors={props.errors}
						/>
						
						<UserDataField
							name="address"
							touched={props.touched}
							errors={props.errors}
						/>
						
						<PostalPlaceWrapper>
							<UserDataField
								name="postalCode"
								touched={props.touched}
								errors={props.errors}
							/>
							<UserDataField
								name="city"
								touched={props.touched}
								errors={props.errors}
							/>
						</PostalPlaceWrapper>
						
						<UserDataField
							name="country"
							touched={props.touched}
							errors={props.errors}
						/>
						
						{
							isCompany && (
								<>
									<UserDataField
										name="orgNumber"
										touched={props.touched}
										errors={props.errors}
									/>
									<UserDataField
										name="companyName"
										touched={props.touched}
										errors={props.errors}
									/>
								</>
							)
						}
						
						<FlexRow>
							<SquareButton type="submit" disabled={!(props.isValid && props.dirty)}>
								{t("admin.userdata.submit")}
							</SquareButton>

							<SquareButton type="reset" disabled={!(props.dirty)} onClick={props.handleReset} >{
								t("admin.userdata.reset")}
							</SquareButton>
							
							{ children }
						</FlexRow>
						
						
					</StyledForm>
				)
			}}
		/>
	)
}

export const UserDataForm = connect(null, {fetchUserData, editUserData })(
	UserDataFormComponent
)
import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { fetchArticles, fetchSpecialArticles } from '../../redux/actions'
//import { ArticleListItem } from './ArticleListItem'
import { ArticleListImageItem } from './ArticleListImageItem'
//import { ArticleListItem } from './ArticleListItem'
//import { Loading } from '../dashboard/Loading'

const List = styled.div`
	/*
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 26px;
	@media screen and (min-width: 1200px)
	{
		grid-template-columns: repeat(3, 1fr);
	}
	@media screen and (min-width: 1500px)
	{
		grid-template-columns: repeat(4, 1fr);
	}*/
	display: flex;
	flex-direction: column;
	margin-bottom: 16px;
	>*
	{
		margin-bottom: 8px;
	}
`

class ArticleListComponent extends React.Component {
	state = {
		fetchedLanguage: undefined,
		fetchedArticles: undefined
	}
	
	performFetch = () => {
		const { i18n, fetchArticles, fetchSpecialArticles, special } = this.props
		//console.log("performFetch", {special})
		special ? fetchSpecialArticles() : fetchArticles()
		this.setState({fetchedLanguage: i18n.language})
	}
	
	componentDidMount() {
		this.performFetch()
	}
	
	componentDidUpdate() {
		//this.props.fetchArticles()
		const { i18n } = this.props
		if ( i18n.language !== this.state.fetchedLanguage) {
			this.performFetch()
		}
	}
	
	render() {
		//if (!this.state.fetchedArticles) return <Loading />
		const { articles, edit, viewPath="" } = this.props
		//console.log({ special })
		return (
			<List>
				{
					articles.map( (article) => {
						//console.log({ article })
						//if (special) return <ArticleListItem key={article.id}>{article}</ArticleListItem>
						return (
							<ArticleListImageItem key={article.id} edit={edit} viewPath={viewPath}>
								{article}
							</ArticleListImageItem>
						)
					}
				)
			}
			</List>
		)
	}
}


const mapStateToProps = state => {
	return {
		articles: state.articles
	}
}

export const ArticleList = connect(mapStateToProps, { fetchArticles, fetchSpecialArticles })(
	withTranslation()(ArticleListComponent)
)
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import Title from '../common/text/Title'
import { withTranslation } from 'react-i18next';

import { fetchArticle, createArticle, editArticle } from '../../redux/actions'
import { Tip } from '../common/text/Tip';
import { SpecialEditor } from '../dashboard/SpecialEditor';
import { FORM_ACTION_POST, FORM_ACTION_PUT } from '../forms/ValidationForm';
import TopBar from '../dashboard/TopBar';
import { PATH_DASHBOARD, PATH_SUBJECT_MATERIAL } from '../../constants';

const FatTip = styled(Tip)`
	font-weight: bold;
`

class AdminSpecialArticleEditorComponent extends React.Component {
	
	componentDidMount() {
		//fetch single article
		const { id, blank, fetchArticle } = this.props
		if (!blank) {
			fetchArticle(id)
		}
	}
	
	render() {
		//console.log({props: this.props})
		const { t, i18n, blank, createArticle, editArticle, id, article, backPath } = this.props
		
		if (!blank && !article) {
			return <Title>{t("admin.article.edit.article-does-not-exist")}</Title>
		}
		//console.log("SpecialArticleEdit", blank, article)
		
		const initial = blank ? {} : article
		return (
			<div>
				<TopBar backPath={backPath} />
				
				<Title>{t("admin.article.edit.title")}</Title>
				<Tip>{t("admin.article.tip")}</Tip>
				
				{ !blank && <FatTip>{t("admin.article.editor.special.link-url")}: {`${PATH_DASHBOARD}${PATH_SUBJECT_MATERIAL}/${id}`}</FatTip> }
				
				<SpecialEditor
					path={blank ? `/articles` : `/articles/${id}`}
					language={blank && i18n.language}
					initialValues={{...initial}}
					submitButtonText={t("admin.guide.submit")}
					onSuccess={(data) => blank ? createArticle(id, data) : editArticle(id, data)}
					operation={blank ? FORM_ACTION_POST : FORM_ACTION_PUT}
				/>
			</div>
		)
	}
	
}

const mapStateToProps = (state, ownProps) => {
	let article = undefined
	if (!ownProps.blank) {
		const uncheckedArticle = state.articles.find( article => article.id.toString() === ownProps.id.toString() )
		//Now, ensure the article has a data field. If not, it's only been fetched from the article list and does not have all the fields we need, so make sure it stays undefined!
		article = uncheckedArticle && uncheckedArticle.data && uncheckedArticle
	}

	
	return {
		isAdmin: state.auth.isAdmin,
		article
	}
}

export const AdminSpecialArticleEditor = connect(mapStateToProps, { fetchArticle, editArticle, createArticle })(
	withTranslation()(AdminSpecialArticleEditorComponent)
)
import styled from 'styled-components'

export const TopBarButton = styled.button`
	border: 0;
	background: transparent;
	padding: 0;
	font-size: inherit;
	font-weight: inherit;
	padding: 8px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: inherit;
`
import {
	CREATE_SUPERGROUP, FETCH_LICENSES, DELETE_SUPERGROUP
} from "../actions/types";

export default (state = {}, action) => {
	switch (action.type) {
		/* Superroup array must be updated when a new group is added! */
		case FETCH_LICENSES:
			return {
				...state,
				...action.payload
			}
		case CREATE_SUPERGROUP:
			//const { licenseID } = action.payload
			return {
				...state,
				[action.payload.licenseID]: {
					...state[action.payload.licenseID],
					supergroups: [
						...state[action.payload.licenseID].supergroups,
						action.payload.id
					]
				}
			}
		case DELETE_SUPERGROUP:
			console.log("deleteSuper", action.payload)
			const { licenseID } = action.payload
			return {
				...state,
				[licenseID]: {
					...state[licenseID],
					supergroups: state[licenseID].supergroups.filter( item => item !== action.payload.id)
				}
			}
		default:
			return state;
	}
}
import React from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from '@reach/router'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

import { fetchFavourites } from '../../redux/actions'

import {
	Dashboard as DashboardIcon,
	Book,
	Person,
	Comment,
	Group,
	LiveHelp,
	Ballot,
} from '@material-ui/icons'

import Menu from './Menu'
import { Main } from '../common/Main'
//import MenuText from '../menu/MenuText'
import Separator from '../common/Separator'
import Favourites from '../menu/Favourites'

import { Content } from '../common/Content'
//import MenuTwoLineText from '../menu/MenuTwoLineText'

//import logo from "../../img/logo-edu-1x.png"
import { Logo } from '../menu/Logo';
import { PATH_GUIDE, PATH_LICENSES, PATH_DASHBOARD, PATH_CHOOSE_GROUP, PATH_CHAPTERS, PATH_QUESTIONS, PATH_SUBJECT_MATERIAL } from '../../constants';
//import { MenuButton } from '../menu/MenuButton';
import { MenuLink } from '../menu/MenuLink';

import { TwoLineText } from '../menu/TwoLineText';
//import FlexRow from '../common/flex/FlexRow';

const Text = styled.p`
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.1px;
	margin: 0;
`

const Fat = styled(Text)`
	font-weight: bold;
`

const Thin = styled(Text)`
	font-weight: 200;
`

class DashboardComponent extends React.Component {
	
	componentDidMount() {
		this.props.fetchFavourites()
	}
	
	render() {
		const { t, children, isLoggedIn/*, isAdmin*/ } = this.props
		
		if (!isLoggedIn) return <Redirect to="/" noThrow />
	
		//console.log("Dashboard", isAdmin)
		return (
			<Content>
				<Menu bottomMenuItems={
					<>
						<MenuLink
							to={`.${PATH_GUIDE}`} 
							icon={<LiveHelp />}
						>
							<TwoLineText
								title={t("menu.get-started")}
								titleComponent={Fat}
								subtitle={t("menu.get-help")}
								subtitleComponent={Thin}
							/>
						</MenuLink>
						
						<Separator color="#E5E5E5" />
						
						<MenuLink
							to={`.${PATH_LICENSES}`}
							icon={<Ballot />}
							text={t("menu.licenses")}
						/>
					</>
				}>
					<Link to={PATH_DASHBOARD} style={{display: "flex", justifyContent: "center"}}>
						<Logo />
					</Link>
					
					<MenuLink
						to={PATH_DASHBOARD}
						icon={<DashboardIcon />}
						text={t('menu.start-page')}
					/>
					
					<Separator color="#E5E5E5" />
					
					<MenuLink
						icon={<Group />}
						to={`.${PATH_CHOOSE_GROUP}`}
						text={t('menu.choose-group')}
					/>
					
					<Separator color="#E5E5E5" />
					
					<MenuLink
						to={`.${PATH_SUBJECT_MATERIAL}`}
						icon={<Book />}
						text={t('menu.teaching-material')}
					/>
					
					<MenuLink
						to={`.${PATH_CHAPTERS}`}
						icon={<Comment />}
						text={t('menu.feedback')}
					/>
					
					<MenuLink
						to={`.${PATH_QUESTIONS}`}
						icon={<Person />}
						text={t('menu.questions-and-answers')}
					/>
					
					<Favourites />
					
					{
						// isAdmin && (
						// 	<Link to={PATH_ADMIN}>
						// 		Go to admin
						// 	</Link>
						// )
					}
					
				</Menu>
				
				<Main>
					{children}
				</Main>
			</Content>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		isLoggedIn: state.auth.isLoggedIn,
		isAdmin: state.auth.isAdmin
	}
}

export const Dashboard = connect(mapStateToProps, { fetchFavourites })(
	withTranslation()(DashboardComponent)
)
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
`

const Text = styled.p`
	width: 100%;
	margin: 0;
`

export const TwoLineText = ({title, titleComponent, subtitle, subtitleComponent, ...rest}) => {
	if (!title) throw Error(`TwoLineText did not receive a title!`)
	const Title = titleComponent || Text
	const Subtitle = subtitleComponent || Text
	return (
		<Wrapper {...rest}>
			<Title>
				{title}
			</Title>
			{
				subtitle && (
					<Subtitle>
						{subtitle}
					</Subtitle>
				)
			}
		</Wrapper>
	)
}
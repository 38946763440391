import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { TopBarButton } from '../dashboard/TopBarButton'
import TopBar from '../dashboard/TopBar'
import { OrderForm } from '../ordering/OrderForm'
import backend from '../../apis/backend'
import { Loading } from '../dashboard/Loading'
import Title from '../common/text/Title'

import { fetchProfile } from '../../redux/actions'
import { PATH_DASHBOARD, PATH_LICENSES } from '../../constants';

export class UpgradeComponent extends React.Component {
	state = {
		currency: "nok",
		bundles: undefined
	}
	
	componentDidMount = async () => {
		const { id, fetchProfile } = this.props
		fetchProfile()
		
		try {const response = await backend.get(`/licenses/${id}/upgrade/bundles`)
			//console.log("Response", response)
			this.setState({bundles: [
				...Object.values(response.data.data)
			]})
		}
		catch(error) {
			console.error("Could not retrieve bundles: ", error)
		}
	}
	
	render() {
		const { currency, bundles } = this.state
		const { t, id, profile, location } = this.props
		//console.log(bundles, profile)
		//console.log(!bundles, !profile)
		if (!bundles || !profile) return <Loading />
		if (!bundles.length) return <Title>{t("dashboard.upgrade.no-bundles")}</Title>
		return (
			<div>
				<TopBar>
					<TopBarButton onClick={() => this.setState({
						currency: currency === "nok" ? "eur" : "nok"
					})}>{this.state.currency}</TopBarButton>
				</TopBar>
				<OrderForm
					currency={currency}
					bundles={bundles}
					pathCard={`licenses/${id}/upgrade/checkout`}
					pathInvoice={`licenses/${id}/upgrade/order/invoice`}
					successURL={`${location.origin}${PATH_DASHBOARD}${PATH_LICENSES}`}
					errorURL={`${location.origin}${PATH_DASHBOARD}`}
					profile={profile}
				/>
				
			</div>
		)
	}
}

const mapStateToProps = state => {
	//console.log(state.profile, Object.values(state.profile), Object.values(state.profile).length)
	const profile = Object.values(state.profile).length && state.profile
	return {
		profile
	}
}

export const Upgrade = connect(mapStateToProps, { fetchProfile })(
	withTranslation()(UpgradeComponent)
)
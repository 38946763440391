//import React from 'react'
import styled from 'styled-components'

export const FlexColumn = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`

/*
const FlexColumn = ({justifyContent="center", alignItems="center", children, style, ...rest}) => (
	<div
		style={{
			display: "flex",
			alignItems: alignItems,
			justifyContent: justifyContent,
			flexDirection: "column",
			...style
		}}
		{...rest}
	>
		{children}
	</div>
)

export default FlexColumn*/
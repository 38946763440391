import React from 'react'
import styled from 'styled-components'
import { FlexRow } from './flex'

const Wrapper = styled.div`
	position: fixed;
	z-index: 1000;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Box = styled.div`
	background-color: white;
	border-radius: 8px;
	padding: 8px;
	box-shadow: 5px 5px 25px black;
`

const Title = styled.h1`
	font-size: 24px;
	font-weight: bold;
`

const Text = styled.p`
	font-size: 18px;
`

export const Modal = ({ title, titleComponent, text, children, buttons, cancelButton: CancelButton, cancelText, onClose, ...rest}) => (
	<Wrapper onClick={() => onClose()}>
		<Box onClick={(e) => e.stopPropagation()} {...rest}>
			{ <Title>{title}</Title> || {titleComponent} }
			<div>
				{<Text>{text}</Text> || children}
			</div>
			<FlexRow justify="flex-end" gap="8px">
				{buttons}
				<CancelButton onClick={() => onClose()}>
					{cancelText}
				</CancelButton>
			</FlexRow>
		</Box>
	</Wrapper>
)
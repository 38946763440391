import React from 'react'
import { connect } from 'react-redux'

import { ArticleEditor } from '../articles/ArticleEditor';
import Title from '../common/text/Title'
import backend from '../../apis/backend';
import { withTranslation } from 'react-i18next';

import { createArticle } from '../../redux/actions'
import { Tip } from '../common/text/Tip';
import TopBar from '../dashboard/TopBar';

const AdminArticleCreateComponent = ({ t, i18n, createArticle }) => (
	<div>
		<TopBar />
		
		<Title>{t("admin.article.create.title")}</Title>
		<Tip>{t("admin.article.tip")}</Tip>
		
		<ArticleEditor
			submitButtonText={t("admin.article.create.submit")}
			onSubmit={(values, actions) => {
				backend.post("/articles", {
					...values,
					language: i18n.language
				})
					.then( (response) => {
						console.log("Successful response", response)
						createArticle(response.data.data)
					})
					.catch( (error) => {
						console.log("Failure response", error, error.response)
						actions.resetForm({...values})
						for(const i in error.response.data.errors) {
							const e = error.response.data.errors[i]
							//console.log("Looping", e)
							
							if (e.code === 10000) {
								//console.log("Code is 10000, setting status: ", e.message)
								actions.setStatus(e.message)
								break
							}
							else {
								//console.log("Code is not 10000, setting error: e.field", e.field, "e.message", e.message)
								actions.setErrors({
									[e.field]: e.message
								})
							}
						}
						//actions.setErrors(error.response)
					})
			}}
		/>
	</div>
)

const mapStateToProps = state => {
	return {
		isAdmin: state.auth.isAdmin
	}
}

export const AdminArticleCreate = connect(mapStateToProps, { createArticle })(
	withTranslation()(AdminArticleCreateComponent)
)
import React from 'react'
import { connect } from 'react-redux'

import Title from '../../common/text/Title'
import Subtitle from '../../common/text/Subtitle'
import TopBar from '../../dashboard/TopBar'
import { LicenseDataForm } from './LicenseDataForm'

import { fetchLicenseData } from '../../../redux/actions'
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { COLOR_GREEN } from '../../../constants'

const Wrapper = styled.div`
	max-width: 800px;
	width: 100%;
	/*margin: 0 8px;*/
	/*display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;*/
	@media screen and (min-width: 800px)
	{
		width: 90%;
		margin: 0 auto;
	}
	@media screen and (min-width: 900px)
	{
		width: 80%;
	}
	@media screen and (min-width: 1000px)
	{
		width: 70%;
	}
	@media screen and (min-width: 1200px)
	{
		width: 60%;
		margin: 0 auto;
	}
`


const TextButton = styled.button`
	font-weight: 500;
	font-size: 14px;
	cursor: pointer;
	text-align: center;
	letter-spacing: 1.25px;
	text-transform: uppercase;
	background: transparent;
	border: none;
	color: ${props => props.color || COLOR_GREEN};
	padding: 8px;
	${props => {
		if (props.align) {
			switch (props.align.toLowercase()) {
				case "left":
					return (`
						margin-left: 0;
						margin-right: auto;
					`)
				case "right":
					return (`
						margin-left: auto;
						margin-right: 0;
					`)
				case "center":
					return (`
						margin-left: auto;
						margin-right: auto;
					`)
				default:
					return "margin: 0;"
			}
		}
	}}
	/*margin-left: ${props => (props.align && props.align.toLowercase() === "left" && "auto") || 0}
	margin-right: ${props => (props.align && props.align.toLowercase() === "left") || 0}*/
`

const RightTextButton = styled(TextButton)`
	display: block;
	margin-left: auto;
	margin-right: 0;
`

class LicenseDataComponent extends React.Component {
	
	componentDidMount() {
		//fetch specific user
		const { id, fetchLicenseData } = this.props
		fetchLicenseData(id)
	}
	
	render() {
		const { t, licenseData } = this.props
		
		if (!Object.keys(licenseData).length) return (
			<div>
				<TopBar />
				
				<Title>...loading</Title>
			</div>
		)
		const { license} = licenseData

		return (
			<div>
				<TopBar/>
				
				<Wrapper>
					<Subtitle>{t("admin.licenses.list.license")}:</Subtitle>
					<Title>{license}</Title>

					<LicenseDataForm
						licenseData={licenseData}
						expiresAt={new Date(licenseData.expires_at).toLocaleDateString('en-CA',  {dateStyle: 'short'})}
					>
					</LicenseDataForm>
				</Wrapper>
				
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		isAdmin: state.auth.isAdmin,
		licenseData: state.licenseData
	}
}

export const LicenseData = connect(mapStateToProps, { fetchLicenseData })(
	withTranslation()(LicenseDataComponent)
)
import {
	FETCH_USERDATA, EDIT_USERDATA
} from "../actions/types";

export default (state = {}, action) => {
	switch (action.type) {
		
		case FETCH_USERDATA:
			return {
				...state,
				...action.payload
			}
		case EDIT_USERDATA:
			const setFields = Object.keys(action.payload).filter( key => {
				return action.payload[key] !== null
			})
			
			const setFieldsObject = {}
			setFields.forEach( key => setFieldsObject[key] = action.payload[key] )
			
			return {
				...state,
				...setFieldsObject
			}
		default:
			return state;
	}
}
import { arrayIncludesAll } from '../common/utility/arrayIncludesAll'

export const groupsAreInState = (supergroup, stateGroups) => {
	return supergroup &&
		stateGroups.length > 0 &&
		arrayIncludesAll(
			stateGroups,
			supergroup.groups.map( groupId => (
				groupId.toString()
			))
		)
}
import React from 'react'
import { connect } from 'react-redux'

import TopBarFiller from '../../dashboard/TopBarFiller';
import LicenseStatsList from './LicenseStatsList';
import { fetchLicenseStats } from '../../../redux/actions'
import { withTranslation } from 'react-i18next';
import Title from '../../common/text/Title';
import { SearchBar} from './common';

class LicenseStats extends React.Component {

	state = {
		searchValue: ''
	}

	handleOnChange = e => {
		const {value, name} = e.currentTarget;
	  
		this.setState({...this.state, [name]: value});
	};

	componentDidMount() {
		this.props.fetchLicenseStats()
	}
	
	render() {
		const { t } = this.props

		console.log("License Stats Props", this.props)
		
		const filteredLicenses = this.props.licenseStats.filter(license => {
			return license.email?.toLowerCase().includes(this.state.searchValue.toLowerCase()) ||
			license.first_name?.toLowerCase().includes(this.state.searchValue.toLowerCase()) ||
			license.last_name?.toLowerCase().includes(this.state.searchValue.toLowerCase()) ||
			license.company_name?.toLowerCase().includes(this.state.searchValue.toLowerCase());
		})

		const licensesToRender = !this.state.searchValue ? this.props.licenseStats: filteredLicenses

		return (
			<div>
				<SearchBar 
					placeholder="Search"
					type = "text"
					name = "searchValue"
					value={this.state.searchValue}
					onChange={this.handleOnChange}
				/>
				<TopBarFiller />
				<Title>{t("admin.licensestats")}</Title>
				<LicenseStatsList>
					{licensesToRender}
				</LicenseStatsList>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		isAdmin: state.auth.isAdmin,
		licenseStats: Object.values(state.licenseStats)
	} 
}

export default connect(mapStateToProps, { fetchLicenseStats })(
	withTranslation()(LicenseStats)
)
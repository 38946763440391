import React from 'react'

import { ValidationForm, FORM_ACTION_POST } from '../forms/ValidationForm'
import { StyledForm } from '../common/forms/StyledForm'
import FlexRow from '../common/flex/FlexRow'
import { SquareButton } from './SquareButton'

import { Editor } from './Editor'

export const SimpleEditor = ({ initialValues, path, operation={FORM_ACTION_POST}, onSuccess, submitButtonText}) => (
	<ValidationForm
		enableReinitialize
		initialValues={{
			data: "",
			...initialValues
		}}
		operation={operation}
		path={path}
		onSuccess={response => onSuccess(response.data.data)}
		render={ ({values, setFieldValue}) => {
			return (
				<StyledForm>
					<Editor
						data={values.data}
						setFieldValue={setFieldValue}
					/>
					
					<FlexRow>
						<SquareButton type="submit" margin="20px">
							{submitButtonText}
						</SquareButton>
					</FlexRow>
				</StyledForm>
			)
		}}
	/>
)
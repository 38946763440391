import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
//import setAcceptLanguage from '../apis/setAcceptLanguage'

//import { setLanguage } from '../redux/actions'
//import { ENGLISH, NORWEGIAN_BOKMAL } from '../static';
import { EN, NO } from '../static'
import { setChosenLanguage } from '../redux/actions'

const Wrapper = styled.button`
	user-select: none;
	cursor: pointer;
	width: 100%;
	background: transparent;
	border: none;
	color: inherit;
	padding: 0;
	text-align: left;
`

const LanguageSwitch = ({text, style, children, setChosenLanguage, ...rest}) => {
	const { i18n, t } = useTranslation()
	const buttonText = text || children || t("language-switch.label")
	
	//console.log("LanguageSwitch: current language is", current)
	let language = EN;
	switch (i18n.language) {
		case EN:
			language = NO
			break
		case NO:
			language = EN
			break
		default:
			language = EN
			break
	}
	
	return (
		<Wrapper
			onClick={ () => {
				i18n.changeLanguage(language)
				setChosenLanguage(language)
				//setAcceptLanguage(language)
				console.log(`LanguageSwitch: Changed language to ${language}`)
			} }
			style={style}
			{...rest}
			//aria-label={}
		>
			{buttonText}
		</Wrapper>
	)
}
	
	/*const { i18n } = useTranslation()
	let otherLanguage = ENGLISH;
	
	switch (i18n.language) {
		case ENGLISH:
			otherLanguage = NORWEGIAN_BOKMAL
			break
		case NORWEGIAN_BOKMAL:
			otherLanguage = ENGLISH
			break
		default:
			otherLanguage = ENGLISH
			break
	}
	return (
		<div
			onClick={() => { i18n.changeLanguage(otherLanguage) }}
			style={{
				userSelect: "none",
				cursor: "pointer",
				...style
			}}
			{...rest}
		>
			<span>{text ? text : children}</span>
		</div>
	)*/
//}

export default connect(null, { setChosenLanguage })(LanguageSwitch)
//import _ from 'lodash';
import {
	TOGGLE_FILTER_VIEW
} from '../actions/types';

export default (state = true, action) => {
	switch (action.type) {
		case TOGGLE_FILTER_VIEW:
			//console.log(state, action)
			return !state;
		default:
			return state;
	}
}
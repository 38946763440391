import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field } from 'formik'
import { ValidationError } from './ValidationError'

export const ReadOnlyField = ({name, i18nPath, touched, errors, type="text", ...rest}) => {
	const { t } = useTranslation()
	if (!errors) console.error("Errors is undefined, check up on that.")
	if (errors && errors[name]) console.log("ValidationField:", {errors, touched})
	return (
		<div {...rest}>
			<label>
				{t(`${i18nPath}.${name}`)}
				<Field type={type} name={name} disabled />
			</label>
			{
				touched && errors && !touched[name] && errors[name] && (
					<ValidationError>
						{errors[name]}
					</ValidationError>
				)
			}
		</div>
	)
}
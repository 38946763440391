import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Link } from '@reach/router'

import { ListSelection, List } from './common'
import { Button } from './Button'
import { fetchGroups, setChosenGroup, createGroup } from '../../../redux/actions'
import { getGroups } from '../../groups/getGroups';
import { StyledEditorPopIn } from '../../common/forms/StyledEditorPopIn'
//import backend from '../../../apis/backend'
import { SquareButton } from '../../dashboard/SquareButton'
import { CircularButton } from '../../dashboard/CircularButton'
import { PATH_LICENSES } from '../../../constants'

class ChooseGroupComponent extends React.Component {
	
	componentDidMount() {
		const { fetchGroups, supergroupId } = this.props
		fetchGroups(supergroupId)
	}
	
	render() {
		const { groups, t, selectedGroup, supergroupId } = this.props
		return (
			<>
				{
					(groups.length > 0) ?
						<>
							<ListSelection
								list={groups}
								onClick={(item) => {
									this.props.setChosenGroup(
										//this.state.chosenSupergroup,
										item.id,
										item.name
									)
								}}
								selected={selectedGroup}
							/>
						</>
					:
						<>
							<p style={{textAlign: "center"}}>
								{t("dashboard.choose-group.no-groups")}
							</p>
							<List>
								<Link to={`..${PATH_LICENSES}`}>
									<Button>
										{t("dashboard.choose-group.create-group")}
									</Button>
								</Link>
								<Link to="../">
									<Button>
										{t("dashboard.choose-group.back")}
									</Button>
								</Link>
							</List>
						</>
				}
				<StyledEditorPopIn
					onSubmit={text => this.props.createGroup(supergroupId, text)}
					buttonText={t("dashboard.licenses.single.add-group")}
					formButtonText={t("dashboard.licenses.single.save-group")}
					button={CircularButton}
					formButton={SquareButton}
				/>
			</>
		)
	}
}

export const mapStateToProps = (state, ownProps) => {
	const groups = getGroups(state, ownProps.supergroupId)
		
	//console.log("Groups: ", groups)
	return {
		groups,
		selectedGroup: state.selection.group
	}
}

export const ChooseGroup = connect(mapStateToProps,
	{
		fetchGroups,
		setChosenGroup,
		createGroup
	}
)(
	withTranslation()(ChooseGroupComponent)
)
import _ from 'lodash';

import {
	CREATE_SUPERGROUP, CREATE_GROUP, FETCH_SUPERGROUPS, DELETE_GROUP, DELETE_SUPERGROUP
} from "../actions/types";

export default (state = {}, action) => {
	switch (action.type) {
		case FETCH_SUPERGROUPS:
			return {
				...state,
				...action.payload
			}
		case CREATE_SUPERGROUP:
			const { id } = action.payload
			//console.log("supergroupReducer", state)
			//console.log("supergroupReducer: id,", id, "name", name)
			return {...state,
				[id]: {
					...action.payload,
					groups: []
				}
			}
		case DELETE_SUPERGROUP:
			return {
				..._.omit(state, action.payload.id)
			}
		/* Group array must be updated when a new group is added! */
		case CREATE_GROUP:
			//const { supergroupID } = action.payload
			return {
				...state,
				[action.payload.supergroupID]: {
					...state[action.payload.supergroupID],
					groups: [
						...state[action.payload.supergroupID].groups,
						action.payload.id
					]
				}
			}
		case DELETE_GROUP:
			const { supergroupID } = action.payload
			//console.log({before: state[supergroupID].groups})
			//const test = _.omit(state[supergroupID].groups, action.payload)
			//console.log({test})
			return {
				...state,
				[supergroupID]: {
					...state[supergroupID],
					groups: state[supergroupID].groups.filter( item => item !== action.payload.id)
				}
			}
		default:
			return state;
	}
}
import React from 'react'
import styled from 'styled-components'
import { Base } from './Base'
//import { Book } from '@material-ui/icons'
import { useTranslation } from 'react-i18next';
import { ReactComponent as Book } from '../../../../img/icons/book.svg'
import { PATH_GUIDE } from '../../../../constants';

export const ButtonStyle = styled(Base)`
	background-color: #F7E2B8;
	border-color: #F4D8A4;
`

export const GuidelineButton = () => {
	const { t } = useTranslation()
	return <ButtonStyle icon={Book} text={t("landing.teacher-guidance")} to={PATH_GUIDE} />
}
import React from 'react'
import { connect } from 'react-redux'
import { Form } from 'formik'
import { useTranslation } from 'react-i18next'

import { PrettyField } from '../../forms/PrettyField'
import { SquareButton } from '../../dashboard/SquareButton'
import { ValidationForm, FORM_ACTION_PUT } from '../../forms/ValidationForm'

import { fetchUserData, editUserData } from '../../../redux/actions'
import backend from '../../../apis/backend';

export const ChangePasswordComponent = ({ userId, onComplete}) => {
	
	const { t } = useTranslation()

	//const { id } = userData
	//console.log("User Data", userData)
	console.log("User ID", userId)
	
	const PasswordField = ({name, ...rest}) => {
		return (
			<PrettyField name={name} i18nPath="dashboard.profile.password" {...rest} />
		)
	}
	
	return (
		<ValidationForm
			initialValues={{
				newPassword: ""
			}}
			operation={FORM_ACTION_PUT}
			path={`/password/${userId}`}
			onSuccess={() => onComplete()}
			render={ props => {
				console.log("ChangePassword, ID", userId)
				console.log("ChangePassword, form render", props)
				return(
					<Form>
						<PasswordField
							name="newPassword"
							type="password"
							touched={props.touched}
							errors={props.errors}
						/>
						
						<SquareButton type="submit">
							{t("dashboard.profile.password.submit")}
						</SquareButton>
					</Form>
				)
			}}
		/>
	)
}

export const ChangePassword = connect(null, {fetchUserData, editUserData })(
	ChangePasswordComponent
)
import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import TopBar from '../dashboard/TopBar'
//import { DISPLAY_NORMAL } from '../../redux/actions/types'
import { setChosenCommentDisplay } from '../../redux/actions'

import karen from '../../img/karen-postit-bg.png'
import { FullscreenScroll } from './Fullscreen'

/*
const CommentDisplayWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	min-height: 100vh;
`*/

const StyledFullscreen  = styled(FullscreenScroll)`
	background: url(${karen});
	background-size: cover;
	background-position: fixed;
	background-repeat: no-repeat;
	background-attachment: fixed;
`
/*
const Wrapper = styled(CommentDisplayWrapper)`
	background: url(${karen});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
`

const ItemWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`*/
const PostItWrapper = styled.div`
	background: ${ props => props.color || "#F7E2B8"};
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	
	padding: 18px 24px;
	
	&:after
	{
		content: '';
		background: ${ props => props.color || "#F7E2B8"};
		position: relative;
		z-index: 200;
		top: 0;
		right: 0;
		height: 20px;
		width: 20px;
	}
`

const Board = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 40px;
	padding: 8px;
	word-break: break-word;
	@media print
	{
		grid-template-columns: repeat(3, 1fr);
	}
`

const Text = styled.p`
	color: ${props => props.color || "black"};
	margin: 0;
	padding: 0;
	font-size: 24px;
	letter-spacing: 0.5px;
	@media print
	{
		font-size: 16px;
	}
`

const Message = styled(Text)`
	font-weight: bold;
	text-align: center;
`

const Name = styled(Text)`
	text-align: right;
`

const PostIt = ({text, author}) => {
	return (
		<PostItWrapper>
			<Message>{text}</Message>
			<Name>{author}</Name>
		</PostItWrapper>
	)
}

const StyledTopBar = styled(TopBar)`
	color: white;
`

const PostItBoardComponent = ({ comments, setChosenCommentDisplay }) => {
	const { t } = useTranslation()
	
	return (
		<StyledFullscreen topBarComponent={StyledTopBar}>
			<Board>
				{
					comments
						.filter( (comment) => !comment.hidden)
						.map( ({id, message, anonymous, createdBy}) => (
							<PostIt
								key={id}
								text={message}
								author={
									anonymous ?
									t("dashboard.comments.post-it-board.anonymous") :
									createdBy
								}
								color="brown"
							/>
						))
				}
			</Board>
		</StyledFullscreen>
	)
	/*
	return (
		<Wrapper>
			<ItemWrapper>
				<StyledTopBar
					noBackButton
					print
					fullscreen
					onClickFullscreen={() => setChosenCommentDisplay(DISPLAY_NORMAL)}
				/>
				<Board>
					{
						comments
							.filter( (comment) => !comment.hidden)
							.map( ({id, message, anonymous, createdBy}) => (
								<PostIt
									key={id}
									text={message}
									author={
										anonymous ?
										t("dashboard.comments.post-it-board.anonymous") :
										createdBy
									}
									color="brown"
								/>
							))
					}
				</Board>
			</ItemWrapper>
		</Wrapper>
	)*/
}

export const PostItBoard = connect(null, { setChosenCommentDisplay })(
	PostItBoardComponent
)
import styled from 'styled-components'

import logo from '../../img/lebensborn_250.png'
//import logo from '../../img/edu-g-h.png'
//import logo from '../../img/logo-edu-1x.png'

export const Logo = styled.img.attrs({
	src: logo,
	alt: "My Child Lebensborn EDU"
})`
	width: 250px;
	height: auto;
`
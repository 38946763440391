import styled from 'styled-components'

export const ArticleStyle = styled.div`

	h1, h2, h3, h4, h5, h6
	{
		font-weight: bold;
	}
	h1
	{
		font-size: 40px;
	}
	h2
	{
		font-size: 36px;
	}
	h3
	{
		font-size: 32px;
	}
	h4
	{
		font-size: 28px;
	}
	h5
	{
		font-size: 24px;
	}
	h6
	{
		font-size: 20px;
	}
	p, ul>li
	{
		font-size: 20px;
	}
	
	.image.image-style-align-right
	{
		float: right;
		display: block;
	}

	.image.image-style-align-left
	{
		float: left;
		display: block;
	}

	.image
	{
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 16px;
		margin-top: 0;
	}
	
	img
	{
		max-width: 100%;
		height: auto;
		max-height: 60vh;
	}
	
	.responsive-video
	{
		padding:56.25% 0 0 0;
		position:relative;
		&>iframe
		{
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
		}
	}
`
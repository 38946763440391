import styled from 'styled-components'
import { Link } from '@reach/router';
import { COLOR_GREEN } from '../../constants';

export const SquareLink = styled(Link)`
	background: ${COLOR_GREEN};
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
	padding: 8px 16px;
	color: white;
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 1.25px;
	font-weight: 500;
	text-align: center;
	border-style: none;
	cursor: pointer;
	margin-bottom: ${props => props.margin};
	white-space: ${ props => props.wrap ? "wrap" : "nowrap"};
`
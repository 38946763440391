import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import UserListItem from './UsersListItem'
import { Item, ItemWide, Centered, Right } from './common'

const UserListStyle = styled.div`

`

const UserHeader = styled.div`
	display: flex;
	padding: 20px 26px;
	font-weight: 500;
	font-size: 16px;
	text-transform: uppercase;
	color: #828282;
`

const UsersList = ({children}) => {
	const { t } = useTranslation()
	console.log("User List Children", children)

	return (
		<UserListStyle>
			<UserHeader>
				<ItemWide>
					<Centered>
						{t("admin.users.list.name")}
					</Centered>
				</ItemWide>
				
				<ItemWide>
					<Centered>
						{t("admin.users.list.email")}
					</Centered>
				</ItemWide>
				
				<Item>
					<Centered>
						{t("admin.users.list.user")}
					</Centered>
				</Item>
			</UserHeader>
			{children.map(
				({id, name, email}) => (
					<UserListItem
						key={id}
						id={id}
						name={name}
						email={email}
					/>
				)
			)}
		</UserListStyle>
	)
}

export default UsersList
import styled from 'styled-components'

import { Link } from '@reach/router'
import { COLOR_GREEN } from '../../constants'

export const TextLink = styled(Link)`
	margin: 0;
	padding: 0;
	border: 0;
	text-decoration: none;
	font-weight: 500;
	font-size: 14px;
	letter-spacing: 1.25px;
	text-transform: uppercase;
	color: ${COLOR_GREEN};
	flex-shrink: 0;
`
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Login } from '../../../img/icons/login.svg'
import { useTranslation } from 'react-i18next'

const Button = styled.button`
	background: #BEABC3;
	border: 2px solid #424242;
	border-radius: 13px;
	cursor: pointer;
	padding: 0;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
`

const Inner = styled.div`
	background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%), #E4CEE0;
	border: 3px solid #BEABC3;
	box-sizing: border-box;
	border-radius: 13px;
	padding: 12px 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	>*
	{
		margin-right: 12px;
		&:last-child
		{
			margin-right: 0;
		}
	}
`

const Text = styled.p`
	font-size: 17px;
	font-weight: bold;
	letter-spacing: 0.5px;
	color: black;
	margin: 0;
`

export const LoginButton = ({ text, ...rest }) => {
	const { t } = useTranslation()
	return (
		<Button {...rest}>
			<Inner>
				<Login />
				<Text>{t("buttons.login")}</Text>
			</Inner>
		</Button>
	)
}
import React from 'react'
import { connect } from 'react-redux'
//import styled from 'styled-components'
//import { Formik } from 'formik'
//import { navigate } from '@reach/router'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

import backend from '../../../apis/backend'
import { performLogin } from '../../../redux/actions'

import { Loading } from '../../dashboard/Loading'
import { PrettyField } from '../../forms/PrettyField'
import { SquareButton } from '../../dashboard/SquareButton'
import FlexRow from '../../common/flex/FlexRow'
import { StyledForm } from '../../common/forms/StyledForm'
import Title from '../../common/text/Title'
import TopBarFiller from '../../dashboard/TopBarFiller'
import { ValidationForm, FORM_ACTION_POST } from '../../forms/ValidationForm'
import { Card } from '../../landing/Card'
import { CardSection } from '../../landing/CardSection'

//import logo from '../../../img/edu-g-s.png'
import bg from '../../../img/Outside_summer_2_1920.png'
//import bg from '../../../img/landing-title-background.png'
import { AbsoluteLanguageSwitch } from '../../landing/AbsoluteLanguageSwitch'
import { LogoLarge } from '../../landing/LogoLarge'

const Wrapper = styled.div`
	margin-top: 8px;
	margin: 0 auto;
	background-image: url(${bg});
	background-size: cover;
	background-repeat: no-repeat;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
`

/*
const Logo = styled.img.attrs({ src: logo, alt: "My Child Lebensborn EDU" })`
	max-width: 100%;
`*/

const ErrorMessage = ({text, children, ...rest}) => (
	<div {...rest}>
		<TopBarFiller />
		<Title>{text || children}</Title>
	</div>
)

const RegistrationField = ({name, ...rest}) => {
	return (
		<PrettyField name={name} i18nPath="form.fields" {...rest} />
	)
}

class UserRegistrationComponent extends React.Component {
	
	state = {
		fetched: false,
		initialValues: {},
		fetchError: undefined
	}
	
	componentDidMount = async () => {
		const { token } = this.props
		try {
			const response = await backend.get(`/registration/${token}`)
			const registrationData = response.data.data
			
			let initialValues = {}
			Object.keys(registrationData).forEach( key => {
				const item = registrationData[key]
				if (item !== null) {
					initialValues = {
						...initialValues,
						[key]: item
					}
				}
			})
			//console.log({initialValues})
			
			this.setState({
				initialValues,
				fetched: true
			})
		}
		catch(error) {
			console.error(error)
			this.setState({
				fetchError: error.response.data.errors[0].message
			})
		}
	}
	
	render() {
		const { t, token, performLogin } = this.props
		const { initialValues, fetched, fetchError } = this.state
		if (fetchError) return <ErrorMessage>{fetchError}</ErrorMessage>
		if (!fetched) return <Loading />
		
		return (
			<Wrapper>
				<AbsoluteLanguageSwitch />
				<LogoLarge />
				<Card>
					<CardSection title={t("registration.title")}>
						<ValidationForm
							initialValues={{
								firstName: "",
								lastName: "",
								password: "",
								...initialValues
							}}
							path={`/registration/${token}`}
							operation={FORM_ACTION_POST}
							onSuccess={ data => {
								performLogin(data.token, false)
								//navigate(data.redirectURL)
							}}
							render={ props => {
								//console.log("Rendering orderForm: Props", props)
								return (
									<StyledForm>
										
										<RegistrationField
											name="firstName"
											touched={props.touched}
											errors={props.errors}
										/>
										
										<RegistrationField
											name="lastName"
											touched={props.touched}
											errors={props.errors}
										/>
										
										<RegistrationField
											name="password"
											type="password"
											touched={props.touched}
											errors={props.errors}
										/>
										
										<FlexRow>
											<SquareButton type="submit" margin="20px">
												{t("registration.submit")}
											</SquareButton>
										</FlexRow>
										
										{/*JSON.stringify(props.values, null, 3)*/}
										
									</StyledForm>
								)
							}}
						/>
					</CardSection>
				</Card>
			</Wrapper>
		)
	}
}

export const UserRegistration = connect(null, { performLogin })(
	withTranslation()(UserRegistrationComponent)
)
import React from 'react'

export const FlexAroundRow = ({justifyContent="space-around", alignItems="center", children, ...rest}) => (
	<div
		style={{
			display: "flex",
			alignItems: alignItems,
			justifyContent: justifyContent,
			flexDirection: "row"
		}}
		{...rest}
	>
		{children}
	</div>
)

export default FlexAroundRow
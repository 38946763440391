import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import CKEditor from '@ckeditor/ckeditor5-react'
import LebensbornEditor from 'lebensborneditor'
import { baseURL } from '../../apis/backend'

const EditorWrapper = styled.div`
	margin-bottom: 16px;
`

const EditorComponent = ({ data, setFieldValue, token }) => (
	<EditorWrapper>
		<CKEditor
			editor={ LebensbornEditor }
			data={data}
			onChange={ ( event, editor ) => {
				const data = editor.getData();
				setFieldValue("data", data)
			}}
			config={{
				simpleUpload: {
					// The URL that the images are uploaded to.
					uploadUrl: `${baseURL}upload/CKEditor`,
		
					// Headers sent along with the XMLHttpRequest to the upload server.
					headers: {
						'X-CSRF-TOKEN': 'CSFR-Token',
						Authorization: `Bearer ${token}`
					}
				}
			}}
		/>
	</EditorWrapper>
)

const mapStateToProps = state => {
	return {
		token: state.auth.token
	}
}

export const Editor = connect(mapStateToProps)(EditorComponent)
import React from 'react'
import { connect } from 'react-redux'

import { fetchArticle, editArticle } from '../../redux/actions'

import { SimpleEditor } from '../dashboard/SimpleEditor'
import { Loading } from '../dashboard/Loading'
import { withTranslation } from 'react-i18next';
import TopBar from '../dashboard/TopBar'
import { FORM_ACTION_PUT } from '../forms/ValidationForm'

class AdminResourcesComponent extends React.Component {
	
	componentDidUpdate() {
		const { fetchArticle, id, article } = this.props
		if (!article) fetchArticle(id)
	}
	
	componentDidMount() {
		const { fetchArticle, id, article } = this.props
		if (!article) fetchArticle(id)
	}
	
	render() {
		const { t, article, editArticle, id } = this.props
	
		if (!article) return <Loading />
		
		return (
			<div>
				<TopBar />
				<SimpleEditor
					path={`/articles/${id}`}
					initialValues={{...article}}
					submitButtonText={t("admin.guide.submit")}
					onSuccess={(data) => editArticle(id, data)}
					operation={FORM_ACTION_PUT}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	const uncheckedArticle = state.articles.find( article => article.id.toString() === ownProps.id.toString() )
	const article = uncheckedArticle && uncheckedArticle.data !== undefined && uncheckedArticle
	return {
		article
	}
}

export const AdminResources = connect(mapStateToProps, { fetchArticle, editArticle })(
	withTranslation()(AdminResourcesComponent)
)
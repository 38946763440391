import React from 'react'
//import styled from 'styled-components'
import { Link, Match } from '@reach/router'
import { MenuItem } from './MenuItem';

/*
const StyledLink = styled(Link)`
	${props => {
		return (
			props.selected && `
				>${MenuItem}
				{
					color: white;
					background-color: #3B7F83;
					border-radius: 4px;
					>*:first-child
					{
						color: white;
					}
				}
			`
		)
	}}
`*/

export const MenuLink = ({ to, ...rest }) => (
	<Match path={`${to}`}>
		{
			({ match }) => {
				const selected = match ? true : false
				//console.log("Match", to, match, selected, rest)
				return (
					<Link to={to}>
						<MenuItem selected={selected} {...rest} />
					</Link>
				)
			}
		}
  </Match>
)

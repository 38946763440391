import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

import { fetchChapter } from '../../../redux/actions'
import Title from '../../common/text/Title'
import Subtitle from '../../common/text/Subtitle'
import { Statistics } from '../../comments/Statistics'
import { Comments } from '../../comments/Comments'


const MarginStatistics = styled(Statistics)`
	margin-bottom: 16px;
`

class ChapterComponent extends React.Component {
	
	getChapterIfNeccessary = () => {
		const { fetchChapter, chapterNumber, group, chapter } = this.props
		if (!chapter || chapter.number.toString() !== chapterNumber.toString()) {
			//console.log(`Chapter, fetching ${chapterNumber}`)
			fetchChapter(chapterNumber, group.id)
		}
	}
	
	componentDidMount() {
		this.getChapterIfNeccessary()
	}
	
	componentDidUpdate() {
		this.getChapterIfNeccessary()
	}
	
	render() {
		const { t, chapter, group } = this.props
		if (!chapter) {
			return <></>
		}
		const { stats, number, comments } = chapter
		return (
			<>
				<Title>{t("dashboard.feedback.comments-for-chapter-x")} {number}</Title>
				<Subtitle>{t("dashboard.feedback.group")}: {group.name}</Subtitle>
				<MarginStatistics>{stats}</MarginStatistics>
				<Comments statistics={stats}>{comments}</Comments>
			</>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		chapter: state.chapters[ownProps.chapterNumber]
	}
}

export const Chapter = connect(mapStateToProps, { fetchChapter })(
	withTranslation()(ChapterComponent)
)
import React from 'react'
import { connect } from 'react-redux'

import TopBar from '../../dashboard/TopBar'
import Title from '../../common/text/Title';
import { FourOhFour } from '../../common/FourOhFour';
import { Comments } from '../../comments/Comments';
import { fetchQuestions } from '../../../redux/actions'

export class QuestionContentsComponent extends React.Component {
	componentDidMount() {
		//fetch question
		const { groupId, fetchQuestions } = this.props
		fetchQuestions(groupId)
		//this.props.fetchComments(this.props.question.comments)
	}
	
	render() {
		const { question } = this.props
		if (!question) return <FourOhFour>This question does not exist!</FourOhFour>
		return (
			<div>
				<TopBar>
					
				</TopBar>
				<Title>{question.title}</Title>
				<Comments>
					{
						question.comments
						/*
						question.comments.map( (commentId) => {
							return comments[commentId]
						})*/
					}
				</Comments>
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		question: state.questions[ownProps.id],
		//comments: state.comments
	}
}

export const QuestionContent = connect(mapStateToProps, { fetchQuestions })(QuestionContentsComponent)
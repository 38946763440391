//import React from 'react'
import styled from 'styled-components'

export const ListCard = styled.div`
	width: 100%;
	background-color: white;
	padding: 16px 24px;
	font-size: 16px;
	display: flex;
	flex-direction: row;
	margin-bottom: 16px;
	border-bottom: 1px solid #E5E5E5;
`
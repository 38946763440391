import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Favorite } from '@material-ui/icons'
import { PATH_SUBJECT_MATERIAL } from '../../constants';
import { MenuLink } from './MenuLink';

const Favourites = ({ favourites }) => {
	const { t } = useTranslation()
	//console.log("Favourites: ", favourites)
	return (
		<>
			{
				favourites.map( ({articleID, title}) => {
					//console.log("Favourite: ",articleId, title)
					return (
						<MenuLink
							key={`article-${articleID}`}
							icon={<Favorite />}
							to={`.${PATH_SUBJECT_MATERIAL}/${articleID}`}
							text={t(`${title}`)}
						/>
					)
				})
			}
		</>
	)
}
	
const mapStateToProps = state => {
	return {
		favourites: state.favourites
	}
}

export default connect(mapStateToProps)(Favourites)
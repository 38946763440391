import {
	FETCH_CUSTOMERS
} from "../actions/types";

export default (state = {}, action) => {
	switch (action.type) {
		
		case FETCH_CUSTOMERS:
			return {
				...state,
				...action.payload
			}
		default:
			return state;
	}
}
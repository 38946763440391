import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { CheckCircle } from '@material-ui/icons';
import { COLOR_GREEN } from '../../constants';
import { Upper } from '../common/text/Upper';

const Wrapper = styled.div`
	position: relative;
	background: ${props => props.selected ? "rgba(59, 127, 131, 0.12)" : "white"};
	border: ${props => props.selected ? "1px solid transparent" : "1px solid #E5E5E5"};
	/*opacity: 0.12;*/
	border-radius: 4px;
	color: ${props => props.selected ? "rgba(0, 0, 0, 0.87)" : "rgba(0, 0, 0, 0.6)"};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 8px 16px;
	cursor: pointer;
	@media screen and (min-width: 800px)
	{
		padding: 16px 32px;
	}
	@media screen and (min-width: 1200px)
	{
		padding: 32px 64px;
	}
`

const Checkmark = styled(CheckCircle)`
	position: absolute;
	top: 12px;
	right: 12px;
	width: 100px;
	height: auto;
	color: ${COLOR_GREEN};
`

const Title = styled.h4`
	font-weight: 500;
	letter-spacing: 0.25px;
	margin: 0;
	font-size: 24px;
	@media screen and (min-width: 800px)
	{
		font-size: 28px;
	}
	@media screen and (min-width: 1400px)
	{
		font-size: 34px;
	}
`

const Text = styled.p`
	font-size: 14px;
	letter-spacing: 0.1px;
	margin: 4px;
`

export const Bundle = ({ bundle, selected, onClick, currency }) => {
	const { title, description, devicesAllowed, price } = bundle
	const { t } = useTranslation()
	//console.log("Bundle! ", bundle, {title, description, devicesAllowed, price})
	
	const currentPrice = price.find( value => value.currency === currency )
	return (
		<Wrapper selected={selected} onClick={onClick}>
			{ selected && <Checkmark /> }
			<Title>{title}</Title>
			<Text>{`${devicesAllowed} ${t("ordering.bundles.licenses").toLowerCase()}.`}</Text>
			<Text>{description}.</Text>
			<Text>
				<Upper>
					{currentPrice.currency === "usd" ? "$" : currentPrice.currency} 
				</Upper>
				{` ${currentPrice.amount} ${(currentPrice.vat && currentPrice.vat > 0) ? t("ordering.form.payment.ex-vat") : ""}`}
			</Text>
		</Wrapper>
	)
}
import React from 'react'
import { connect } from 'react-redux'

import Supergroup from './Supergroup';
import { createSupergroup, fetchLicenses, fetchSupergroups } from '../../../redux/actions'
//import { InputButton } from './InputButton';
import { withTranslation } from 'react-i18next';

import { FourOhFour } from '../../common/FourOhFour'
//import { StyledPopInEditor } from '../../common/StyledPopInEditor'
//import { PopIn } from '../../common/PopIn';
//import { EditorPopIn } from '../../common/forms/EditorPopIn';
import { StyledEditorPopIn } from '../../common/forms/StyledEditorPopIn';
import { SquareButton } from '../../dashboard/SquareButton';
import { CircularButton } from '../../dashboard/CircularButton';
import { StyledLabelWrapper } from '../../common/forms/EditorStyling/StyledLabelWrapper';
import styled from 'styled-components'
import Title from '../../common/text/Title';
import Subtitle from '../../common/text/Subtitle';
import { Link } from '@reach/router';
import { SquareLink } from '../../dashboard/SquareLink';
import TopBar from '../../dashboard/TopBar';
import { FlexRow } from '../../common/flex/FlexRow'
import backend from '../../../apis/backend';
import { PATH_REGISTER, PATH_INVOICES, PATH_UPGRADE, COLOR_GREEN } from '../../../constants';
import { Tip } from '../../common/text/Tip';

const PopInWrapper = styled.div`
	height: ${props => props.height || "60px"};
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
`

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	@media screen and (min-width: 1100px)
	{
		grid-template-columns: repeat(3, 1fr);
	}
	@media screen and (min-width: 1500px)
	{
		grid-template-columns: repeat(4, 1fr);
	}
	grid-gap: 30px 40px;
	>*
	{
		margin-bottom: 0;
	}
`

const LicenseBar = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #E5E5E5;
	padding: 0 14px;
	margin-bottom: 16px;
`

const RightItems = styled(FlexRow)`
	>*
	{
		margin-right: 24px;
		&:last-child
		{
			margin-right: 0;
		}
	}
`

const InnerRightButtons = styled(RightItems)`
	flex-shrink: 0;
	>*
	{
		flex-shrink: 0;
	}
`

/*
const Text = styled.p`
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.5px;
	color: black;
	padding: 14px 0;
	margin: 0;
`*/

const StyledLink = styled(Link)`
	font-weight: 500;
	font-size: 14px;
	text-align: center;
	letter-spacing: 1.25px;
	text-transform: uppercase;
	color: ${COLOR_GREEN};
`

class LicenseSingle extends React.Component {
	
	componentDidMount() {
		//fetch data on this license
		this.props.fetchLicenses()
		this.props.fetchSupergroups()
	}
	
	/*
	componentDidUpdate() {
		//need to fetch ONCE, or this will break horribly
		const { license, supergroups, fetchSupergroups } = this.props
		const fetchedLicensesButNotSupergroups = (
			license &&
			(licenses.supergroups.length > 0) && 
			(supergroups === [])
		)
		if (fetchedLicensesButNotSupergroups) {
			fetchSupergroups()
		}
	}*/
	
	handleSubmit = (text) => {
		const { createSupergroup, licenseId} = this.props
		createSupergroup(licenseId, text)
	}
	
	render() {
		const { t, license, supergroups, location } = this.props
		//console.log("LicenseSingle render()",this.props)
		//console.log(license)
		//console.log("Render: ", location)
		if (license)
		{
			return (
				<div>
					<TopBar />
					<Title>{t("dashboard.licenses.single.license-belonging-to")}: {license.owner}</Title>
					<Subtitle>{t("dashboard.licenses.single.license")}: {license.license}</Subtitle>
					
					<Tip>{t("dashboard.licenses.single.tip")}</Tip>
					<LicenseBar>
						{
							/*
							<Text>{t("dashboard.licenses.single.license")}: {license.license}</Text>
							*/
						}
						<PopInWrapper>
							<StyledEditorPopIn
								onSubmit={this.handleSubmit}
								buttonText={t("dashboard.licenses.single.add-supergroup")}
								formButtonText={t("dashboard.licenses.single.add-supergroup")}
								button={SquareButton}
								formButton={SquareButton}
								labelWrapper={StyledLabelWrapper}
								height="80px"
							/>
						</PopInWrapper>
						<RightItems>
							<StyledEditorPopIn
								onSubmit={(email) => {
									backend.post(`/licenses/${license.id}/invite`, {
										email: email,
										successURL: `${location.origin}${PATH_REGISTER}`
									})
								}}
								buttonText={t("dashboard.licenses.single.invite.button")}
								button={SquareButton}
								label={t("dashboard.licenses.single.invite.email")}
								formButtonText={t("dashboard.licenses.single.invite.submit")}
								formButton={SquareButton}
							/>
							{
								license.isOwner && (
									<InnerRightButtons>
										<StyledLink to={`.${PATH_INVOICES}`}>{t("dashboard.licenses.single.invoice")}</StyledLink>
										<SquareLink to={`.${PATH_UPGRADE}`}>{t("dashboard.licenses.single.upgrade")}</SquareLink>
									</InnerRightButtons>
								)
							}
							

						</RightItems>
					</LicenseBar>
					<Grid>
						{
							supergroups.map( (supergroup) => {
								//console.log("superg", supergroup)
								return (
									<Supergroup
										key={supergroup.id}
										supergroup={supergroup}
										isOwner={license.isOwner}
									/>
								)
							})
						}
					</Grid>
					
					{
						/*
						<StyledPopInEditor
							onSubmit={this.handleSubmit}
							buttonText={t("dashboard.licenses.single.add-supergroup")}
						>
							{(onClick) => onClick()}
						</StyledPopInEditor>
						*/
					}
					
					{
						license.isOwner && (
							<PopInWrapper>
								<StyledEditorPopIn
									onSubmit={this.handleSubmit}
									buttonText={t("dashboard.licenses.single.add-supergroup")}
									formButtonText={t("dashboard.licenses.single.add-supergroup")}
									button={CircularButton}
									formButton={CircularButton}
									labelWrapper={StyledLabelWrapper}
									height="80px"
								/>
							</PopInWrapper>
						)
					}
					
					{
						/*
						<InputButton onSubmit={this.handleSubmit}>
							{t("dashboard.licenses.single.add-supergroup")}
						</InputButton>
						*/
					}
					
				</div>
			)
		}
		return <FourOhFour>{t("dashboard.licenses.single.404")}</FourOhFour>
	}
}

const mapStateToProps = (state, ownProps) => {
	//console.log("Mapstate")
	const license = state.licenses[ownProps.licenseId]
	//console.log("license", license)
	/*
	const supergroupsExist = Object.keys(state.supergroups).length > 0
	
	const supergroups = license && supergroupsExist ? license.supergroups.map( (supergroupId) => (
		state.supergroups[supergroupId]
	)) : []*/
	const stateSupergroupsExist = Object.keys(state.supergroups).length > 0
	const supergroups = license && stateSupergroupsExist ? license.supergroups.map( (supergroupId) => (
		state.supergroups[supergroupId]
	)) : []
	
	return {
		license,//,
		supergroups
	}
}

export default connect(mapStateToProps, { createSupergroup, fetchLicenses, fetchSupergroups })(
	withTranslation()(LicenseSingle)
)
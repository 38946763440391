import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

import { performLogin } from '../../redux/actions'

import { Title } from '../common/text/Title'
import { FlexRow } from '../common/flex'
import { PATH_DASHBOARD } from '../../constants'

const Wrapper = styled(FlexRow)`
	width: 100%;
	height: 100vh;
	justify-content: center;
	align-items: center;
`

class FeideLoginDestinationComponent extends React.Component {
	componentDidMount() {
		console.log("FeideLoginDestination")
		const { performLogin, token, navigate } = this.props
		performLogin(token, false)
		navigate(PATH_DASHBOARD)
	}
	render() {
		const { t } = this.props
		return (
			<Wrapper>
				<Title>{t("general.messages.logging-in")}...</Title>
			</Wrapper>
		)
	}
}

export const FeideLoginDestination = connect(null, { performLogin })(
	withTranslation()(FeideLoginDestinationComponent)
)
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { ListCardHeader } from '../../common/ListCardHeader'
import { createQuestion, fetchQuestions } from '../../../redux/actions'
import { Question } from './Question'
import { Fill } from './common'
import { CircularButton } from '../../dashboard/CircularButton'
import { FlexRight } from '../../common/flex/FlexRight'
import { StyledPopInEditor } from '../../common/StyledPopInEditor'

class QuestionsComponent extends React.Component{
	
	componentDidMount() {
		const { fetchQuestions, groupId } = this.props
		fetchQuestions(groupId)
	}
	
	render() {
		const { questions, createQuestion, groupId, t } = this.props
		//console.log("Questions", questions)
		return (
			<div>
				<ListCardHeader>
					<Fill>
						{t("dashboard.ask-players.questions")}
					</Fill>
				</ListCardHeader>
				{
					questions.map( ({id, title}) => {
						return (
							<Question key={id} id={id} title={title} />
						)
					})
				}
				
				<FlexRight>
					<StyledPopInEditor
						onSubmit={(title) => {
							createQuestion(groupId, title)
						}}
						buttonText={t("dashboard.questions.add-question-confirm")}
						label={t("dashboard.questions.new-question")}
					>
						{
							(onClick) => (
								<CircularButton onClick={ () => {
									console.log("Clicked to add question")
									onClick()
								}}>
									{t("dashboard.ask-players.add-question")}
								</CircularButton>
							)
						}
					</StyledPopInEditor>
				</FlexRight>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		questions: Object.values(state.questions)
	}
}

export const Questions = connect(mapStateToProps, { createQuestion, fetchQuestions })(
	withTranslation()(QuestionsComponent)
)
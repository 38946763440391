import React from 'react'
import styled from 'styled-components'
import { Link as ReachLink } from '@reach/router';
import { Item, ItemWide, Fat, Right, Centered } from './common'
import { useTranslation } from 'react-i18next';
import { COLOR_GREEN } from '../../../constants';

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	background-color: white;
	border-bottom: 2px solid #E5E5E5;
	padding: 20px 26px;
	font-size: 16px;
	margin-bottom: 16px;
`

const Link = styled(ReachLink)`
	text-decoration: none;
	color: ${COLOR_GREEN};
	font-weight: 500;
	letter-spacing: 0.5px;
`

const LicenseListItem = ({id, owner, amount, users }) => {
	const { t } = useTranslation()
	return (
		<Wrapper>
			<ItemWide>
				<Fat>
					{owner}
				</Fat>
			</ItemWide>
			
			<Item>
				<Centered>
					{users}
				</Centered>
			</Item>
			
			<Item>
				<Centered>
					{amount}
				</Centered>
			</Item>
			
			<Item>
				<Link to={`./${id}`}>
					<Fat>
						<Right>
							{t("dashboard.licenses.show")}
						</Right>
					</Fat>
				</Link>
			</Item>
		</Wrapper>
	)
}

export default LicenseListItem
import React from 'react'
//import { Link } from '@reach/router';

import TopBar from '../../dashboard/TopBar'
import { ArticleList } from '../../articles/ArticleList'
import { PATH_CREATE, PATH_VIEW } from '../../../constants'
import { useTranslation } from 'react-i18next'
import { CircularLink } from '../../dashboard/CircularLink'

import FlexRow from '../../common/flex/FlexRow'

export const AdminSpecialArticles = () => {
	const { t } = useTranslation()
	return (
		<div>
			<TopBar />
			
			<ArticleList special edit viewPath={PATH_VIEW} />
			
			<FlexRow>
				<CircularLink to={`.${PATH_CREATE}`}>
					{t("admin.create-button")}
				</CircularLink>
			</FlexRow>
			
		</div>
	)
}

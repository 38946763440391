import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { fetchProfile } from '../../redux/actions'

import { flexColumn, flexCenter } from '../common/style'
import { withTranslation } from 'react-i18next'
import { Card } from '../common/Card/Card'
//import CardExtended from '../common/Card/CardExtended';
import ImageOverflowCard from '../common/Card/ImageOverflowCard'

import karenKlaus from '../../img/karen-klaus.png'
import Title from '../common/text/Title'
import Subtitle from '../common/text/Subtitle'
import { PATH_QUESTIONS, PATH_LICENSES, PATH_SUBJECT_MATERIAL, PATH_GUIDE } from '../../constants'

import { Loading } from '../dashboard/Loading'

const CardRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	>*
	{
		margin-right: 20px;
		&:last-child
		{
			margin-right: 0;
		}
	}
`

class DashboardLandingComponent extends React.Component {
	
	componentDidMount() {
		this.props.fetchProfile()
	}
	//const DashboardLanding = () => {
	render() {
		const { t, profile } = this.props
		
		if (!profile) return <Loading />
		//const username = "Kåre Bananer" //Todo: Remove (this was for testing)
		return (
			<div style={{
				...flexCenter
			}}>
				<div style={{
					...flexCenter,
					...flexColumn,
					padding: "20px",
					maxWidth: "1000px"
				}}>
					<div style={{ ...flexCenter, ...flexColumn }}>
						<Title>{t("dashboard.title")} {profile.username}</Title>
						<Subtitle>{t("dashboard.subtitle")}</Subtitle>
					</div>
					<div style={{ ...flexCenter }}>
						
					</div>
					
					<ImageOverflowCard
						title={t("dashboard.landing.get-started.title")}
						text={t("dashboard.landing.get-started.text")}
						buttonText={t("dashboard.landing.get-started.button")}
						buttonTo={`.${PATH_GUIDE}`}
						image={karenKlaus}
					/>
					
					<CardRow>
						
						<Card
							title={t("dashboard.landing.your-groups.title")}
							text={t("dashboard.landing.your-groups.text")}
							buttonText={t("dashboard.landing.your-groups.button")}
							buttonTo={`.${PATH_LICENSES}`}
							backgroundColor="white"
							//style={{ margin: "10px" }}
						/>
						
						<Card
							title={t("dashboard.landing.questions.title")}
							text={t("dashboard.landing.questions.text")}
							buttonText={t("dashboard.landing.questions.button")}
							buttonTo={`.${PATH_QUESTIONS}`}
						/>
						
						<Card
							title={t("dashboard.landing.teachers.title")}
							text={t("dashboard.landing.teachers.text")}
							buttonText={t("dashboard.landing.teachers.button")}
							buttonTo={`.${PATH_SUBJECT_MATERIAL}`}
							backgroundColor="white"
							//style={{ margin: "10px" }}
						/>
						
						
					</CardRow>
					
				</div>
			</div>
			
		)
	}
	
}

const mapStateToProps = state => {
	return {
		profile: state.profile
	}
}

export const DashboardLanding = connect(mapStateToProps, { fetchProfile })(
	withTranslation()(DashboardLandingComponent)
)
import styled from 'styled-components'
import { Link } from '@reach/router'

export const TopBarLink = styled(Link)`
	display: flex;
	align-items: center;
	color: ${props => props.color || "black"};
	/*margin-left: 30px;
	margin-right: 30px;*/
	padding: 8px; /* Hitbox helper */
	cursor: pointer;
`
import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

import { toggleFilterView, fetchFilters } from '../../redux/actions'

import { FlexRow } from '../common/flex'
import FilterList from './FilterList'

import { GoalsModal } from './GoalsModal'
import { SquareButton } from '../dashboard/SquareButton'
//import { Tip } from '../common/text/Tip';

const Wrapper = styled.div`
	margin-bottom: 16px;
`

const GoalsWrapper = styled.div`
	/*width: 100%;*/
	padding: 8px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
`

class ArticleFilters extends React.Component {
	
	state = {
		openModal: false
	}
	
	componentDidMount() {
		//fetch filters
		
		//add this if we want more performance and there are no problems
		//if (!filters) {
		//console.log("Component mounted, fetching filters!")
		this.props.fetchFilters()
		//}
	}
	
	render() {
		const { t, showFilter, filters } = this.props
		//const { t } = useTranslation()
		//console.log("!filters",!filters, "filters", filters)
		
		if (!showFilter || filters.length < 1) return <></>
		
		return (
			<Wrapper>
				{
					this.state.openModal && (
						<GoalsModal
							onClose={() => this.setState({ openModal: false })}
							goals={filters.goals}
						/>
					)
				}
				{/*<Tip>{t("dashboard.articles.filter.tip")}</Tip>*/}
				<FlexRow style={{alignItems: "normal", justifyContent: "space-around"}}>
					<FilterList name="ageGroup"/>
					<FilterList name="topic" />
					<FilterList name="subject" />
					<GoalsWrapper>
						<h3 style={{margin: 0, padding: 0, paddingBottom: "4px"}}>{t(`filters.goal`)}</h3>
						<SquareButton onClick={() => this.setState({ openModal: true })}>
							{t("filters.goals-button")}
						</SquareButton>
					</GoalsWrapper>
				</FlexRow>
				{ /*<DividerFlex>
					<FilterList name="goal" />
				</DividerFlex>*/ }
			</Wrapper>
		)
	}
}

const mapStateToProps = state => {
	return {
		showFilter: state.showFilterView,
		filters: Object.values(state.filters)
	}
}
	
export default connect(mapStateToProps, { toggleFilterView, fetchFilters })(
	withTranslation()(
		ArticleFilters
	)
)
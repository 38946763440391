import React from 'react'
import { Form } from 'formik'
import { useTranslation } from 'react-i18next'

import { PrettyField } from '../../forms/PrettyField'
import { SquareButton } from '../../dashboard/SquareButton'
import { ValidationForm, FORM_ACTION_PUT } from '../../forms/ValidationForm'

export const ChangePassword = ({ onComplete }) => {
	const { t } = useTranslation()
	
	const PasswordField = ({name, ...rest}) => {
		//console.log(rest)
		return (
			<PrettyField name={name} i18nPath="dashboard.profile.password" {...rest} />
		)
	}
	
	return (
		<ValidationForm
			initialValues={{
				oldPassword: "",
				newPassword: ""
			}}
			operation={FORM_ACTION_PUT}
			path="/profile/password"
			onSuccess={() => onComplete()}
			render={ props => {
				//console.log("ChangePassword, form render", props)
				return(
					<Form>
						<PasswordField
							name="oldPassword"
							type="password"
							touched={props.touched}
							errors={props.errors}
						/>
						<PasswordField
							name="newPassword"
							type="password"
							touched={props.touched}
							errors={props.errors}
						/>
						
						<SquareButton type="submit">
							{t("dashboard.profile.password.submit")}
						</SquareButton>
					</Form>
				)
			}}
		/>
	)
}
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Location, navigate } from '@reach/router'

import { SquareButton } from '../../../dashboard/SquareButton'
import backend from '../../../../apis/backend'
import { PATH_FEIDE_DESTINATION, PATH_FEIDE_ERROR } from '../../../../constants'

export const FeideLogin = ({ ...rest }) => {
	const { t } = useTranslation()
	return (
		<Location>
			{({ location }) => (
				<SquareButton onClick={async () => {
					try {
						const response = await backend.post("/login/feide", {
							successURL: `${location.origin}${PATH_FEIDE_DESTINATION}`,
							errorURL: `${location.origin}${PATH_FEIDE_ERROR}`
						})
						console.log({ response })
						navigate(response.data.data.redirectURL)
					}
					catch (error) {
						console.error(error)
						console.log({ ...error })
					}
				}} {...rest}>
					{t("buttons.feide")}
				</SquareButton>
			)}
		</Location>
	)
}
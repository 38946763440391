//import _ from 'lodash';
import {
	TOGGLE_FILTER, FETCH_FILTERS
} from '../actions/types';

export default (state = {}
	/*
	ageGroup: {
		1: {
			id: 1,
			selected: true,
			text: "1. klasse",
			i18nString: "dashboard.articles.filter.levels.1st"
		},
		2: {
			id: 2,
			selected: false,
			text: "2. klasse",
			i18nString: "dashboard.articles.filter.levels.2nd"
		}
	},
	topic: {
		1: {
			id: 1,
			selected: true,
			text: "Mobbing",
			i18nString: "dashboard.articles.filter.themes.bullying"
		},
		2: {
			id: 2,
			selected: true,
			text: "Etterkrigstiden",
			i18nString: "dashboard.articles.filter.themes.post-war"
		}
	},
	goal: {
		1: {
			id: 1,
			selected: true,
			text: "Ha kunnskap om nazisme",
			i18nString: "dashboard.articles.filter.goals.nazism"
		},
		2: {
			id: 2,
			selected: false,
			text: "Ha god form",
			i18nString: "dashboard.articles.filter.goals.good-shape"
		},
		3: {
			id: 3,
			selected: false,
			text: "Kunne bake kake",
			i18nString: "dashboard.articles.filter.goals.bake-cake"
		}
	},
	subject: {
		1: {
			id: 1,
			selected: true,
			text: "Naturfag",
			i18nString: "dashboard.articles.filter.subjects.nature"
		},
		2: {
			id: 2,
			selected: false,
			text: "Gymnastikk",
			i18nString: "dashboard.articles.filter.subjects.gymnastics"
		},
		3: {
			id: 3,
			selected: false,
			text: "Norskk",
			i18nString: "dashboard.articles.filter.subjects.norwegian"
		}
	}
}*/, action) => {
	switch (action.type) {
		case FETCH_FILTERS:
			return {
				...action.payload
			}
		case TOGGLE_FILTER:
			//console.log("filterReducer", state, action)
			//console.log(state[action.payload.item])
			return {
				...state,
				[action.payload.category]: {
					...state[action.payload.category],
					[action.payload.item]: {
						...state[action.payload.category][action.payload.item],
						selected: !state[action.payload.category][action.payload.item].selected //reverse value in state
					}
				}
			}
		default:
			return state;
	}
}
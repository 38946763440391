//import React from 'react'
import styled from 'styled-components'

/*const CardBase = ({ style, children, ...rest}) => (
	//<div style={{width: "100%"}}>
		<div
			className="card"
			style={{
				flex: "1",
				backgroundColor: "#FFF9EE",
				border: "1px solid #E5E5E5",
				borderRadius: "4px",
				padding: "21px",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				width: "100%",
				...style
			}}
			{...rest}
		>
			{children}
		</div>
	//</div>
)*/

export const CardBase = styled.div`
	display: flex;
	background-color: ${props => props.backgroundColor || "#FFF9EE"};
	border: 1px solid #E5E5E5;
	border-radius: 4px;
	padding: 21px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
`
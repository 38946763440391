import { LOGIN, LOGOUT } from "../actions/types";

const defaultState = {
	isLoggedIn: false,
	token: "",
	isAdmin: undefined
}

export default (state = {
	...defaultState
}, action) => {
	switch (action.type) {
		case LOGIN:
			return {
				isLoggedIn: true,
				...action.payload
			}
		case LOGOUT:
			return {
				isLoggedIn: false,
				...defaultState
			}
		default:
			return state;
	}
}
import React from 'react'

//import { ReactComponent as CheckOff } from '../../img/icons/comment/check_off.svg'
//import { ReactComponent as CheckOn } from '../../img/icons/comment/check_on.svg'

import styled from 'styled-components'

const Wrapper = styled.div`
	cursor: pointer;
`

export const Switch = ({ checked, imgOn, imgOff, onClick })  => (
	<Wrapper onClick={onClick}>
		{ checked ? <img src={imgOn} alt="" /> : <img src={imgOff} alt="" /> }
	</Wrapper>
)
import React from 'react'
import { connect } from 'react-redux'

import Title from '../../common/text/Title'
import Subtitle from '../../common/text/Subtitle'
import TopBar from '../../dashboard/TopBar'
import { UserDataForm } from './UserDataForm'

import { fetchUserData } from '../../../redux/actions'
import { ChangePassword } from './ChangePassword'
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { COLOR_GREEN } from '../../../constants'

const Wrapper = styled.div`
	max-width: 800px;
	width: 100%;
	/*margin: 0 8px;*/
	/*display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;*/
	@media screen and (min-width: 800px)
	{
		width: 90%;
		margin: 0 auto;
	}
	@media screen and (min-width: 900px)
	{
		width: 80%;
	}
	@media screen and (min-width: 1000px)
	{
		width: 70%;
	}
	@media screen and (min-width: 1200px)
	{
		width: 60%;
		margin: 0 auto;
	}
`


const TextButton = styled.button`
	font-weight: 500;
	font-size: 14px;
	cursor: pointer;
	text-align: center;
	letter-spacing: 1.25px;
	text-transform: uppercase;
	background: transparent;
	border: none;
	color: ${props => props.color || COLOR_GREEN};
	padding: 8px;
	${props => {
		if (props.align) {
			switch (props.align.toLowercase()) {
				case "left":
					return (`
						margin-left: 0;
						margin-right: auto;
					`)
				case "right":
					return (`
						margin-left: auto;
						margin-right: 0;
					`)
				case "center":
					return (`
						margin-left: auto;
						margin-right: auto;
					`)
				default:
					return "margin: 0;"
			}
		}
	}}
	/*margin-left: ${props => (props.align && props.align.toLowercase() === "left" && "auto") || 0}
	margin-right: ${props => (props.align && props.align.toLowercase() === "left") || 0}*/
`

const RightTextButton = styled(TextButton)`
	display: block;
	margin-left: auto;
	margin-right: 0;
`

class UserDataComponent extends React.Component {

	state = {
		openPasswordChange: false,
	}
	
	componentDidMount() {
		//fetch specific user
		const { id, fetchUserData } = this.props
		fetchUserData(id)
	}
	
	render() {
		const { t, userData } = this.props
		
		if (!Object.keys(userData).length) return (
			<div>
				<TopBar />
				
				<Title>...loading</Title>
			</div>
		)
		const { name, email } = userData

		return (
			<div>
				<TopBar/>
				
				<Wrapper>
					<Title>{name}</Title>
					<Subtitle>{email}</Subtitle>

					<UserDataForm
						userData={userData}
					>
						{
							!this.state.openPasswordChange && (
								<RightTextButton
									onClick={() => this.setState({openPasswordChange: true})}
								>
									{t("admin.userdata.change-password")}
								</RightTextButton>
							)
						}
					</UserDataForm>
					
					{
						this.state.openPasswordChange && (
							<ChangePassword
								userId={userData.id}
								onComplete={() => this.setState({ openPasswordChange: false })}
							/>
						)
					}
	
				</Wrapper>
				
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		isAdmin: state.auth.isAdmin,
		userData: state.userData
	}
}

export const UserData = connect(mapStateToProps, { fetchUserData })(
	withTranslation()(UserDataComponent)
)
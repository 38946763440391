import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div``

const Text = styled.p`
	font-size: 1.25rem;
	line-height: 2;
	border-left: 2px solid #888;
	padding-left: 2rem;
	margin: 0 2rem;
	font-style: italic;
`

const Source = styled.p`
	/*margin: 0 2rem;*/
	display: block;
	font-size: 1rem;
	line-height: initial;
	font-weight: 500;
	margin: 1rem 0 0 5rem;
	text-align: right;
`

export const Quote = ({ text, source }) => (
	<Wrapper>
		<Text>
			{text}
		</Text>
		<Source>
			{source}
		</Source>
	</Wrapper>
)
import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { fetchSupergroups } from '../../../redux/actions'

import { ChooseGroup } from './ChooseGroup';
import { ListSelection } from './common';
import TopBar from '../../dashboard/TopBar';

import Title from '../../common/text/Title'
import { Tip } from '../../common/text/Tip'

//Does what?
/*
	Check if data is in redux. If not, fetch.
	Then, display all supergroups from all licenses as buttons in a grid. Picking one takes you to a subpage where you can pick the group. Once you choose that, we need some kind of visual confirmation. For simplicity, I'm thinking a redirect to the dashboard landing.
*/

class ChooseGroupsComponent extends React.Component {
	
	state = {
		chosenSupergroup: undefined
	}
	
	componentDidMount() {
		//fetch licenses
		//this.props.fetchLicenses()
		this.props.fetchSupergroups()
	}
	
	render() {
		const { chosenSupergroup } = this.state
		const { supergroups, t } = this.props
		return (
			<div>
				{
					!chosenSupergroup ?
						<TopBar /> :
						<TopBar onClickBack={() => this.setState({ chosenSupergroup: undefined })} />
				}
				<Title>
					{
						chosenSupergroup
							? t("dashboard.choose-group")
							: t("dashboard.choose-group.supergroup")
					}
				</Title>
				{
					!chosenSupergroup
					?
						<>
							<Tip>
								{t("dashboard.choose-group.supergroup.tip")}
							</Tip>
							<ListSelection
								list={supergroups}
								onClick={(item) => {
									this.setState({
										chosenSupergroup: item.id
									})
								}}
								selected={this.state.chosenSupergroup}
							/>
						</>
					:
						<>
							<Tip>
								{t("dashboard.choose-group.tip")}
							</Tip>
							<ChooseGroup
								supergroupId={this.state.chosenSupergroup}
								onClickBack={() => this.setState({ chosenSupergroup: undefined })}
							/>
						</>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		//licenses: Object.values(state.licenses).sort(),
		supergroups: Object.values(state.supergroups).sort()
	}
}

export const ChooseGroups = connect(
	mapStateToProps,
	{
		//fetchLicenses,
		fetchSupergroups,
		//fetchGroups
	}
)(
	withTranslation()(
		ChooseGroupsComponent
	)
)
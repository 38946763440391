import React from 'react'

const CardContents = ({title, text}) => (
	<div>
		<h3 style={{
			margin: "0",
			fontWeight: "500",
			fontSize: "20px",
			letterSpacing: "0.15px",
			color: "rgba(0, 0, 0, 0.87)"
		}}>{title}</h3>
		<p style={{
			fontSize: "14px",
			letterSpacing: "0.25px",
			color: "rgba(0, 0, 0, 0.6)"
		}}>{text}</p>
	</div>
)

export default CardContents
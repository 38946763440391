import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

import style from './Menu.module.scss'
//import { noMargins, fullWidth } from '../common/style'

import {
	Language,
	//LiveHelp,
	PowerSettingsNew,
	Face
} from '@material-ui/icons'

//import Thin from '../common/text/Thin'
import Separator from '../common/Separator'
//import MenuText from '../menu/MenuText'
//import MenuTwoLineText from '../menu/MenuTwoLineText'
import { logout, wipeSelections, fetchProfile } from '../../redux/actions'
import LanguageSwitch from '../LanguageSwitch';
import { PATH_PROFILE } from '../../constants';
import { MenuButton } from '../menu/MenuButton';
import { MenuItem } from '../menu/MenuItem';
import { TwoLineText } from '../menu/TwoLineText';
import { MenuLink } from '../menu/MenuLink';

const Text = styled.p`
	width: 100%;
	margin: 0;
`

const ProfileTitle = styled(Text)`
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.15px;
`

const ProfileSubtitle = styled(Text)`
	font-size: 10px;
	line-height: 10px;
	letter-spacing: 0.25px;
	font-weight: 400;
`

class Menu extends React.Component {
//const Menu = ({children, bottomMenuItems, logout, wipeSelections, profile}) => {
	componentDidMount() {
		this.props.fetchProfile()
	}	

	render() {
		const { children, bottomMenuItems, logout, /*wipeSelections,*/ profile, t } = this.props
		//const { t } = useTranslation();
		//console.log("Profile: ", profile)
		//console.log("Profile.length: ", Object.keys(profile).length)
		//console.log("Profile.email: ", profile.email)
		let profileName = t("menu.profile.default")
		if (Object.keys(profile).length && profile.name && profile.email) {
			profileName = profile.name
		}
		
		return (
			<nav className={style.nav} /*className={menuStyle.nav}*/>
				
				<div className={style.top}>
					{children}
				</div>
				
				<div>
					{/* <p
						onClick={() => {
							console.log("Clicked wipe")
							wipeSelections()}
						}
						style={{cursor: "pointer"}}
					>
							Wipe selection
					</p> */}
					
					{bottomMenuItems}
					
					<Separator color="#E5E5E5" />
					
					<MenuLink to={`.${PATH_PROFILE}`} icon={<Face />}>
						<TwoLineText
							title={profileName}
							titleComponent={ProfileTitle}
							subtitle={profile && Object.keys(profile).length > 0 ? profile.email : ""}
							subtitleComponent={ProfileSubtitle}
						/>
					</MenuLink>
					
					<Separator color="#E5E5E5" />
					
					<LanguageSwitch>
						<MenuItem
							icon={<Language />}
							text={t("language-switch.label")}
						/>
					</LanguageSwitch>
					
					<Separator color="#E5E5E5" />
					
					<MenuButton
						onClick={() => { logout() }}
						icon={<PowerSettingsNew />}
						color="#B30000"
						text={t("menu.log-out")}
					/>
				</div>
				
			</nav>
		)
	}
}

const mapStateToProps = state => {
	return {
		profile: state.profile
	}
}

export default connect(mapStateToProps, { logout, wipeSelections, fetchProfile })(
	withTranslation()(Menu)
);
import React from 'react'
import { connect } from 'react-redux'

import TopBarFiller from '../../dashboard/TopBarFiller';
import CustomersList from './CustomersList';
import { fetchCustomers } from '../../../redux/actions'
import { withTranslation } from 'react-i18next';
import Title from '../../common/text/Title';
import { SearchBar} from './common';

class Customers extends React.Component {

	state = {
		searchValue: ''
	}

	handleOnChange = e => {
		const {value, name} = e.currentTarget;
	  
		this.setState({...this.state, [name]: value});
	};

	componentDidMount() {
		this.props.fetchCustomers()
	}
	
	render() {
		const { t } = this.props

		console.log("Fetch Customers Props", this.props)

		const filteredCustomers= this.props.customers.filter(customer=> {
			return customer.email.toLowerCase().includes(this.state.searchValue.toLowerCase()) ||
			customer.firstName?.toLowerCase().includes(this.state.searchValue.toLowerCase()) ||
			customer.lastName?.toLowerCase().includes(this.state.searchValue.toLowerCase()) ||
			customer.companyName?.toLowerCase().includes(this.state.searchValue.toLowerCase());

		})

		const customersToRender = !this.state.searchValue ? this.props.customers: filteredCustomers
		return (
			<div>
				<SearchBar 
					placeholder="Search"
					type = "text"
					name = "searchValue"
					value={this.state.searchValue}
					onChange={this.handleOnChange}
				/>
				<TopBarFiller />
				<Title>{t("admin.customers")}</Title>
				<CustomersList>
					{customersToRender}
				</CustomersList>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		isAdmin: state.auth.isAdmin,
		customers: Object.values(state.customers)
	}
}

export default connect(mapStateToProps, { fetchCustomers })(
	withTranslation()(Customers)
)
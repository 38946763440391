import React from 'react'
//import { Link } from '@reach/router'
import { useTranslation } from 'react-i18next'

import Title from '../common/text/Title'
import TopBarFiller from './TopBarFiller'

export const Loading = () => {
	const { t } = useTranslation()
	return (
		<div>
			<TopBarFiller />
			
			<Title>
				...{t("dashboard.elements.loading")}
			</Title>
		</div>
	)
}
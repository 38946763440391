import React from "react"
import { Breakpoint } from "react-socks"
import styled from "styled-components"

//import Title from '../../common/text/Title';
import Subtitle from "../../common/text/Subtitle"
import ShrinkableDiv from "../../common/ShrinkableDiv"
import FlexRow from "../../common/flex/FlexRow"

import appStore from "../../../img/appstore/appstore.png"
import googlePlay from "../../../img/appstore/googleplay.png"
//import fishingWithKarin from '../../../img/screenshots/Fishing-with-Karin.jpg';
//import outsideSummer from '../../../img/screenshots/Outside-summer.jpg';
import homeKlaus from "../../../img/screenshots/home-klaus.jpg"
import homeKarin from "../../../img/screenshots/home-karin.jpg"
import dashboardScreenshot from "../../../img/screenshots/dash-preview.png"
//import videoScreenshot from '../../../img/screenshots/video.png';
import ExternalLink from "../../common/ExternalLink"

import style from "./Landing.module.scss"
import Header from "./Header"
import ResponsiveColumns from "./ResponsiveColumns"
import Footer from "./Footer"
import { Quote } from "../../landing/Quote"
import { OrderButtonSmall } from "../../landing/buttons/OrderButtonSmall"
import { ErrorPopup } from "./ErrorPopup"
import { TextLink } from "../../landing/TextLink"
import { FlexColumn as BaseFlexColumn } from "../../common/flex"

import appleSchool from "../../../img/apple_school.png"
import appleBusiness from "../../../img/apple_business.png"

const Wrapper = styled.div`
    background-color: #fff9ee;
`

const Content = styled.div`
    padding: 16px;
`

const Section = styled.div`
    margin: 2rem auto;
`

/*
const PaddedSection = styled(Section)`
	padding: 0 6rem;
`

const BreakpointPadding = styled.div`
	@media screen and (min-width: ${props => props.breakpoint})
	{
		${props => props.padding && css`padding: ${props.padding};`}
	}
`*/

const ContentWrapper = styled.div`
    /*margin: 0 5rem;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: 1200px) {
        margin: 0 8rem;
    }
`

/*
const CenterContent = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 2rem;
`

const ImageWrap = styled.div`
	max-height: 300px;
`*/

const TextContent = styled.p`
    font-size: 1.25rem;
    line-height: 1.8;
`

const BoldText = styled(TextContent)`
    font-weight: 600;
`

const Bold = styled.strong``

const Title = styled.h1`
    font-size: 3.2rem;
    text-align: center;
    font-weight: bold;
    letter-spacing: 0.510899px;
    color: black;
    margin: 64px auto;
`

const Iframe = styled.iframe`
    max-width: 100%;
    border: 0;
`

const FlexColumn = styled(BaseFlexColumn)`
    > * {
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0;
        }
    }
`

const Title3 = styled(Subtitle)`
    font-size: 28px;
    text-align: left;
`

const Landing = ({ error }) => {
    //const { t } = useTranslation();
    console.log({ error })
    return (
        <Wrapper>
            {error && <ErrorPopup />}

            <Header />

            <Content>
                <ContentWrapper>
                    <Title>Et innovativt, digitalt læremiddel</Title>
                    <Subtitle fontSize="1.7rem">
                        Spill «My Child Lebensborn» som en gruppe. Vis felles
                        spillstatistikk på storskjerm. Velg mellom ferdiglagde
                        undervisningsopplegg og kommuniser med gruppa via
                        spillapp på mobil. «My Child Lebensborn EDU» er en
                        digital klasseromsløsning for det prisbelønte,
                        dokumentaristiske mobilspillet «My Child Lebensborn».
                    </Subtitle>

                    <Section>
                        <ResponsiveColumns
                            breakpoint="600px"
                            left={
                                <Iframe
                                    width="570"
                                    height="360"
                                    src="https://www.youtube.com/embed/onkHe-z89Zk"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                />
                            }
                            right={
                                <ShrinkableDiv>
                                    <TextContent>
                                        Forsvinner hatet mot fienden av seg
                                        selv? «My Child Lebensborn» forteller
                                        den sanne historien om skjebnene til
                                        norske tyskerbarn etter 2. verdenskrig.
                                        <br />
                                        Du spiller som en adoptivforelder som
                                        skal ta vare på ditt barn i Norge i
                                        1951. Det er ikke enkelt når
                                        lokalsamfunnet kjenner barnets bakgrunn
                                        og hatet mot den tyske okkupasjonsmakten
                                        ennå er sterkt.
                                        <br />
                                        <br />
                                        Hva kan du gjøre? Hvordan kan du
                                        beskytte et barn som ikke forstår hva
                                        som skjer? Spillet er utviklet i
                                        samarbeid med norske Lebensborn-barn og
                                        er representativt for deres historie.
                                    </TextContent>
                                </ShrinkableDiv>
                            }
                        />
                    </Section>

                    <Section>
                        <ResponsiveColumns
                            breakpoint="600px"
                            left={
                                <ShrinkableDiv>
                                    <Subtitle>
                                        Hva skjer når krigen er over?
                                    </Subtitle>
                                    <TextContent>
                                        Spillet presenterer også historien bak
                                        Nazistenes Lebensborn-program, dets
                                        rasetenkning, og hvilke konsekvenser det
                                        fikk for enkelte tyskerbarn. Mest av alt
                                        viser spillet effektene av mobbing og
                                        mishandling på et barn som blir utstøtt
                                        fra sitt eget lokalsamfunn uten å forstå
                                        hvorfor.
                                    </TextContent>
                                </ShrinkableDiv>
                            }
                            right={
                                <img
                                    className={style.columnImg}
                                    src={homeKlaus}
                                    alt="Screenshot of Klaus at home"
                                />
                            }
                        />
                    </Section>

                    <Section>
                        <ResponsiveColumns
                            breakpoint="600px"
                            left={
                                <img
                                    className={style.dashScreenshotImg}
                                    src={dashboardScreenshot}
                                    alt="Screenshot of Karin fishing"
                                />
                            }
                            right={
                                <ShrinkableDiv>
                                    <Subtitle>
                                        Dialog mellom storskjerm og app
                                    </Subtitle>
                                    <TextContent>
                                        Denne nettsiden, i kombinasjon med
                                        spillets mobilapp, utgjør i fellesskap
                                        et interaktivt læremiddel for skoler og
                                        grupper. Lærer eller gruppeleder får
                                        tilgang til over 70 ferdige
                                        undervisningsopplegg for mellomtrinn,
                                        ungdomsskole, videregående skole eller
                                        voksne.
                                    </TextContent>
                                    <TextContent>
                                        Oppgavene kan sorteres på relevante
                                        læringsmål og dekker temaer som mobbing,
                                        menneskeverd, 2. verdenskrig og etikk.
                                        Spillet er også oversatt til mange språk
                                        og kan brukes i språkundervisning.
                                    </TextContent>
                                </ShrinkableDiv>
                            }
                        />
                    </Section>

                    <Section>
                        <ShrinkableDiv>
                            <Breakpoint tablet up>
                                <FlexRow
                                    style={{
                                        justifyContent: "center",
                                        marginBottom: "3rem",
                                        width: "400px",
                                    }}
                                >
                                    <OrderButtonSmall />
                                </FlexRow>
                                <FlexRow
                                    style={{
                                        justifyContent: "center",
                                        marginVertical: "8px",
                                        width: "400px",
                                    }}
                                >
                                    <ExternalLink to="https://play.google.com/store/apps">
                                        <img
                                            style={{
                                                marginRight: "2px",
                                                maxWidth: "200px",
                                            }}
                                            src={googlePlay}
                                            alt="Google play logo"
                                        />
                                    </ExternalLink>
                                    {/* <ExternalLink to="https://apps.apple.com/no/app/apple-store/id375380948">
                                        <img
                                            style={{
                                                marginRight: "2px",
                                                maxWidth: "200px",
                                            }}
                                            src={appStore}
                                            alt="Apple appstore logo"
                                        />
                                    </ExternalLink> */}
                                </FlexRow>
                            </Breakpoint>
                        </ShrinkableDiv>
                    </Section>

                    <Section>
                        <ResponsiveColumns
                            breakpoint="900px"
                            left={
                                <ShrinkableDiv>
                                    <Subtitle>Slik kommer du i gang</Subtitle>
                                    <TextContent>
                                        For å bruke løsningen må
                                        elever/deltakere få lastet inn en app på
                                        sin mobil, nettbrett eller Chromebook og
                                        lærer/gruppeleder må ha tilgang til en
                                        gyldig lisens på denne nettsiden.
                                    </TextContent>
                                    <Title3>
                                        For alt utstyr med operativsystemet
                                        Android/Chrome OS:
                                    </Title3>
                                    <TextContent>
                                        «My Child Lebensborn EDU»-appen kan
                                        lastes ned raskt og gratis fra Google
                                        Play og brukes på alle Android mobiler,
                                        -nettbrett og Chromebooks.
                                    </TextContent>

                                    <ExternalLink to="https://play.google.com/store/apps">
                                        <img
                                            style={{
                                                marginRight: "2px",
                                                maxWidth: "200px",
                                            }}
                                            src={googlePlay}
                                            alt="Google play logo"
                                        />
                                    </ExternalLink>

                                    <TextContent>
                                        Android-appen gir tilgang til spillet på
                                        enhetene så lenge det er tilknyttet en
                                        gyldig lisens.
                                    </TextContent>
                                    <TextContent>
                                        Løsningen er dessverre ikke tilgjengelig
                                        for iOS-basert utstyr som iPhone eller
                                        iPad. Det var ikke mulig å få godkjent
                                        en tilsvarende løsning på AppStore som
                                        på Google Play. Hvis Apple skulle endre
                                        sin policy vil vi gjøre det vi kan for
                                        å tilrettelegge for dette.
                                    </TextContent>
                                    {/* <Title3>For Apple-utstyr / iOS:</Title3>
                                    <BoldText>
                                        For iPhones og iPads eid av
                                        skoler/enheter:
                                    </BoldText>
                                    <TextContent>
                                        «My Child Lebensborn EDU» er
                                        tilgjengelig for alle iPads og iPhones
                                        som er tilknyttet{" "}
                                        <TextLink
                                            as="a"
                                            href="https://school.apple.com/"
                                        >
                                            Apple School Manager (ASM)
                                        </TextLink>
                                        .
                                    </TextContent>

                                    <ExternalLink to="https://school.apple.com/">
                                        <img
                                            style={{
                                                marginRight: "2px",
                                                maxWidth: "200px",
                                            }}
                                            src={appleSchool}
                                            alt="Apple skole"
                                        />
                                    </ExternalLink>

                                    <TextContent>
                                        Apper nedlastet fra ASM gir varig
                                        tilgang til mobilspillet «My Child
                                        Lebensborn» på skolens enheter, mens
                                        kommunikasjonsmuligheten i appen er
                                        avhengig av å være tilknyttet en gyldig
                                        lisens på denne nettsiden.
                                        App-nedlasting via ASM gir 50% rabatt
                                        på spillet ved kjøp av mer enn 20
                                        lisenser om gangen, noe som gir en
                                        pris på NOK 16,50,- per stykk. Dette
                                        kommer i tillegg til lisenskostnaden.
                                    </TextContent>
                                    <BoldText>
                                        Privat eide iPhones eller iPads:
                                    </BoldText>
                                    <TextContent>
                                        Vi har dessverre ikke klart å få en
                                        løsning med Apple som sikret at
                                        skole/enhet kunne kjøpe inn og
                                        distribuere «My Child Lebensborn EDU»
                                        til elevers/brukeres private enheter på
                                        samme enkle og raske måte som med
                                        Android. Hvis Apple skulle endre sin
                                        policy vil vi gjøre det vi kan for å
                                        tilrettelegge for dette.
                                    </TextContent>

                                    <ExternalLink to="https://www.apple.com/business/docs/site/Apple_Business_Manager_Getting_Started_Guide.pdf">
                                        <img
                                            style={{
                                                marginRight: "2px",
                                                maxWidth: "200px",
                                            }}
                                            src={appleBusiness}
                                            alt="Apple bedrift"
                                        />
                                    </ExternalLink>
                                    <TextContent>
                                        Det er mulig å benytte{" "}
                                        <TextLink
                                            as="a"
                                            href="https://www.apple.com/business/docs/site/Apple_Business_Manager_Getting_Started_Guide.pdf"
                                        >
                                            Apple Business Manager (ABM)
                                        </TextLink>{" "}
                                        for tilknytning av privat eide mobiler
                                        til løsningen, men dette krever at
                                        mobilene tilknyttes et MDM-system.
                                        Kontakt oss hvis dette er aktuelt. Les
                                        mer om ABM hos Apple.
                                    </TextContent> */}
                                    <Title3>Oppstart i klasserommet:</Title3>
                                    <TextContent>
                                        Lisens kan kjøpes på denne nettsiden med
                                        kort/faktura og gir tilgang til
                                        løsningen med en gang. Lærer/gruppeleder
                                        behøver så kun å opprette en
                                        spillergruppe for klassen og å dele ut
                                        aktiviseringskoden som tilhører
                                        lisensen. Elev/bruker åpner appen,
                                        legger inn aktiviseringskoden, velger et
                                        brukernavn og kobler seg opp til
                                        klassens spillergruppe. Derved er
                                        løsningen klar for bruk.
                                    </TextContent>
                                </ShrinkableDiv>
                            }
                            right={
                                <FlexColumn>
                                    <Quote
                                        text="Elevene fikk enkelt logget seg på og lagt inn lisensnøkkelen. Absolutt alt fungerte fra A til Å. Elevene ville ikke stoppe og den timen vi holdt på fløy avgårde."
                                        source="- Kim Nilsen Aarberg, Lærer ved Follese skole. Testet på klasse med 28 elever på Google Chrome."
                                    />
                                    <Quote
                                        text="Vi har prøvd å lage en løsning som er så enkel som mulig for lærere, og som gir nye samarbeidsmuligheter for klasserommet. Vi håper denne nye EDU-appen vil gi mange lærere en positiv opplevelse med å bruke dataspill i undervisningen."
                                        source="- Terje Pedersen, forfatter av læringsoppleggene og lærer ved Rothaugen Skole i Bergen."
                                    />
                                </FlexColumn>
                            }
                        />
                    </Section>

                    <Section>
                        <ResponsiveColumns
                            breakpoint="600px"
                            left={
                                <img
                                    className={style.columnImg}
                                    src={homeKarin}
                                    alt="Screenshot of Karin taking a bath"
                                />
                            }
                            right={
                                <ShrinkableDiv>
                                    <Subtitle>
                                        Prisvinnende spill for empati
                                    </Subtitle>
                                    <TextContent>
                                        «My Child Lebensborn» vant spillenes
                                        Oscar, BAFTA i kategorien «Game Beyond
                                        Entertainment» i 2019. Det har vunnet
                                        den norske Spillprisen 2019 Liten
                                        Skjerm, «Best Narrative» hos kinesiske
                                        TapTap og Silver Medal hos Serious Play.
                                    </TextContent>
                                    <TextContent>
                                        Spillet har blitt kalt ett av de beste
                                        spillene i verden i 2018 hos The New
                                        Yorker og Polygon.com og var «Game of
                                        the Month» i The Guardian i juni 2018.
                                        Det har så langt solgt over en million
                                        kopier verden over.
                                    </TextContent>
                                </ShrinkableDiv>
                            }
                        />
                    </Section>
                </ContentWrapper>
            </Content>

            {/* <Breakpoint tablet up> */}
            <Footer />
            {/* </Breakpoint> */}
        </Wrapper>
    )
}

export default Landing

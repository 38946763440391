import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { Clear } from '@material-ui/icons'
import { toggleFilter } from '../../redux/actions'
import { useTranslation } from 'react-i18next';
import { COLOR_GREEN } from '../../constants'

const Wrapper = styled.div`
	width: 100%;
	padding: 8px;
`

const Ul = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
`

const List = ({children}) => (
	<Ul role="listbox">
		{children}
	</Ul>
)

const CheckboxStyle = styled.div.attrs( ({selected}) => ({
	selected: selected
}))`
	/*cursor: pointer;*/
	position: relative;
	display: flex;
	align-items: center;
	*
	{
		cursor: pointer;
	}
	label
	{
		user-select: none;
		display: flex;
		align-items: center;
		/*margin: 4px;*/
	}
	span
	{
		color: ${props => props.selected ? "white" : COLOR_GREEN};
		background-color: ${props => props.selected ? COLOR_GREEN : "transparent"};
		text-decoration: underline;
		padding-top: 4px;
		padding-bottom: 4px;
		padding-right: 4px;
	}
	svg
	{
		color: ${COLOR_GREEN};
	}
	input
	{
		text-decoration: underline;
		
		/* Hide checkbox */
		position: absolute;
		opacity: 0;
		height: 0;
		width: 0;
	}
	&:after
	{
		
	}
`

const Checkbox = ({label, name, selected, onChange, children}) => (
	<CheckboxStyle selected={selected}>
		<label className={selected ? "selected" : ""}>
			<span>{label || children}</span>
			<input type="checkbox" name={name} checked={selected} onChange={onChange}/>
			{selected && <Clear />}
		</label>
	</CheckboxStyle>
)

const FilterList = ({name, items, children, toggleFilter}, props) => {
	//console.log("FilterList", name, items)
	const { t } = useTranslation()
	return (
		<Wrapper>
			<h3 style={{margin: 0, padding: 0}}>{t(`filters.${name}`)}</h3>
			<List>
				{
					Object.keys(items).map(index => {
						const { id, selected, translationKey } = items[index]
						return (
							<Checkbox
								key={index}
								name={id}
								selected={selected}
								onChange={() => toggleFilter(name, index)}
							>
								{t(`filters.${translationKey}`)}
							</Checkbox>
						)
					})
				}
			</List>
		</Wrapper>
	)
}

/*
<li
								key={index}
								role="option"
								aria-selected={item.selected ? true : false}
								onChange={() => toggleFilter(category, index)}
							>
								{item.text}
							</li>


			<Checkbox name="1" selected>1</Checkbox>
			<Checkbox name="2" >2</Checkbox>
			*/

const mapStateToProps = (state, ownProps) => {
	//console.log("mapState", state, ownProps)
	//console.log("ownProps.category", ownProps.category)
	//console.log("state.filters[ownProps.category]", state.filters[ownProps.category])
	return {
		items: state.filters[ownProps.name]
	}
}

export default connect(mapStateToProps, { toggleFilter })(FilterList)
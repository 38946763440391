import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form} from 'formik'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { fetchLicenseData, editLicenseData } from '../../../redux/actions'
import backend from '../../../apis/backend';
import { PrettyField } from '../../forms/PrettyField';
import { FixedField } from '../../forms/FixedField';
import { SquareButton } from '../../dashboard/SquareButton';
import { navigate } from '@reach/router';

const StyledForm = styled(Form)`
	/*max-width: 600px;*/
	width: 100%;
	word-break: break-word;
	/*margin: 0 8px;*/
	/*display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;*/
`

const PostalPlaceWrapper = styled.div`
	display: flex;
	flex-direction: row;
	>*:first-of-type
	{
		margin-right: 20px;
	}
`

const FlexRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`

const LicenseDataFormComponent = ({ licenseData, expiresAt }) => {
	
	const { t } = useTranslation()
	
	const { id } = licenseData
	console.log("License Data", licenseData)
	console.log("Expiry date", expiresAt)
	
	const defaultValues = {
		first_name: "",
		last_name: "",
		email: "",
		devices_used: "",
		devices_allowed: "",
		admins: "",
		expiresAt: ""
	}

	console.log("Default Values", defaultValues)
	
	const LicenseFixedField = ({name, ...rest}) => {
		return (
			<FixedField name={name} i18nPath="form.fields" {...rest} />
		)
	}

	const LicenseDataField = ({name, ...rest}) => {
		return (
			<PrettyField name={name} i18nPath="form.fields" {...rest} />
		)
	}

	let initialValues= {expiresAt, ...licenseData}
	let extantInitialValues = {}
	if (initialValues) {
		Object.keys(initialValues).forEach(key => {
			const value = initialValues[key]
			if (value) extantInitialValues = { ...extantInitialValues, [key]: value}
		});
	}
	
	return (
		<Formik
			enableReinitialize
			initialValues={{
				...defaultValues,
				...extantInitialValues,
			}}
			onSubmit={(values, actions) => {
				//console.log("Submitted from LicenseData")
				backend.put(`/licensestats/${id}`, {
					...values
				})
					.then( (response) => {
						console.log("Successful response", response)
						//console.log("LicenseData was updated!")
						//navigate(`./`)
						actions.resetForm(values)
					})
					.catch( (error) => {
						console.log("Failure response", error, error.response)
						actions.resetForm({...values})
						let errors = {}
						for(const i in error.response.data.errors) {
							const e = error.response.data.errors[i]
							
							if (e.code === 10000) {
								actions.setStatus(e.message)
								break
							}
							else {
								errors = {
									...errors,
									[e.field]: e.message
								}
							}
						}
						actions.setErrors(errors)
					})
			}}
			render={ props  => {
				console.log("Rendering LicenseDataForm: Props", props)
				return (
					<StyledForm>
						<LicenseFixedField
							name="first_name"
							touched={props.touched}
							errors={props.errors}
						/>
						
						<LicenseFixedField
							name="last_name"
							touched={props.touched}
							errors={props.errors}
						/>
						
						<LicenseFixedField
							name="email"
							type="email"
							touched={props.touched}
							errors={props.errors}
						/>
						
						<PostalPlaceWrapper>
							<LicenseFixedField
								name="devices_used"
								touched={props.touched}
								errors={props.errors}
							/>
							<LicenseDataField
								name="devices_allowed"
								touched={props.touched}
								errors={props.errors}
							/>
						</PostalPlaceWrapper>
						
						<LicenseFixedField
							name="admins"
							touched={props.touched}
							errors={props.errors}
						/>

						<LicenseDataField
							name="expiresAt"
							touched={props.touched}
							errors={props.errors}
						/>
						
						
						<FlexRow>
							<SquareButton type="submit" disabled={!(props.isValid && props.dirty)} >
								{t("admin.userdata.submit")}
							</SquareButton>

							<SquareButton type="reset" disabled={!(props.dirty)} onClick={props.handleReset} >{
								t("admin.userdata.reset")}
							</SquareButton>
							
						</FlexRow>
						
						
					</StyledForm>
				)
			}}
		/>
	)
}

export const LicenseDataForm = connect(null, {fetchLicenseData, editLicenseData })(
	LicenseDataFormComponent
)
import React from 'react'
import styled from 'styled-components'
import { Link as ReachLink } from '@reach/router';
import { Item, ItemWide, Fat, Right, Centered } from './common'
import { useTranslation } from 'react-i18next';
import { COLOR_GREEN } from '../../../constants';

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	background-color: white;
	border-bottom: 2px solid #E5E5E5;
	padding: 20px 26px;
	font-size: 16px;
	margin-bottom: 16px;
`

const Link = styled(ReachLink)`
	text-decoration: none;
	color: ${COLOR_GREEN};
	font-weight: 500;
	letter-spacing: 0.5px;
`

const UsersListItem = ({id, name, email}) => {
	const { t } = useTranslation()
	return (
		<Wrapper>
			<ItemWide>
				<Centered>
					<Fat>
						{name}
					</Fat>
				</Centered>
			</ItemWide>
			
			<ItemWide>
				<Centered>
					{email}
				</Centered>
			</ItemWide>
			
			<Item>
				<Link to={`./${id}`}> 
					<Fat>
						<Centered>
							{t("admin.users.list.viewdetails")}
						</Centered>
					</Fat>
				</Link>
			</Item>
		</Wrapper>
	)
}

export default UsersListItem
import React from 'react'
import { Formik } from 'formik'

import backend from '../../apis/backend'

/*
	This should take over as the main way of updating forms so that fixes propagate properly.
*/

export const FORM_ACTION_POST = "POST"
export const FORM_ACTION_PUT = "PUT"

export const ValidationForm = ({
	enableReinitialize,
	initialValues,
	path,
	operation=FORM_ACTION_POST,
	submitModifier=(values) => values,
	onSuccess,
	render,
	...rest
}) => {
	//console.log("validationform", {initialValues, enableReinitialize})
	return (
		<Formik
			enableReinitialize={enableReinitialize}
			initialValues={{
				...initialValues
			}}
			onSubmit={(values, actions) => {
				//console.log("Submitted validationForm")
				let action = backend.post
				switch(operation) {
					case FORM_ACTION_PUT:
						action = backend.put
						break
					case FORM_ACTION_POST:
						break
					default:
						throw new Error("Invalid operation provided to ValidationForm!")
				}
				action(path, {
					...submitModifier(values)
				})
					.then( (response) => {
						console.log("Successful response", response)
						onSuccess(response.data && response.data.data)
					})
					.catch( (error) => {
						console.log("Failure response", error, error.response)
						actions.resetForm({...values})
						let errors = {}
						for(const i in error.response.data.errors) {
							const e = error.response.data.errors[i]
							//console.log("Looping", e)
							
							if (e.code === 10000) {
								//console.log("Code is 10000, setting status: ", e.message)
								actions.setStatus(e.message)
								break
							}
							else {
								//console.log("Code is not 10000, setting error: e.field", e.field, "e.message", e.message)
								errors = {
									...errors,
									[e.field]: e.message
								}
							}
						}
						//actions.setErrors(error.response)
						actions.setErrors(errors)
					})
			}}
			render={ (props) => render(props) }
			{...rest}
		/>
	)
}
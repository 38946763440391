import React from 'react'


//For fun, could parse start for "http" and add "https" if there is none, thus one wouldn't need to provide it to get a valid link.
const ExternalLink = ({to, children, newTab=true}) => (
	<a href={to} target={newTab ? "_blank" : ""} rel="noopener noreferrer">
		{children}
	</a>
)

export default ExternalLink
import React from 'react'
//import styled from 'styled-components'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { fetchLicenses } from '../../../redux/actions'
import { Loading } from '../../dashboard/Loading'
import TopBar from '../../dashboard/TopBar'
import Title from '../../common/text/Title'
import Subtitle from '../../common/text/Subtitle'
import { CardTable, CardTableHeader, CardTableItem, HeadItem, Item } from '../../dashboard/CardTable'
import { TextExternalLink } from '../../dashboard/TextExternalLink'
import { Upper } from '../../common/text/Upper'

export class InvoicesComponent extends React.Component {
	
	state = {
		fetching: false
	}
	
	componentDidUpdate() {
		if (this.state.fetching) this.setState({ fetching: false })
	}
	
	componentDidMount() {
		this.props.fetchLicenses()
		this.setState({ fetching: true})
	}
	
	render() {
		const { t, license } = this.props
		//console.log({ license })
		if (!license) return <Loading />
		return (
			<div>
				<TopBar />
				<Title>{t("dashboard.invoices.title")}</Title>
				<Subtitle>{t("dashboard.licenses.single.license")}: {license.license}</Subtitle>
				
				<CardTable
					header={
						<CardTableHeader>
							<HeadItem>Dato</HeadItem>
							<HeadItem>Sum</HeadItem>
							<HeadItem>Status</HeadItem>
							<HeadItem align="right"></HeadItem>
						</CardTableHeader>
					}
					items={
						license.payments.map( ({id, date, amount, currency, status, invoice, receipt}) => {
							//console.log(receipt, invoice, receipt || invoice)
							return (
								<CardTableItem key={id}>
									<Item>{date}</Item>
									<Item><Upper>{currency}</Upper> {amount}</Item>
									<Item>
										{t(`dashboard.invoices.status.${status}`)}
									</Item>
									<Item align="right">
										{
											(receipt || invoice) && (
												<TextExternalLink
													target="_blank"
													href={`${receipt || invoice}`}
												>
													{t(`dashboard.invoices.show.${(receipt && "receipt") || (invoice && "invoice")}`)}
												</TextExternalLink>
											)
										}
										
									</Item>
								</CardTableItem>
							)
						})
					}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	const license = state.licenses[ownProps.id]
	return {
		license
	}
}

export const Invoices = connect(mapStateToProps, { fetchLicenses })(
	withTranslation()(InvoicesComponent)
)
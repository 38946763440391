import styled from 'styled-components'
import { COLOR_GREEN } from '../../../constants'
//import { Link } from '@reach/router'

const Text = styled.p`
	font-size: ${props => props.size || "16px"};
	margin: 0;
	margin-bottom: ${props => props.size || "16px"};
	text-align: ${props => props.align};
	a
	{
		color: ${COLOR_GREEN};
		text-decoration: underline;
	}
`

export const Tip = styled(Text).attrs({align: "center"})`
	font-style: italic;
`
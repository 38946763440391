import React from 'react'
import styled from 'styled-components'
import { Button } from './Button';

export const List = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 24px 32px;
	margin-bottom: 24px;
	@media screen and (min-width: 800px)
	{
		grid-gap: 48px 72px;
		margin-bottom: 48px;
	}
`

export const ListSelection = ({list, onClick, selected}) => (
	<List>
		{list.map( (item) => {
			//console.log(supergroup)
			//console.log(item.id, selected)
			return (
				<Button key={item.id} onClick={() => onClick(item)} selected={item.id === selected}>
					{item.name}
				</Button>
			)
		})}
	</List>
)
import React from 'react'
import { connect } from 'react-redux'

import Menu from './Menu'
import { Main } from '../common/Main'
import { Redirect, Link } from '@reach/router'

//import logo from "../../img/Logo.png"
import { Content } from '../common/Content'
import { LiveHelp, Book, Bookmarks, Person, People, CardMembership } from '@material-ui/icons'
//import MenuText from '../menu/MenuText'
import { useTranslation } from 'react-i18next'
//import i18next from 'i18next'
import { PATH_DASHBOARD, PATH_ADMIN, PATH_USERS, PATH_CUSTOMERS, PATH_LICENSES_STATS, PATH_ARTICLES, PATH_GUIDE_ADMIN_NO, PATH_GUIDE_ADMIN_EN, PATH_RESOURCES_ADMIN_EN, PATH_RESOURCES_ADMIN_NO, PATH_SPECIAL_ARTICLES } from '../../constants'

//import logo from "../../img/logo-edu-1x.png"

import { Logo } from '../menu/Logo'
import { MenuLink } from '../menu/MenuLink';
//import FlexRow from '../common/flex/FlexRow'

const AdminComponent = ({ children, isAdmin }) => {
	const { t, i18n } = useTranslation()
	
	//console.log("Admin", {isAdmin, isAdmin})
	if (!isAdmin) return <Redirect to={PATH_DASHBOARD} noThrow />
	
	return (
		<Content>
			<Menu>
				<Link to={PATH_ADMIN} style={{display: "flex", justifyContent: "center"}}>
					<Logo />
				</Link>

				<MenuLink
					to={`.${PATH_USERS}`}
					icon={<Person />}
					text={t("admin.menu.users")}
				/>

				<MenuLink
					to={`.${PATH_CUSTOMERS}`}
					icon={<People />}
					text={t("admin.menu.customers")}
				/>

				<MenuLink
					to={`.${PATH_LICENSES_STATS}`}
					icon={<CardMembership />}
					text={t("admin.menu.licenses")}
				/>
				
				<MenuLink
					to={PATH_ADMIN}
					icon={<Book />}
					text={t("admin.menu.articles")}
				/>	
				
				<MenuLink
					to={`.${PATH_SPECIAL_ARTICLES}`}
					icon={<Bookmarks />}
					text={t("admin.menu.pages")}
				/>
				
				<MenuLink
					to={ `.${i18n.language === "no" ? PATH_GUIDE_ADMIN_NO : PATH_GUIDE_ADMIN_EN}` }
					icon={<LiveHelp />}
					text={t("admin.menu.guide")}
				/>
				
				<MenuLink
					to={ `.${i18n.language === "no" ? PATH_RESOURCES_ADMIN_NO : PATH_RESOURCES_ADMIN_EN}` }
					icon={<LiveHelp />}
					text={t("admin.menu.resources")}
				/>
				
				{/* <Link to={PATH_DASHBOARD}>
					Go to dashboard
				</Link> */}
			</Menu>
			
			<Main>
				{children}
			</Main>
		</Content>
	)
}

const mapStateToProps = state => {
	return {
		isAdmin: state.auth.isAdmin
	}
}

export const Admin = connect(mapStateToProps)(AdminComponent)
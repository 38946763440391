import styled from 'styled-components'

import { PopInEditor } from './forms/PopInEditor'
import { COLOR_GREEN } from '../../constants'

export const InlinePopInEditor = styled(PopInEditor)`
	width: 100%;
	display: flex;
	form
	{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
	}
	.label-and-input
	{
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-right: 8px;
		width: 100%;
	}
	label
	{
		display: none;
	}
	input[type=text]
	{
		background: transparent;
		border: none;
		font-size: 16px;
		letter-spacing: 0.15px;
		width: 100%;
		border-bottom: 1px solid #9E9E9E;
	}
	button[type=submit]
	{
		background: ${COLOR_GREEN};
		box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
		border: none;
		border-radius: 4px;
		padding: 4px 6px;
		
		color: white;
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 1.25px;
		text-transform: uppercase;
		cursor: pointer;
	}
`
import React from 'react'
import { Link } from '@reach/router'
import { COLOR_GREEN } from '../../../constants'

const CardButton = ({ text, to, children, style, ...rest}) => (
	<Link
		to={to}
		style={{
			display: "block",
			width: "100%",
			padding: "10px 16px",
			textAlign: "center",
			textDecoration: "none",
			color: "white",
			fontStyle: "normal",
			fontWeight: "500",
			fontSize: "14px",
			letterSpacing: "1.25px",
			textTransform: "uppercase",
			backgroundColor: COLOR_GREEN,
			boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)",
			borderRadius: "4px",
			...style
		}}
		{...rest}
	>
		{text ? text : children}
	</Link>
)

export default CardButton
import React from 'react'
//import styled from 'styled-components'
import { connect } from 'react-redux'

import checkOff from '../../img/icons/comment/check_off.svg'
import checkOn from '../../img/icons/comment/check_on.svg'
import switchOff from '../../img/icons/comment/switch_off.svg'
import switchOn from '../../img/icons/comment/switch_on.svg'
import { Switch } from './Switch';
import { editComment } from '../../redux/actions'

import { Wrapper, BoldText, UsernameItem, CheckboxItem, CommentItem, SwitchItem } from './common'
//import { useTranslation } from 'react-i18next';

const CheckBox = ({onClick, checked}) => (
	<Switch
		checked={checked}
		imgOn={checkOn}
		imgOff={checkOff}
		onClick={onClick}
	/>
)

const ImageSwitch = ({onClick, checked}) => (
	<Switch
		checked={checked}
		imgOn={switchOn}
		imgOff={switchOff}
		onClick={onClick}
	/>
)

/*
const mapStateToProps = (state, ownProps) => {
	console.log("mapState: ", state.comments[ownProps.children])
	return {
		...state.comments[ownProps.children]
	}
}*/

const CommentComponent = ({children, editComment}) => {
	const { id, createdBy, message, hidden, anonymous } = children
	return (
		<Wrapper>
			<UsernameItem>
				<BoldText>
					{createdBy}
				</BoldText>
			</UsernameItem>
			<CommentItem>{message}</CommentItem>
			{ /*<Item width="10%">{hidden ? <CheckOn /> : <CheckOff /> }</Item>*/ }
			<CheckboxItem>
				<CheckBox
					checked={hidden}
					onClick={() => {
						//console.log(`Clicked on hidden checkbox`)
						editComment(id, {
							hidden: !hidden
						})
					}}/>
			</CheckboxItem>
			<SwitchItem>
				<ImageSwitch
					checked={anonymous}
					onClick={() => {
						//console.log(`Clicked on anonymous switch`)
						editComment(id, {
							anonymous: !anonymous
						})
					}}
				/>
			</SwitchItem>
			{ /*<Item width="10%">{anonymous ? <SwitchOn /> : <SwitchOff /> }</Item>*/ }
		</Wrapper>
	)
}
export const Comment = connect(null, { editComment })(CommentComponent)
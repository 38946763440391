import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import LicenseListItem from './LicenseListItem'
import { Item, ItemWide, Centered, Right } from './common'

const LicenseListStyle = styled.div`

`

const LicenseHeader = styled.div`
	display: flex;
	padding: 20px 26px;
	font-weight: 500;
	font-size: 16px;
	text-transform: uppercase;
	color: #828282;
`

const LicenseList = ({children}) => {
	//console.log("LicenseList:",children)
	const { t } = useTranslation()
	return (
		<LicenseListStyle>
			<LicenseHeader>
				<ItemWide>
					{t("dashboard.licenses.list.owner")}
				</ItemWide>
				
				<Item>
					<Centered>
						{t("dashboard.licenses.list.current-users")}
					</Centered>
				</Item>
				
				<Item>
					<Centered>
						{t("dashboard.licenses.list.max-users")}
					</Centered>
				</Item>
				
				<Item>
					<Right>
						{t("dashboard.licenses.list.license")}
					</Right>
				</Item>
			</LicenseHeader>
			{children.map(
				({id, owner, devicesAllowed, devicesUsed}) => (
					<LicenseListItem
						key={id}
						id={id}
						owner={owner}
						amount={devicesAllowed}
						users={devicesUsed}
					/>
				)
			)}
		</LicenseListStyle>
	)
}

export default LicenseList
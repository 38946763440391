import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

import { setChosenChapter } from '../../../redux/actions'
import { KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';

//const getChapterIndex = chapterNumber => chapterNumber - 1

const Button = styled.button`
	border: none;
	text-decoration: ${props => !props.lineless && props.disabled && "underline"};
	/*border-radius: 50%;*/
	background: transparent;
	font-size: 20px;
	font-weight: bold;
	padding: 8px;
	cursor: pointer;
	display: flex;
	align-items: center;
`

class ChapterSelectComponent extends React.Component {
	
	componentDidMount() {
		//fetch group
		//const { fetchGroup, groupId } = this.props
		//fetchGroup(groupId)
	}
	
	renderChapterButton = (number) => (
		<Button
			aria-label={`Gå til kapittel ${number}`}
			disabled={number.toString() === this.props.selectedChapter.toString()}
			onClick={ () => {
				this.props.setChosenChapter(
					number
				)
			}}
		>
			{number}
		</Button>
	)
	
	render() {
		const { chapters, selectedChapter, setChosenChapter, t } = this.props
		const isFirstChapter = selectedChapter.toString() === chapters[0].toString()//selectedChapter === 1
		const isLastChapter = selectedChapter.toString() === chapters[chapters.length - 1].toString()//
		return (
			<>
				<Button
					lineless
					disabled={isFirstChapter}
					onClick={() => {
						setChosenChapter(
							chapters[
								chapters.indexOf(
									parseInt(selectedChapter)
								) - 1
							]
						)
					}}
				>
					<KeyboardArrowLeft /> {t("dashboard.feedback.topbar.previous-chapter")}
				</Button>
				{
					chapters.map( (chapter) => {
						//console.log(chapter)
						return (
							<div key={chapter}>
								{this.renderChapterButton(chapter)}
							</div>
						)
					})
				}
				
				<Button
					lineless
					disabled={isLastChapter}
					onClick={() => {
						const chapterIndex = chapters.indexOf(parseInt(selectedChapter))
						//console.log(chapterIndex, chapters, selectedChapter)
						const newChapter = chapters[chapterIndex + 1]
						//console.log("newChapter", newChapter)
						setChosenChapter(newChapter)
						//setChosenChapter(chapters[getChapterIndex(selectedChapter) + 1].id)}
					}}
				>
					{t("dashboard.feedback.topbar.next-chapter")} <KeyboardArrowRight />
				</Button>
			</>
		)
	}
}

const mapStateToProps = state => {
	const group = state.groups[state.selection.group]
	/*const chapters = group.chapters.map( (chapterId) => {
		return state.chapters[chapterId]
	})*/
	//const chapter = state.chapters[state.selection.chapter]
	//const chapterNumber = group.chapters[state.selection.chapter] + 1
	//console.log("group", group)
	
	return {
		chapters: group.chapters,
		selectedChapter: state.selection.chapter
	}
}

export const ChapterSelect = connect(mapStateToProps, { setChosenChapter })(
	withTranslation()(ChapterSelectComponent)
)
import React from 'react'
//import TopBar from '../../dashboard/TopBar';
//import { Statistics } from './Statistics';
//import { Comments } from '../../comments/Comments';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
//import styled from 'styled-components'
//import { ChapterSelect } from './ChapterSelect';
import { fetchLocalSelection } from '../../../redux/actions'
import { FeedbackContent } from './FeedbackContent';
import { MustChooseGroup } from '../../dashboard/MustChooseGroup';
import { Loading } from '../../dashboard/Loading';

/*
const Title = styled.h1`
	font-size: 43px;
	font-weight: bold;
	letter-spacing: 0.5px;
	text-align: center;
	margin-top: 8px;
	margin-bottom: 8px;
`

const Subtitle = styled.h1`
	font-weight: 300;
	font-size: 24px;
	letter-spacing: 0.5px;
	text-align: center;
	margin-top: 0;
	margin-bottom: 8px;
`*/

class FeedbackComponent extends React.Component {
	
	componentDidMount() {
		//fetch comments
		const { groupId, fetchLocalSelection } = this.props
		!groupId && fetchLocalSelection()
	}
	
	render() {
		const { groupId } = this.props
		//console.log(groupId)
		if (groupId === undefined) return <Loading />
		if (groupId === null) return <MustChooseGroup />
		return <FeedbackContent groupId={groupId}/>
		
	}
}

const mapStateToProps = state => {
	return {
		groupId: state.selection.group
		//selection: Object.keys(state.selection).length > 0,
	}
}

export const Feedback = connect(mapStateToProps, { fetchLocalSelection })(
	withTranslation()(FeedbackComponent)
)
import React from 'react'
import styled from 'styled-components'

import { Fullscreen } from "./Fullscreen";
import { Statistics } from "./Statistics";

const CenterFullscreen = styled(Fullscreen)`
	justify-content: center;
	align-items: center;
`

export const StandaloneStatistics = ({ statistics }) => (
	<CenterFullscreen>
		{
			//console.log({statistics})
		}
		<Statistics>
			{statistics}
		</Statistics>
	</CenterFullscreen>
)

//if this works without issues: lol
import React from 'react'
//import { connect } from 'react-redux'
import { navigate } from '@reach/router'
import { Form } from 'formik'
import { withTranslation } from 'react-i18next'
//import styled from 'styled-components'

import LoginBackground from '../landing/LoginBackground'
import { ValidationForm } from '../forms/ValidationForm'
import { SquareButton } from '../dashboard/SquareButton'
import { PATH_LOGIN } from '../../constants'
import { LoginField } from '../landing/LoginField'
import FlexRow from '../common/flex/FlexRow'
import backend from '../../apis/backend'
import { Loading } from '../dashboard/Loading'
import { Title } from '../common/text/Title'

//export const ForgotPassword = ({ token, touched, errors }) => {
class ForgotPasswordComponent extends React.Component {
	state = {
		fetchSuccess: undefined,
		fetchError: undefined
	}
	
	async componentDidMount() {
		try {
			const { token } = this.props
			await backend.get(`/reset-password/${token}`)
			this.setState({fetchSuccess: true})
		}
		catch(error) {
			this.setState({
				fetchSuccess: false,
				fetchError: error.response.data.errors[0].message
			})
			console.error(error)
		}
	}
	
	render() {
		console.log({fetchSuccess: this.state.fetchSuccess, fetchError: this.state.fetchError})
		if (this.state.fetchSuccess === undefined) return <Loading />
		if (this.state.fetchError) return (
			<LoginBackground>
				<Title color="white" shadow="0 0 2px black">{this.state.fetchError}</Title>
			</LoginBackground>
		)
		const { t, token} = this.props
		return (
			<LoginBackground>
				<ValidationForm
					initialValues={{ password: "" }}
					path={`/reset-password/${token}`}
					onSuccess={() => navigate(PATH_LOGIN) }
					render={ ({ errors, touched }) => (
						<Form>
							<LoginField
								i18nPath="login"
								name="password"
								errors={errors}
								touched={touched}
								type="password"
							/>
							<FlexRow>
								<SquareButton type="submit">{t("forgot-password.submit")}</SquareButton>
							</FlexRow>
						</Form>
					)}
				/>
			</LoginBackground>
		)
	}
}

export const ForgotPassword = withTranslation()(ForgotPasswordComponent)
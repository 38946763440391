import React from 'react'
import styled from 'styled-components'
//import { useTranslation } from 'react-i18next';
//import { ShoppingCart } from '@material-ui/icons';
import { Link } from '@reach/router';


/* Size is divided by four to arrive at border width! */
const StyledLink= styled(Link)`
	/*border: ${props => props.size/4}px;*/
	width: 250px;
	height: 80px;
    border: 2px solid #444444;
	border-width: 3px;
	border-radius: 18px;
    margin-right: calc(8px * 2);
	margin-top: calc(8px * 2);
	padding: 0;
	
    cursor: pointer;
    justify-content: flex-start;
	box-shadow: 0px 2.23587px 2.23587px rgba(0, 0, 0, 0.25);
`

const Inner = styled.div`
	/*border: ${props => props.size/4}px;*/
    border-width: 4px;
	border-radius: 15px;
	height: 100%;
    width: 100%;
    display: flex;
	flex-direction: row;
	justify-content: flex-start;
    align-items: center;
	border-style: solid;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%), #E4CEE0;
    border-color: #BEABC3;
`

const Text = styled.p`
	color: black;
	font-size: 24px;
	font-weight: bold;
	display: flex;
	align-items: flex-start;
	text-align: center;
	margin: 0;
	width: 100%;
`

export const Base = ({size=16, text, icon: Icon, to, ...rest}) => {
	return (
		<StyledLink to={to}>
			<Inner size={size} {...rest}>
				<Icon style={{
					margin: "4px",
					height: "100%",
					width: "auto",
					color: "black"
				}}/>
				<Text>{text}</Text>
			</Inner>
		</StyledLink>
	)
}

import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import TopBar from '../dashboard/TopBar'
import { fetchArticle, setFavourite, deleteFavourite } from '../../redux/actions/'
import { FavoriteBorder, Favorite } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'
import { Loading } from '../dashboard/Loading'
import Title from '../common/text/Title'
import { ArticleStyle } from '../articles/ArticleStyle'
import { TopBarButton } from '../dashboard/TopBarButton'
import backend from '../../apis/backend'

/*
const Button = styled.button`
	border: none;
	background-color: transparent;
	padding: 0;
	display: flex;
	align-items: center;
	font-size: 20px;
	font-weight: bold;
	letter-spacing: 0.05px;
	transition: opacity 0.05s;
	&:active
	{
		top: 1px;
		opacity: 0.7;
	}
	svg
	{
		margin-right: 8px;
	}
`*/

const RedTopBarButton = styled(TopBarButton)`
	color: #bf322c;
`

/*
const FavouriteButton = ({isFavourite, onClick, children}) => {
	console.log(isFavourite, onClick, children)
	if (isFavourite) {
		return <><Favorite style={{color: "#bf322c"}}/></>
	}
	return (
		<Button onClick={onClick}><FavoriteBorder />
			{children}
		</Button>
	)
}*/

class Article extends React.Component{
	
	state = {
		currentId: undefined
	}
	
	getArticle = () => {
		const { fetchArticle, id } = this.props
		if (id !== this.state.currentId)
		{
			fetchArticle(id)
			this.setState({currentId: id})
		}
	}
	
	componentDidUpdate() {
		this.getArticle()
	}
	
	componentDidMount() {
		this.getArticle()
	}
	
	embedYoutube = (url) => {
		return `<div class="responsive-video youtube"><iframe src="${url}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`
	}
	
	embedVimeo = (url) => {
		return `<div class="responsive-video vimeo"><iframe src="${url}" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div>`
	}
	
	chooseEmbedSource = (url) => {
		if (url.search(/youtube.com/) >= 0) {
			console.log("Found youtube!", {url})
			return this.embedYoutube(url)
		} else if (url.search(/vimeo.com/) >= 0) {
			console.log("Found vimeo!", {url})
			return this.embedVimeo(url)
		} else {
			console.log("Did not find embed source!", {url})
			backend.post("/embedmissing", { url })
			return ""
		}
	}
	
	/* Replace an oembed element with what each service needs. */
	findAndReplaceEmbeds = (data) => {
		//return data.replace('<oembed\s+.*?\s+src=(".*?").*?<\/oembed>', () => )
		console.log({data})
		console.log("Oembeds:", data.search(/<oembed.*?url=(".*?").*?>/g))
		return data.replace(/<oembed\s+?.*?url="(.+?)"\s*?.*?>\s*?<\/oembed>/g, (match, p1, offset, string) => {
			console.log({match, p1, offset, string})
			//return `<h1>${p1}</h1>`
			return this.chooseEmbedSource(p1)
			//return `<iframe class="responsive-video vimeo" src="${p1}" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`
		})
	}
	
	renderFavouriteButton = () => {
		const { id, isFavourite, setFavourite, t, article, deleteFavourite } = this.props
		if (isFavourite) {
			return (
				<RedTopBarButton onClick={() => deleteFavourite(id)}>
					<Favorite />
					{t("dashboard.articles.article.remove-favourite")}
				</RedTopBarButton>
			)
		}
		return (
			<TopBarButton onClick={() => setFavourite(id, article.title )}>
				<FavoriteBorder />
				{t('dashboard.articles.article.add-favourite')}
			</TopBarButton>
		)
	}
	
	render() {
		const { article, backPath } = this.props
		//console.log(props)
		//console.log(id, title, content, image, imageAlt, setFavourite)
		if (!article) {
			return <Loading />
		}
		let replacedData = ""
		replacedData = article.data && this.findAndReplaceEmbeds(article.data)
		console.log({ replacedData })
		return (
			<div>
				<TopBar
					//onClickPrint={() => window.print()}
					//onClickFullscreen={() => console.log("fullscreen!")}
					print
					//fullscreen
					backPath={backPath}
				>
					{
						this.renderFavouriteButton()
					}
				</TopBar>
				<ArticleStyle>
					<Title>{article.title}</Title>
					{
						article.data && (
							<div dangerouslySetInnerHTML={{ __html: this.findAndReplaceEmbeds(article.data)}} />
						)
					}
				</ArticleStyle>
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	const id = parseInt(ownProps.id)
	const article = state.articles.find( (article) => {
		return article.id === id
	})
	const isFavourite = state.favourites.find( ({ articleID }) => {
		return id === articleID
	})
	return {
		article,
		isFavourite: isFavourite ? true : false
	}
}

export default connect(mapStateToProps, { fetchArticle, setFavourite, deleteFavourite })(
	withTranslation()(Article)
)
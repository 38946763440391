import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'
import { useTranslation } from 'react-i18next'

import TopBar from '../../dashboard/TopBar'
import ArticleFilterButton from '../../articles/ArticleFilterButton'
import { ArticleList } from '../../articles/ArticleList'
import ArticleFilters from '../../articles/ArticleFilters'
import { PATH_RESOURCES } from '../../../constants'
//import { TopBarLink } from '../../dashboard/TopBarLink'

import Title from '../../common/text/Title'
import { Tip } from '../../common/text/Tip'

const Wrapper = styled.div`
`

const FatLink = styled(Link)`
	font-weight: bold;
`


export const Articles = () => {
	const { t } = useTranslation()
	return (
		<Wrapper>
			<TopBar>
				{/*<TopBarLink to={`..${PATH_RESOURCES}`}>
					{t("dashboard.articles.resources")}
				</TopBarLink>*/}
				<ArticleFilterButton />
			</TopBar>
			
			<Title>{t("dashboard.articles.title")}</Title>
			
			<Tip>
				{t("dashboard.articles.filter.tip")}
				<FatLink to={`..${PATH_RESOURCES}`}>
					{t("dashboard.articles.filter.tip-end")}
				</FatLink>
			</Tip>
			
			<ArticleFilters />
			
			<ArticleList />
		</Wrapper>
	)
}
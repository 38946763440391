import { arrayIncludesAll } from './arrayIncludesAll'

/*export const itemsAreInState = (parent, stateChildren, childrenKey) => {
	return parent &&
		stateChildren.length > 0 &&
		arrayIncludesAll(
			stateChildren,
			parent[childrenKey].map( itemId => (
				itemId.toString()
			))
		)
}*/


export const itemsAreInState = (state, itemKeys, stateKey) => {
	/*console.log("ItemsAreInState", {
		state, itemKeys, stateKey
	})*/
	const stateItems = Object.keys(state[stateKey])
	const stateNotEmpty = stateItems.length > 0
	//console.log("stateNotEmpty", stateNotEmpty, "stateItems", stateItems)
	
	//cast all values to strings
	const stringItemKeys = itemKeys.map( item => item.toString())
	const stringStateItems = stateItems.map( item => item.toString())
	//console.log("itemsAreInState", stringItemKeys, stringStateItems)
	
	return stateNotEmpty &&
		arrayIncludesAll(
			stringStateItems,
			stringItemKeys
		)
}
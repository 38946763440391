import { FETCH_BUNDLES } from "../actions/types";

const defaultState = {}

export default (state = {
	...defaultState
}, action) => {
	switch (action.type) {
		case FETCH_BUNDLES:
			return {
				...action.payload
			}
		default:
			return state;
	}
}
import React from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import styled from "styled-components"

import { Header } from "./Header"
import Title from "../../common/text/Title"
import Subtitle from "../../common/text/Subtitle"
import { Tip } from "../../common/text/Tip"
import { OrderForm } from "../../ordering/OrderForm"
import backend from "../../../apis/backend"
import { LandingBackground } from "../Landing/LandingBackground"
import { PATH_REGISTER, PATH_ORDER } from "../../../constants"
import { TextLink } from "../../dashboard/TextLink"
//import { Content } from '../../common/Content'
//import { MaxWidth } from '../../common/MaxWidth'
//import { SwitchForm } from './Switch'

export const Wrapper = styled.div`
    max-width: 1200px;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    margin: 0;
    @media screen and (min-width: 800px) {
        width: 90%;
        padding: 0;
        margin-left: auto;
        margin-right: auto;
    }
`

const Text = styled.p`
    font-size: 14px;
    letter-spacing: 0.1px;
    margin: 4px;
`

class OrderComponent extends React.Component {
    state = {
        currency: "nok",
        bundles: undefined,
    }

    componentDidMount = async () => {
        //const { id } = this.props
        //const response = await backend.get(`/licenses/${id}/upgrade/bundles`)
        try {
            const response = await backend.get(`/licenses/bundles`)
            //console.log("Response", response)
            this.setState({ bundles: [...Object.values(response.data.data)] })
        } catch (error) {
            console.error("Could not retrieve bundles: ", error)
        }
    }

    render() {
        const { bundles } = this.state
        const { location, t } = this.props
        return (
            <LandingBackground>
                <Header
                    onClickCurrency={() =>
                        this.setState({
                            currency:
                                this.state.currency === "nok" ? "usd" : "nok",
                        })
                    }
                    currency={this.state.currency}
                />
                <Wrapper>
                    <Title>{t("ordering.title")}</Title>
                    <Subtitle>{t("ordering.subtitle")}</Subtitle>
                    <Tip>
                        Pakkene gir tilgang til undervisningsmateriell og
                        klasseromsløsning med kommunikasjon mellom apper og
                        web/storskjerm for oppgitt antall deltagere og
                        gruppeledere/lærere i ett kalenderår fra kjøp. Fritt
                        antall spillergrupper eller skoler/enheter kan
                        opprettes.
                    </Tip>
                    <Tip>
                        Nedlasting av app for Android/Chrome OS er gratis.
                        Løsningen er dessverre ikke tilgjengelig for iOS.
                    </Tip>
                    {/* <Tip>
                        Nedlasting av app for Android er gratis. For
                        iOS via Apple School Manager tilkommer NOK 16,50,-
                        per nedlasting ved kjøp av 20+ lisenser. iOS-app
                        gir i tillegg permanent tilgang til selve spillet
                        ut over lisensens varighet (50% rabatt på fullpris
                        i AppStore).
                    </Tip> */}
                    <Tip>
                        For spesiallisenser, kontakt{" "}
                        <TextLink as="a" href="mailto:support@teknopilot.no">
                            support@teknopilot.no
                        </TextLink>
                    </Tip>
                    {/*<Tip>{t("ordering.tip")}</Tip>*/}
                    {
                        // bundles && bundles.length && (
                        true && (
                            <OrderForm
                                currency={this.state.currency}
                                bundles={bundles}
                                pathInvoice="/order/invoice"
                                pathCard="/checkout"
                                successURL={`${location.origin}${PATH_REGISTER}`}
                                errorURL={`${location.origin}${PATH_ORDER}`}
                                isCompany={this.state.isCompany}
                            />
                        )
                    }
                </Wrapper>
            </LandingBackground>
        )
    }
}

/*
	<FlexColumn>
		
		<span>Order</span>
		
		<Link to="/dashboard">
			Go to dashboard
		</Link>
	</FlexColumn>
*/

const mapStateToProps = (state) => {
    const bundles =
        state.bundles && Object.keys(state.bundles).length
            ? Object.values(state.bundles)
            : []
    return {
        bundles,
    }
}

export const Order = connect(
    mapStateToProps,
    {}
)(withTranslation()(OrderComponent))

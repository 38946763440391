import { combineReducers } from 'redux'

import filterViewReducer from './filterViewReducer'
import filterReducer from './filterReducer'
import articleReducer from './articleReducer'
import licenseReducer from './licenseReducer'
import selectionReducer from './selectionReducer'
import favouritesReducer from './favouritesReducer'
import supergroupReducer from './supergroupReducer'
import groupReducer from './groupReducer'
import chapterReducer from './chapterReducer'
import commentReducer from './commentReducer'
import questionReducer from './questionReducer'
import authReducer from './authReducer'
import profileReducer from './profileReducer'
import bundleReducer from './bundleReducer';
import userReducer from './userReducer';
import userDataReducer from './userDataReducer';
import customerReducer from './customerReducer';
import licenseStatReducer from './licenseStatReducer';
import licenseDataReducer from './licenseDataReducer';

export default combineReducers({
	showFilterView: filterViewReducer,
	filters: filterReducer,
	articles: articleReducer,
	licenses: licenseReducer,
	supergroups: supergroupReducer,
	groups: groupReducer,
	selection: selectionReducer,
	favourites: favouritesReducer,
	chapters: chapterReducer,
	comments: commentReducer,
	questions: questionReducer,
	auth: authReducer,
	profile: profileReducer,
	bundles: bundleReducer,
	users: userReducer,
	userData: userDataReducer,
	customers: customerReducer,
	licenseStats: licenseStatReducer,
	licenseData: licenseDataReducer
});
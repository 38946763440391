import { EDIT_COMMENT, FETCH_COMMENTS } from "../actions/types";

export default (state = {
	/*
	1: {
		id: 1,
		createdBy: "Anne",
		message: "This was horrible!",
		hidden: false,
		anonymous: false,
		createdAt: "2019-12-31T08:00:00Z"
	},
	2: {
		id: 2,
		createdBy: "Steve",
		message: "Why do I have to do this?",
		hidden: false,
		anonymous: false,
		createdAt: "2019-12-31T08:00:00Z"
	},
	3: {
		id: 3,
		createdBy: "John",
		message: "Super big boobies",
		hidden: true,
		anonymous: false,
		createdAt: "2019-12-31T08:00:00Z"
	},
	4: {
		id: 4,
		createdBy: "Zack",
		message: "I hate everyone",
		hidden: false,
		anonymous: true,
		createdAt: "2019-12-31T08:00:00Z"
	},
	5: {
		id: 5,
		createdBy: "Hewey",
		message: "Dewey did it!",
		hidden: false,
		anonymous: false,
		createdAt: "2019-12-31T08:00:00Z"
	},
	6: {
		id: 6,
		createdBy: "Dewey",
		message: "No, it's true, Louie did it!",
		hidden: false,
		anonymous: false,
		createdAt: "2019-12-31T08:00:00Z"
	},
	7: {
		id: 7,
		createdBy: "Louie",
		message: "Hewey did it, I swear!",
		hidden: false,
		anonymous: false,
		createdAt: "2019-12-31T08:00:00Z"
	},
	8: {
		id: 8,
		createdBy: "Bill commenter",
		message: "One more comment for the road",
		hidden: false,
		anonymous: false,
		createdAt: "2019-12-31T08:00:00Z"
	},
	10: {
		id: 10,
		createdBy: "QuestionCommenter 1",
		message: "10",
		hidden: false,
		anonymous: false,
		createdAt: "2019-12-31T08:00:00Z"
	},
	11: {
		id: 11,
		createdBy: "QuestionCommenter 2",
		message: "11",
		hidden: false,
		anonymous: false,
		createdAt: "2019-12-31T08:00:00Z"
	},
	12: {
		id: 12,
		createdBy: "QuestionCommenter 3",
		message: "12",
		hidden: false,
		anonymous: false,
		createdAt: "2019-12-31T08:00:00Z"
	},
	13: {
		id: 13,
		createdBy: "QuestionCommenter 4",
		message: "13",
		hidden: false,
		anonymous: false,
		createdAt: "2019-12-31T08:00:00Z"
	},
	14: {
		id: 14,
		createdBy: "QuestionCommenter 5",
		message: "14",
		hidden: false,
		anonymous: false,
		createdAt: "2019-12-31T08:00:00Z"
	},
	15: {
		id: 15,
		createdBy: "QuestionCommenter 6",
		message: "15",
		hidden: false,
		anonymous: false,
		createdAt: "2019-12-31T08:00:00Z"
	},
	16: {
		id: 16,
		createdBy: "QuestionCommenter 7",
		message: "16",
		hidden: false,
		anonymous: false,
		createdAt: "2019-12-31T08:00:00Z"
	},
	17: {
		id: 17,
		createdBy: "QuestionCommenter 8",
		message: "17",
		hidden: false,
		anonymous: false,
		createdAt: "2019-12-31T08:00:00Z"
	},
	18: {
		id: 18,
		createdBy: "QuestionCommenter 9",
		message: "18",
		hidden: false,
		anonymous: false,
		createdAt: "2019-12-31T08:00:00Z"
	},*/
}, action) => {
	switch (action.type) {
		case FETCH_COMMENTS:
			return {
				...state,
				...action.payload
			}
		case EDIT_COMMENT:
			return {
				...state,
				[action.payload.id]: {
					...state[action.payload.id],
					...action.payload.data
				}
			}
		default:
			return state;
	}
}
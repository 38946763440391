import React from 'react'

const ShrinkableDiv = ({children, style, ...rest}) => (
	<div style={{
		maxWidth: "100%",
		...style
	}}
	{...rest}
	>
		{children}
	</div>
)

export default ShrinkableDiv
import styled from 'styled-components'

export const Wrapper = styled.div`
	background-color: white;
	padding: 4px 24px;
	font-size: 16px;
	display: flex;
	flex-direction: row;
	margin-bottom: 16px;
	border-bottom: 1px solid #E5E5E5;
`

export const Item = styled.div`
	width: ${props => props.width};
	display: flex;
	flex-direction: row;
	align-items: center;
`

export const UsernameItem = styled(Item)`
	width: 20%;
`

export const CommentItem = styled(Item)`
	width: 60%;
`

export const CheckboxItem = styled(Item)`
	width: 10%;
`

export const SwitchItem = styled(Item)`
	width: 10%;
`

export const BoldText = styled.p`
	font-weight: bold;
`
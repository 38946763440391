import React from 'react'
import styled from 'styled-components'

//import FlexRow from '../../common/flex/FlexRow';

import banner from '../../../img/banner.png'
import logo from '../../../img/Logo.png'
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';
//import LanguageSwitch from '../../LanguageSwitch';

const Wrapper = styled.div`
	
`

const Banner = styled.div`
	background: url(${banner}) #000000;
	background-position-x: right;
	background-repeat: no-repeat;
	background-size: contain;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	height: 150px;
	width: 100%;
	display: flex;
	align-items: center;
`

const Text = styled.p`
	text-decoration: underline;
	font-weight: bold;
	font-size: 24px;
	letter-spacing: 0.9px;
	color: black;
	margin: 0;
	cursor: pointer;
	text-transform: uppercase;
`

const TextLink = styled(Link)`
	text-decoration: underline;
	font-weight: bold;
	font-size: 24px;
	letter-spacing: 0.9px;
	color: black;
	margin: 0;
	cursor: pointer;
`

const Logo = styled.img.attrs({src: logo})`
	max-height: 100%;
	margin-left: 32px;
	
`

const Nav = styled.nav`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 32px 80px;
`

export const Header = ({onClickCurrency, currency}) => {
	const { t } = useTranslation()
	return (
		<Wrapper>
			<Banner>
				<Link to="/">
					<Logo />
				</Link>
			</Banner>
			<Nav>
				<TextLink to="/">
					{t("topbar.back")}
				</TextLink>
				<Text onClick={onClickCurrency}>
					{currency}
				</Text>
			</Nav>
		</Wrapper>
	)
}
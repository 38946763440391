import React from 'react'

import { CardBase } from './CardBase'
import CardContents from './CardContents'
import CardButton from './CardButton'

export const Card = ({title, text, buttonText, buttonTo, backgroundColor, ...rest}) => (
	<CardBase
		backgroundColor={backgroundColor}
		{...rest}
	>
		<CardContents title={title} text={text} />
		<CardButton text={buttonText} to={buttonTo} />
	</CardBase>
)
import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

//import backend from '../../apis/backend';
import { editProfile } from '../../../redux/actions'
import backend from '../../../apis/backend';
import { PrettyField } from '../../forms/PrettyField';
import { SquareButton } from '../../dashboard/SquareButton';
import { navigate } from '@reach/router';

const StyledForm = styled(Form)`
	/*max-width: 600px;*/
	width: 100%;
	word-break: break-word;
	/*margin: 0 8px;*/
	/*display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;*/
`

const PostalPlaceWrapper = styled.div`
	display: flex;
	flex-direction: row;
	>*:first-of-type
	{
		margin-right: 20px;
	}
`

const FlexRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`

const ProfileFormComponent = ({ profile, children }) => {
	//console.log("ProfileForm", profile)
	
	const { t } = useTranslation()
	
	const { isCompany, ...remainingFields } = profile
	
	const commonFields = {
		firstName: "",
		lastName: "",
		email: "",
		address: "",
		city: "",
		postalCode: "",
		country: ""
	}
	
	const companyFields = {
		companyName: "",
		orgNumber: ""
	}
	
	const extraFields = isCompany ? {
		...companyFields
	} : {}
	
	const defaultValues = {
		...commonFields,
		...extraFields
	}
	/*console.log({
		defaultValues,
		remainingFields,
		initalValues: {
			...defaultValues,
			...remainingFields
		}
	})*/
	
	/*
	const LabeledField = ({name, children, ...rest}) => {
		const fieldName = name || children
		return (
			<div>
				<label htmlFor={fieldName}>{t(`form.fields.${fieldName}`)}</label>
				<Field name={fieldName} {...rest} />
			</div>
		)
	}*/
	
	const ProfileField = ({name, ...rest}) => {
		//console.log(rest)
		return (
			<PrettyField name={name} i18nPath="form.fields" {...rest} />
		)
	}
	
	return (
		<Formik
			enableReinitialize
			initialValues={{
				...defaultValues,
				...remainingFields
			}}
			onSubmit={(values, actions) => {
				console.log("Submitted from profile!")
				backend.put("/profile", {
					...values
				})
					.then( (response) => {
						console.log("Successful response", response)
						//console.log("Profile was updated!")
						//changePassword()
						navigate("/admin")
					})
					.catch( (error) => {
						console.log("Failure response", error, error.response)
						actions.resetForm({...values})
						let errors = {}
						for(const i in error.response.data.errors) {
							const e = error.response.data.errors[i]
							//console.log("Looping", e)
							
							if (e.code === 10000) {
								//console.log("Code is 10000, setting status: ", e.message)
								actions.setStatus(e.message)
								break
							}
							else {
								//console.log("Code is not 10000, setting error: e.field", e.field, "e.message", e.message)
								errors = {
									...errors,
									[e.field]: e.message
								}
							}
						}
						//actions.setErrors(error.response)
						actions.setErrors(errors)
					})
			}}
			render={ props => {
				//console.log("Rendering profileForm: Props", props)
				return (
					<StyledForm>
						<ProfileField
							name="firstName"
							touched={props.touched}
							errors={props.errors}
						/>
						
						<ProfileField
							name="lastName"
							touched={props.touched}
							errors={props.errors}
						/>
						
						<ProfileField
							name="email"
							type="email"
							touched={props.touched}
							errors={props.errors}
						/>
						
						<ProfileField
							name="address"
							touched={props.touched}
							errors={props.errors}
						/>
						
						<PostalPlaceWrapper>
							<ProfileField
								name="postalCode"
								touched={props.touched}
								errors={props.errors}
							/>
							<ProfileField
								name="city"
								touched={props.touched}
								errors={props.errors}
							/>
						</PostalPlaceWrapper>
						
						<ProfileField
							name="country"
							touched={props.touched}
							errors={props.errors}
						/>
						
						{
							isCompany && (
								<>
									<ProfileField
										name="orgNumber"
										touched={props.touched}
										errors={props.errors}
									/>
									<ProfileField
										name="companyName"
										touched={props.touched}
										errors={props.errors}
									/>
								</>
							)
						}
						{
							/*
								<label htmlFor="name">{t("dashboard.profile.fields.name")}</label>
								<Field name="name" />
								
								<label htmlFor="email">{t("dashboard.profile.fields.email")}</label>
								<Field name="email" />
								
								<label htmlFor="address">{t("dashboard.profile.fields.address")}</label>
								<Field name="address" />
								
								<label htmlFor="city">{t("dashboard.profile.fields.city")}</label>
								<Field name="city" />
								
								<label htmlFor="postalCode">{t("dashboard.profile.fields.postalCode")}</label>
								<Field name="name" />
								
								<label htmlFor="country">{t("dashboard.profile.fields.name")}</label>
								<Field name="name" />
							*/
						}
						
						<FlexRow>
							<SquareButton type="submit">
								{t("dashboard.profile.submit")}
							</SquareButton>
							
							{ children }
						</FlexRow>
						
						
					</StyledForm>
				)
			}}
		/>
	)
}

export const ProfileForm = connect(null, { editProfile })(
	ProfileFormComponent
)
import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { fetchArticle } from '../../redux/actions'
import { GUIDE_ARTICLE_NO, GUIDE_ARTICLE_EN } from '../../constants'
import TopBar from '../dashboard/TopBar'
import { ArticleStyle } from '../articles/ArticleStyle'
import { Loading } from '../dashboard/Loading'

/*
	There should be a nice way of making this thing load the new text without showing a loading screen each time. I'm thinking shifting the some logic into the update function. It's okay if an article is not the right language, so long as it's about to change :)
*/

class GetStartedComponent extends React.Component {
	
	state = {
		//text: "",
		//language: "",
		fetching: false,
		fetchedLanguage: undefined
	}
	
	//the following fixes not rerendering the component upon opening the page for it after changing language
	/*
	fetchText() {
		//this will fetch the article text for the current language
		const { language } = this.props
		if (this.state.language !== language) {
			switch(this.props.language) {
				case "en":
					this.setState({text: "Instructions in english", language})
					break
				case "no":
					this.setState({text: "Instruksjoner på norsk", language})
					break
				default:
					this.setState({text: "", language: ""})
					break
			}
		}
	}*/
	
	getArticle() {
		const { i18n, fetchArticle } = this.props
		fetchArticle(i18n.language === "no" ? GUIDE_ARTICLE_NO : GUIDE_ARTICLE_EN)
	}
	
	componentDidUpdate() {
		const { i18n, article } = this.props
		//console.log("GetStarted.componentDidUpdate()", article, !article, article && article.language, i18n.language)
		if (!article || article.language !== i18n.language) {
			this.getArticle()
		}
	}
	
	componentDidMount() {
		this.getArticle()
		//this.setState({fetching: true})
	}
	
	render() {
		//console.log("GetStarted.render()", this.props.language)
		const { article } = this.props
		if (!article) return <Loading />
		
		return (
			<div>
				<TopBar />
				<ArticleStyle>
					<div dangerouslySetInnerHTML={{ __html: article.data}} />
				</ArticleStyle>
			</div>
		)
	}
};

const mapStateToProps = (state, ownProps) => {
	//const id = ownProps.i18n.language === "no" ? GUIDE_ARTICLE_NO : GUIDE_ARTICLE_EN
	const article = state.articles.find( article => (
		article.id.toString() === GUIDE_ARTICLE_NO.toString() ||
		article.id.toString() === GUIDE_ARTICLE_EN.toString()
	))
	return {
		article
	}
}

export const GetStarted = withTranslation()(
	connect(mapStateToProps, { fetchArticle })(GetStartedComponent)
)
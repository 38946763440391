import React from 'react'
import styled from 'styled-components'
import { MenuItem } from './MenuItem';

const OuterButton = styled.button`
	cursor: pointer;
	border: 0;
	background: transparent;
	width: 100%;
	padding: 0;
	text-align: left;
`

export const MenuButton = ({onClick, ...rest}) => (
	<OuterButton onClick={onClick}>
		<MenuItem {...rest} />
	</OuterButton>
)
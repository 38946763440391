import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

//import logo from '../../../img/Logo.png'
//import kulturradet from '../../../img/kulturradet.png'
//import sarepta from '../../../img/sarepta.png'
import teknopilot from '../../../img/teknopilot_white.png'

import ExternalLink from '../../common/ExternalLink'

import { FlexRow } from '../../common/flex/FlexRow'
import { PATH_LANDING_ABOUT } from '../../../constants'
import { TextLink } from '../../landing/TextLink'

const Wrapper = styled.div`
	background: rgba(0, 0, 0, 0.83);
	border-top: 5px solid #424242;
	
	padding: 16px;
	@media screen and (min-width: 800px)
	{
		
		padding-top: 64px;
		padding-bottom: 64px;
		padding-left: 96px;
		padding-right: 96px;
	}
	/*display: flex;
	flex-direction: row;
	justify-content: space-between;*/
`

const Text = styled.p`
	/*font-weight: bold;*/
	font-size: 16px;
	letter-spacing: 0.5px;
	color: white;
	
	text-align: center;
	@media screen and (min-width: 800px)
	{
		text-align: right;
	}
`

const TopRow = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	
	flex-direction: column;
	@media screen and (min-width: 800px)
	{
		flex-direction: row;
	}
	>*
	{
		flex-shrink: 1;
	}
`

const Image = styled.img`
	max-width: 100%;
	height: auto;
`

const Footer = () => {
	const { t } = useTranslation()
	return (
		<footer>
			<Wrapper>
				<TopRow>
					{
						/*
					<Image src={logo} alt="My child lebensborn" />
					<ExternalLink to="https://www.kulturradet.no/">
						<Image src={kulturradet} alt="Kulturrådet" />
					</ExternalLink>
						*/
					}
					{
						/*
						<ExternalLink to="https://sareptastudio.com/">
							<img src={sarepta} alt="Sarepta" />
						</ExternalLink>
						*/
					}
					<ExternalLink to="http://www.teknopilot.no/">
						<Image src={teknopilot} alt="Teknopilot" />
					</ExternalLink>
				</TopRow>
				<FlexRow>
					<Text>
						{t("landing.footer.tip")}
						<TextLink to={PATH_LANDING_ABOUT}>
							{t("landing.footer.tip-end")}
						</TextLink>
					</Text>
				</FlexRow>
			</Wrapper>
		</footer>
	)
}

export default Footer
import React from "react"
import { Field as BaseField } from "formik"
import styled from "styled-components"

const Field = styled(BaseField)``

const LabelDefault = styled.label`
    display: block;
`

const Error = styled.p`
    font-size: 14px;
    color: red;
`

export const Checkbox = ({
    label,
    value,
    name,
    labelComponent: LabelComponent,
    touched,
    errors,
    ...rest
}) => {
    const labelText = label || value
    const Label = LabelComponent || LabelDefault
    return (
        <>
            <Field name={name}>
                {({ field, form }) => {
                    const isFieldValueArray = Array.isArray(field.value);
                    return (
                        <Label>
                            <input
                                type="checkbox"
                                value={value}
                                name={name}
                                {...rest}
                                checked={isFieldValueArray ? value && field.value.includes(value) : value}
                                onChange={isFieldValueArray ? () => {
                                    // console.log({ "field.value": field.value, label, value, name, touched, errors, ...rest })
                                    if (field.value && field.value.includes(value)) {
                                        const nextValue = field.value.filter((prev) => prev !== value)
                                        form.setFieldValue(name, nextValue)
                                    } else {
                                        const nextValue = field.value.concat(value)
                                        form.setFieldValue(name, nextValue)
                                    }
                                } : () => {
                                    form.setFieldValue(name, !field.value)
                                }}
                            />
                            {labelText}
                        </Label>
                    )
                }}
            </Field>
            {errors?.[name] && touched?.[name] && (
                <Error>{errors?.[name]}</Error>
            )}
        </>
    )
}

import React from 'react'
import { connect } from 'react-redux'

import TopBarFiller from '../../dashboard/TopBarFiller';
import LicenseList from './LicenseList';
import { fetchLicenses } from '../../../redux/actions'
import { withTranslation } from 'react-i18next';
import Title from '../../common/text/Title';

class Licenses extends React.Component {
	componentDidMount() {
		this.props.fetchLicenses()
	}
	
	render() {
		const { t } = this.props
		return (
			<div>
				<TopBarFiller />
				<Title>{t("dashboard.licenses")}</Title>
				<LicenseList>
					{this.props.licenses}
				</LicenseList>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		licenses: Object.values(state.licenses)
	} //have to use find because it is an array and not an object
}

export default connect(mapStateToProps, { fetchLicenses })(
	withTranslation()(Licenses)
)
//import backend from '../apis/backend';
import { navigate } from '@reach/router'
//import { useTranslation } from 'react-i18next'

import backend from '../../apis/backend'
import setAuthToken from '../../apis/setAuthToken'
//import { EN, NO } from '../../static'
import setAcceptLanguage from '../../apis/setAcceptLanguage'

import {
	TOGGLE_FILTER_VIEW,
	TOGGLE_FILTER,
	SET_CHOSEN_GROUP,
	SET_CHOSEN_CHAPTER,
	SET_CHOSEN_COMMENT_DISPLAY,
	SELECTED_GROUP,
	SELECTED_CHAPTER,
	SELECTED_COMMENT_DISPLAY,
	SET_FAVOURITE,
	CREATE_GROUP,
	CREATE_SUPERGROUP,
	FETCH_QUESTIONS,
	EDIT_QUESTION,
	CREATE_QUESTION,
	DELETE_QUESTION,
	LOGIN,
	LOGOUT,
	FETCH_FILTERS,
	FETCH_ARTICLES,
	FETCH_ARTICLE,
	CREATE_ARTICLE,
	EDIT_ARTICLE,
	FETCH_GROUPS,
	FETCH_LICENSES,
	FETCH_COMMENTS,
	EDIT_COMMENT,
	FETCH_SUPERGROUPS,
	FETCH_LOCAL_SELECTION,
	//DELETE_QUESTION_ERROR,
	SELECTED_GROUP_NAME,
	FETCH_GROUP,
	FETCH_CHAPTER,
	FETCH_PROFILE,
	EDIT_PROFILE,
	DISPLAY_NORMAL,
	DISPLAY_BOARD,
	DISPLAY_CLOUD,
	WIPE_SELECTIONS,
	FETCH_FAVOURITES,
	FETCH_BUNDLES,
	DELETE_FAVOURITE,
	DELETE_GROUP,
	DELETE_SUPERGROUP,
	DELETE_ARTICLE,
	SELECTED_LANGUAGE,
	SET_CHOSEN_LANGUAGE,//,
	FETCH_SPECIAL_ARTICLES,
	DISPLAY_STATISTICS,
	FETCH_USERS,
	FETCH_USERDATA,
	EDIT_USERDATA,
	FETCH_CUSTOMERS,
	FETCH_LICENSESTATS,
	FETCH_LICENSEDATA,
	EDIT_LICENSEDATA
	//FETCH_LOCAL_LANGUAGE
} from './types'
import { PATH_ADMIN, PATH_DASHBOARD } from '../../constants';

/* LOGIN HANDLERS */

export const performLogin = (token, isAdmin) => async (dispatch, getState) => {
	console.log("ActionCreator: performLogin", {token, isAdmin})
	//this must be separated from simpleLogin so that we can run only simpleLogin and won't set localstorage and navigate on browser reload.
	
	dispatch(
		wipeSelections()
	)
	
	dispatch(
		loginSimple(token, isAdmin)
	)
	
	//store auth data locally
	await localStorage.setItem('TOKEN', getState().auth.token)
	await localStorage.setItem('IS_ADMIN', getState().auth.isAdmin)
	
	navigate(isAdmin ? PATH_ADMIN : PATH_DASHBOARD);
}

export const loginSimple = (token, isAdmin) => (dispatch, getState) => {
	console.log("ActionCreator: loginSimple", {token, isAdmin})
	
	setAuthToken(token)
	dispatch({
		type: LOGIN,
		payload: {
			token,
			isAdmin
		}
	})
	
}

export const logout = () => async dispatch => {
	console.log("Action: logout")
	
	try {
		await backend.get("/logout")
		//navigate('/');
	}
	catch (error) {
		console.error("Could not log out from backend!")
		console.error(error)
	}
	
	setAuthToken()
	localStorage.removeItem('TOKEN')
	localStorage.removeItem('IS_ADMIN')
	dispatch({
		type: LOGOUT
	})
}

export const loginFeide = () => async dispatch => {
	console.log("ActionCreator: loginFeide")
	try {
		const response = await backend.post("/login/feide")
		console.log(response)
		//navigate(response.data.data);
	}
	catch (error) {
		console.error("Could not log in with feide")
		console.error(error)
	}
}

/* PASSWORDS */

/*
export const requestNewPassword = () => async dispatch => {
	console.log("ActionCreator: requestNewPassword")
	
	try {
		const response = await backend.get("/request-new-password")
		
	}
	catch (error) {
		console.error("Could not log out from backend!")
		console.error(error)
	}
}*/

/* AUTH TOKEN STORAGE */

export const fetchLocalAuth = () => async (dispatch) => {
	console.log("ActionCreator: fetchLocalAuth")
	const token = localStorage.getItem('TOKEN')
	console.log("fetchLocalAuth", token)
	if (token) {
		const isAdmin = localStorage.getItem('IS_ADMIN') === "true" ? true : false
		console.log("fetchLocalAuth", isAdmin)
		dispatch(
			loginSimple(token, isAdmin)
		)
	}
	else
	{
		console.log("Attempted to fetch local auth data. Token does not exist. User is unauthorized.")
	}
}

/*
export const storeLocalAuth = () => async (dispatch, getState) => {
	console.log("Action: setToken. Not implemented!")
}*/

/* LICENSES */

export const fetchLicenses = () => async (dispatch, getState) => {
	console.log("ActionCreator: fetchLicenses")
	
	const response = await backend.get("/licenses")
	
	console.log(response)
	
	dispatch({
		type: FETCH_LICENSES,
		payload: {
			...response.data.data
		}
	})
}

/* SUPERGROUPS */

export const fetchSupergroups = () => async (dispatch, getState) => {
	console.log("ActionCreator: fetchSupergroups")
	
	const response = await backend.get("/supergroups")
	dispatch({
		type: FETCH_SUPERGROUPS,
		payload: {
			...response.data.data
		}
	})
}

export const createSupergroup = (licenseId, name) => async dispatch => {
	console.log("ActionCreator: createSupergroup", licenseId, name)
	
	//const id = supergroupLength + 1 //generate new id until I get one from backend
	const response = await backend.post(`licenses/${licenseId}/supergroups`, { name })
	console.log("Response", response)
	dispatch({
		type: CREATE_SUPERGROUP,
		payload: {
			...response.data.data
		}
	})
}

export const deleteSupergroup = (id, licenseID) => async dispatch => {
	console.log("ActionCreator: deleteSupergroup", id, licenseID)
	try {
		await backend.delete(`supergroups/${id}`)
		dispatch({
			type: DELETE_SUPERGROUP,
			payload: {
				id: parseInt(id),
				licenseID: parseInt(licenseID)
			}
		})
	}
	catch(error) {
		console.error("Failed removing supergroup:", error)
	}
}

/* GROUPS */

export const fetchGroups = (supergroupId) => async (dispatch, getState) => {
	console.log("ActionCreator: fetchGroups", supergroupId)
	
	const response = await backend.get(`/supergroups/${supergroupId}/groups`)
	console.log("response", response)
	dispatch({
		type: FETCH_GROUPS,
		payload: {
			...response.data.data
		}
	})
}

export const fetchGroup = (groupId) => async dispatch => {
	console.log("ActionCreator: fetchGroup", groupId)
	
	const response = await backend.get(`/groups/${groupId}`)
	console.log("response", response)
	dispatch({
		type: FETCH_GROUP,
		payload: {
			...response.data.data
		}
	})
}

export const createGroup = (supergroupId, name) => async dispatch => {
	console.log("ActionCreator: createGroup", supergroupId, name)
	
	//const id = groupLength + 1 //generate new id until I get one from backend
	const response = await backend.post(`supergroups/${supergroupId}/groups`, { name })
	console.log("Response:", response)
	dispatch({
		type: CREATE_GROUP,
		payload: {
			...response.data.data
		}
	})
}

export const deleteGroup = (id, supergroupID) => async dispatch => {
	console.log("ActionCreator: deleteGroup", id, supergroupID)
	try {
		await backend.delete(`groups/${id}`)
		dispatch({
			type: DELETE_GROUP,
			payload: {
				id: parseInt(id),
				supergroupID: parseInt(supergroupID)
			}
		})
	}
	catch(error) {
		console.error("Failed removing group:", error)
	}
}


/* CHAPTERS */

export const fetchChapter = (number, groupID) => async (dispatch, getState) => {
	console.log("ActionCreator: fetchChapter", number, groupID)
	
	const response = await backend.get(`/chapter/${number}`, { params: { groupID } })
	console.log("response: ", response)
	
	dispatch({
		type: FETCH_CHAPTER,
		payload: {
			...response.data.data
		}
	})
}

/* COMMENTS */

export const fetchComments = (ids) => async (dispatch, getState) => {
	console.log("ActionCreator: fetchComments", ids)
	
	const response = await backend.get("/comments", {
		params: {
			[`ids[]`]: [...ids].toString()
		}
	})
	console.log("response: ", response)
	
	dispatch({
		type: FETCH_COMMENTS,
		payload: {
			...response.data.data
		}
	})
}

export const editComment = (commentId, data) => async dispatch => {
	console.log("ActionCreator: editComment", commentId, data)
	
	try {
		await backend.put(`/comments/${commentId}`, { ...data })
		
		dispatch({
			type: EDIT_COMMENT,
			payload: {
				id: commentId,
				data
			}
		})
	}
	catch(error) {
		console.error(error)
		console.log(error.message)
	}
}

/* QUESTIONS */

export const fetchQuestions = (groupId) => async dispatch => {
	console.log("ActionCreator: fetchQuestions", groupId)
	
	const response = await backend.get(
		"/questions",
		{
			params: {
				groupID: groupId
			}
		}
	)
	dispatch({
		type: FETCH_QUESTIONS,
		payload: response.data.data
	})
}

export const createQuestion = (id, title) => async dispatch => {
	console.log("ActionCreator: createQuestion")
	const response = await backend.post("/questions", { groupID: id, title })
	//const tempId = Object.values(getState().questions).length + 1 //get a legit one from response
	dispatch({
		type: CREATE_QUESTION,
		payload: {
			...response.data.data
		}
	})
}

export const editQuestion = (id, title) => async dispatch => {
	console.log("ActionCreator: editQuestion", id, title)
	
	const response = await backend.put(`questions/${id}`, { title })
	
	dispatch({
		type: EDIT_QUESTION,
		payload: {
			...response.data.data
		}
	})
}

export const deleteQuestion = (id) => async dispatch => {
	console.log("ActionCreator: deleteQuestion")
	try {
		await backend.delete(`questions/${id}`)
		dispatch({
			type: DELETE_QUESTION,
			payload: id
		})
	}
	catch(error) {
		console.error(error)
		
		console.log(error.data)
		/*
		dispatch({
			type: DELETE_QUESTION_ERROR,
			payload: error
		})*/
	}
}

/* FAVOURITES */

export const fetchFavourites = () => async dispatch => {
	console.log("ActionCreator: fetchFavourites")
	
	try {
		const response = await backend.get("/favorites")
		dispatch({
			type: FETCH_FAVOURITES,
			payload: response.data.data
		})
	}
	catch(error) {
		console.error("Failed fetching favourites: ", error)
	}
}

export const setFavourite = (articleID, title) => async dispatch => {
	console.log("ActionCreator: setFavourite", articleID, title);
	
	try {
		await backend.post(`/articles/${articleID}/favorites`)
		dispatch({
			type: SET_FAVOURITE,
			payload: {
				articleID: parseInt(articleID),
				title
			}
		})
	}
	catch(error) {
		console.error("Failed setting favourite: ", error)
	}
};

export const deleteFavourite = (id) => async dispatch => {
	console.log("ActionCreator: deleteFavourite")
	try {
		await backend.delete(`articles/${id}/favorites`)
		dispatch({
			type: DELETE_FAVOURITE,
			payload: parseInt(id)
		})
	}
	catch(error) {
		console.error("Failed removing favourite:", error)
	}
}

export const fetchState = () => async (dispatch) => {
	console.log("Action: fetchState. Not implemented!")
}

/* LANGUAGE */

/*
export const fetchLocalLanguage = () => async dispatch => {
	console.log("ActionCreator: fetchLocalLanguage")
	
	dispatch({
		type: FETCH_LOCAL_LANGUAGE,
		payload: {
			language: await localStorage.getItem(SELECTED_LANGUAGE)
		}
	})
}*/

export const setChosenLanguage = (language) => async dispatch => {
	localStorage.setItem(SELECTED_LANGUAGE, language)
	setAcceptLanguage(language)
	dispatch({
		type: SET_CHOSEN_LANGUAGE,
		payload: language
	})
}

/* SELECTION */

export const fetchLocalSelection = () => async dispatch => {
	console.log("ActionCreator: fetchLocalSelection")
	/*
	const getItemOrUndefined = async (item) => {
		const storedItem = await localStorage.getItem(item)
		return storedItem === null ? undefined : storedItem
	}*/
	
	dispatch({
		type: FETCH_LOCAL_SELECTION,
		payload: {
			group: await localStorage.getItem(SELECTED_GROUP),
			groupName: await localStorage.getItem(SELECTED_GROUP_NAME),
			chapter: await localStorage.getItem(SELECTED_CHAPTER),
			commentDisplayMode: localStorage.getItem(SELECTED_COMMENT_DISPLAY) || DISPLAY_NORMAL //default
		}
	})
	/*
	const selected_group = await localStorage.getItem(SELECTED_GROUP)
	const selected_group_name = await localStorage.getItem(SELECTED_GROUP_NAME)
	const selected_comment_display_mode = await localStorage.getItem(SELECTED_COMMENT_DISPLAY)
	const selected_chapter = await localStorage.getItem(SELECTED_CHAPTER)
	
	dispatch({
		type: FETCH_LOCAL_SELECTION,
		payload: {
			group: selected_group !== null ? selected_group : undefined,
			groupName: selected_group_name !== null ? selected_group_name : undefined,
			chapter: selected_chapter !== null ? selected_chapter : undefined,
			commentDisplayMode: selected_comment_display_mode !== null ? selected_comment_display_mode : DISPLAY_NORMAL //default
		}
	})*/
}

export const setChosenGroup = (groupId, groupName) => async (dispatch, getState) => {
	console.log("ActionCreator: setChosenGroup", groupId)
	
	//get the current group
	const group = getState().groups[groupId]
	if (group.chapters.length <= 0) {
		throw new Error("group.chapters is empty. It should ALWAYS be 10 long!")
	}
	const chapter = group.chapters[0]
	
	localStorage.setItem(SELECTED_GROUP, groupId)
	localStorage.setItem(SELECTED_GROUP_NAME, groupName)
	localStorage.setItem(SELECTED_CHAPTER, chapter)
	
	dispatch({
		type: SET_CHOSEN_GROUP,
		payload: {
			group: groupId,
			groupName,
			chapter
		}
	})
	navigate(PATH_DASHBOARD);
};

export const setChosenChapter = (chapterNumber) => async (dispatch) => {
	console.log("Action: setChosenChapter", chapterNumber)
	
	localStorage.setItem(SELECTED_CHAPTER, chapterNumber)
	
	dispatch({
		type: SET_CHOSEN_CHAPTER,
		payload: chapterNumber
	})
}

export const setChosenCommentDisplay = (mode) => async (dispatch) => {
	console.log("ActionCreator: setChosenCommentDisplay", mode)
	
	const acceptedModes = [DISPLAY_NORMAL, DISPLAY_BOARD, DISPLAY_CLOUD, DISPLAY_STATISTICS]
	
	if (!acceptedModes.find( (item) => item === mode)) throw new Error("Tried setting an invalid mode!")
	
	localStorage.setItem(SELECTED_COMMENT_DISPLAY, mode)
	
	dispatch({
		type: SET_CHOSEN_COMMENT_DISPLAY,
		payload: mode
	})
}

export const wipeSelections = () => async dispatch => {
	console.log("ActionCreator: wipeSelections")
	
	localStorage.removeItem(SELECTED_COMMENT_DISPLAY)
	localStorage.removeItem(SELECTED_CHAPTER)
	localStorage.removeItem(SELECTED_GROUP)
	localStorage.removeItem(SELECTED_GROUP_NAME)
	
	dispatch({
		type: WIPE_SELECTIONS
	})
}

/* Filters */

export const fetchFilters = () => async (dispatch, getState) => {
	console.log("ActionCreator: fetchFilters")
	
	const response = await backend.get("/articles/filters")
	
	const responseFilters = response.data.data
	const filters = Object.keys(responseFilters).reduce( (f, key) => {
		
		const filterItems = responseFilters[key].reduce( (o, item) => {
			return {
				...o,
				[item.id]: {
					...item,
					selected: false
				}
			}
		}, {})
		
		return {
			...f,
			[key]: {
				...filterItems
			}
		}
  }, {})
	
	dispatch({
		type: FETCH_FILTERS,
		payload: {
			...filters
		}
	})
}

export const toggleFilter = (category, item) => async dispatch => {
	console.log("ActionCreator: toggleFilter", category, item)
	await dispatch({
		type: TOGGLE_FILTER,
		payload: {
			category,
			item
		}
	})
	dispatch(
		fetchArticles()
	)	
}

export const toggleFilterView = () => {
	console.log("Action: toggleFilterView");
	return {
		type: TOGGLE_FILTER_VIEW
	};
};

/* Articles */

export const fetchArticles = () => async (dispatch, getState) => {
	const { filters } = getState()
	const filterKeys = Object.keys(filters)
	console.log("Action: fetchArticles", filters)
	
	const filterAndMap = (items, callback, returnCallback) => {
		const filteredItems = [...items].filter( callback )
		return filteredItems.map(returnCallback)
	}
	
	/*
	const filterAndReduce = (items, callback, returnCallback) => {
		const filteredItems = [...items].filter( callback )
		return filteredItems.reduce(returnCallback)
	}*/
	
	/*
	const params = filterAndMap( filterKeys, (filterKey) => {
		const items = Object.values(filters[filterKey])
		console.log(`filters[${filterKey}]`, items)
		const keyItems = filterAndMap(items, (item) => (
			item.selected
		), (item => item.selected))
		console.log("keyItems", keyItems)
		return keyItems.length > 0
	}, filterKey => {
		const items = Object.values(filters[filterKey])
		console.log("second callback", filterKey)
		const keyItems = filterAndMap(items, (item) => (
			item.selected
		), (item => item.id))
		return {
			[`filters[${filterKey}]`]: [...keyItems]
		}
	})*/
	
	const filteredItems = filterKeys.filter( (filterKey) => {
		const items = Object.values(filters[filterKey])
		console.log(`filters[${filterKey}]`, items)
		const keyItems = filterAndMap(items, (item) => (
			item.selected
		), (item => item.selected))
		console.log("keyItems", keyItems)
		return keyItems.length > 0
	})
	
	const params = filteredItems.reduce( (o, filterKey) => {
		const items = Object.values(filters[filterKey])
		console.log("second callback", filterKey)
		const keyItems = filterAndMap(items, (item) => (
			item.selected
		), (item => item.id))
		/*
		return {
			...o,
			[`filters[${filterKey}]`]: [...keyItems]
		}*/
		
		return {
			...o,
			[`filter[${filterKey}]`]: [...keyItems].toString()
		}
	}, {})
	
	console.log("Params: ", params)
	
	const response = await backend.get(
		"/articles",
		{
			params
		}
	)
	
	/*
	const response = await backend.get("/articles", { params: {
		"filters[ageGroup]": filters["ageGroup"],
		"filters[topic]": filters["topic"],
		"filters[goal]": filters["goal"],
		"filters[subject]": filters["subject"]
	}})*/
	console.log("response", response)
	dispatch({
		type: FETCH_ARTICLES,
		payload: response.data.data
	})
}

/* I might want to handle this in the views themselves because something about nothing, really. Why can't I just handle it here? try-catch, on error, navigate away! All the view needs to do is display "no exist lol" if article is blank! */
export const fetchArticle = (id) => async (dispatch, getState) => {
	console.log("ActionCreator: fetchArticle", id)
	
	try {
		const response = await backend.get(`/articles/${id}`)
	
		console.log("fetchArticle: response", response)
		
		dispatch({
			type: FETCH_ARTICLE,
			payload: {
				...response.data.data
			}
		})
	}
	catch( error ) {
		navigate(PATH_ADMIN) //poof away if the fetch fails, haha!
	}
	
}

export const createArticle = (formValues) => async (dispatch, getState) => {
	console.log("Action: createArticle", formValues)
	//const response = await backend.post("/articles", { ...formValues, ...Object.values(getState().filters) })
	//console.log("createArticle: response", response)
	console.log("Will not actually handle this action in any way, though. Article will appear at next opening of article list")
	dispatch({
		type: CREATE_ARTICLE/*,
		payload: {
			...response.data.data
		}*/
	})
	navigate(PATH_ADMIN);
}

export const editArticle = (id, formValues) => async (dispatch, getState) => {
	console.log("ActionCreator: editArticle", id, formValues)
	//const response = await backend.post("/articles", { ...formValues, ...Object.values(getState().filters) })
	//console.log("createArticle: response", response)
	console.log("Will not actually handle this action in any way, though. Article will appear at next opening of article list")
	dispatch({
		type: EDIT_ARTICLE/*,
		payload: {
			...response.data.data
		}*/
	})
	navigate(PATH_ADMIN);
}

export const deleteArticle = id => async dispatch => {
	console.log("ActionCreator: deleteArticle", id)
	
	try {
		await backend.delete(`/articles/${id}`)
		dispatch({
			type: DELETE_ARTICLE,
			payload: id
		})
	}
	catch(error) {
		console.error("Could not delete article", error)
	}
}

/* SPECIAL ARTICLES */

export const fetchSpecialArticles = () => async (dispatch, getState) => {
	console.log("Action: fetchSpecialArticles")
	
	const response = await backend.get("/hiddenArticles")
	
	console.log("response", response)
	dispatch({
		type: FETCH_SPECIAL_ARTICLES,
		payload: response.data.data
	})
}

/* PROFILE */

export const fetchProfile = () => async (dispatch, getState) => {
	console.log("ActionCreator: fetchProfile")
	
	try {
		const response = await backend.get(`/profile`)
	
		console.log("response", response)
		
		dispatch({
			type: FETCH_PROFILE,
			payload: {
				...response.data.data
			}
		})
	}
	catch( error ) {
		//navigate("/admin") //poof away if the fetch fails, haha!
		console.error(error)
	}
}

export const editProfile = (formValues) => async (dispatch, getState) => {
	console.log("ActionCreator: editProfile")
	
	try {
		//const response = await backend.put(`/profile`, { ...formValues })
	
		//console.log("response", response)
		
		dispatch({
			type: EDIT_PROFILE,
			payload: {
				...formValues
				//...response.data.data
			}
		})
	}
	catch( error ) {
		//navigate("/admin") //poof away if the fetch fails, haha!
		console.error(error)
	}
}

/* BUNDLES */

export const fetchBundles = () => async (dispatch, getState) => {
	console.log("ActionCreator: fetchBundles")
	
	try {
		const response = await backend.get(`/licenses/bundles`)
	
		console.log("response", response)
		
		dispatch({
			type: FETCH_BUNDLES,
			payload: {
				...response.data.data
			}
		})
	}
	catch( error ) {
		//navigate("/admin") //poof away if the fetch fails, haha!
		console.error(error)
	}
	
}

/* USER */

export const fetchUsers = () => async (dispatch, getState) => {
	console.log("ActionCreator: fetchUsers")
	
	try {
		const response = await backend.get(`/users`)
	
		console.log("response", response)
		
		dispatch({
			type: FETCH_USERS,
			payload: {
				...response.data.data
			}
		})
	}
	catch( error ) {
		console.error(error)
	}
}

export const fetchUserData = id => async (dispatch, getState) => {
	console.log("ActionCreator: fetchUserData", id)
	
	try {
		const response = await backend.get(`/users/${id}`)
	
		console.log("response", response)
		
		dispatch({
			type: FETCH_USERDATA,
			payload: {
				...response.data.data
			}
		})
	}
	catch( error ) {
		console.error(error)
	}
}

export const editUserData = (formValues) => async (dispatch, getState) => {
	console.log("ActionCreator: editUserData")
	
	try {
		
		dispatch({
			type: EDIT_USERDATA,
			payload: {
				...formValues
			}
		})
	}
	catch( error ) {
		console.error(error)
	}
}

export const fetchCustomers = () => async (dispatch, getState) => {
	console.log("ActionCreator: fetchCustomers")
	
	try {
		const response = await backend.get(`/customers`)
	
		console.log("response", response)
		
		dispatch({
			type: FETCH_CUSTOMERS,
			payload: {
				...response.data.data
			}
		})
	}
	catch( error ) {
		console.error(error)
	}
}

/* LICENSES and STATS */

export const fetchLicenseStats = () => async (dispatch, getState) => {
	console.log("ActionCreator: fetchLicenseStats")
	
	try {
		const response = await backend.get(`/licensestats`)
	
		console.log("response", response)
		
		dispatch({
			type: FETCH_LICENSESTATS,
			payload: {
				...response.data.data
			}
		})
	}
	catch( error ) {
		console.error(error)
	}
}

export const fetchLicenseData = id => async (dispatch, getState) => {
	console.log("ActionCreator: fetchLicenseData", id)
	
	try {
		const response = await backend.get(`/licensestats/${id}`)
	
		console.log("response", response)
		
		dispatch({
			type: FETCH_LICENSEDATA,
			payload: {
				...response.data.data
			}
		})
	}
	catch( error ) {
		console.error(error)
	}
}

export const editLicenseData = (formValues) => async (dispatch, getState) => {
	console.log("ActionCreator: editLicenseData")
	
	try {
		
		dispatch({
			type: EDIT_LICENSEDATA,
			payload: {
				...formValues
			}
		})
	}
	catch( error ) {
		console.error(error)
	}
}
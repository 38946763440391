import React from 'react'
import styled from 'styled-components'

const ButtonStyle = styled.button`
	border: 1px solid #E5E5E5;
	background-color: ${props => props.selected ? "#FFF9EE" : "white"};
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	cursor: pointer;
	font-weight: 500;
	font-size: 20px;
	padding: 24px 0;
	width: 100%;
`

export const Button = ({children, onClick, selected=false}) => (
	<ButtonStyle onClick={onClick} selected={selected}>
		{children}
	</ButtonStyle>
)
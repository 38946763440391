import styled from 'styled-components'

import { PopInEditor } from './forms/PopInEditor'
import { COLOR_GREEN } from '../../constants'

export const StyledPopInEditor = styled(PopInEditor)`
	border-radius: 4px 4px 0px 0px;
	width: 100%;
	form
	{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		background:  white; /*rgba(0, 0, 0, 0.04);*/
		border-radius: 4px 4px 0px 0px;
		border-bottom: 1px solid #E5E5E5; /*#9E9E9E;*/
		padding: 16px 24px; /* 12px;*/
	}
	.label-and-input
	{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-right: 8px;
		width: 100%;
	}
	label
	{
		font-size: 12px;
		letter-spacing: 0.4px;
		color: rgba(0, 0, 0, 0.54);
	}
	input[type=text]
	{
		background: transparent;
		border: none;
		font-size: 16px;
		letter-spacing: 0.15px;
		width: 100%;
		caret-color: ${COLOR_GREEN};
		&:focus
		{
			outline: none;
		}
		border-bottom: 1px solid #E5E5E5;
	}
	button[type=submit]
	{
		color: white;
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 1.25px;
		text-transform: uppercase;
		background: ${COLOR_GREEN};
		box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
		border: none;
		border-radius: 4px;
		padding: 4px 6px;
		cursor: pointer;
	}
`
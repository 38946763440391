//import _ from 'lodash';
/*import {
	TOGGLE_FILTER
} from '../actions/types';*/

//import screen from '../../img/screenshots/Fishing-with-Karin.jpg'
import { FETCH_ARTICLES, FETCH_ARTICLE, DELETE_ARTICLE, FETCH_SPECIAL_ARTICLES } from '../actions/types';

export default (state = [
	/*
	{
		id: 1,
		title: "Why I ate the cookie",
		content: "<p>Lol</p><p>Hahaha</p><p>What is even the reason to try if it won't matter anyway? Who knows? Maybe it's all about that hot steamy hardcore?</p>",
		image: screen,
		imageAlt: "Karin ice fishing",
		components: [
			{
				type: "text",
				text: "Lallalaalala"
			},
			{
				type: "image",
				url: ""
			}
		]
	},
	{
		id: 2,
		title: "Oh shaving cream, why hath doth forsaken me?",
		content: `<h2>So this is what that shit's all about yo</h2><p>Some stuff about politics</p><p>So anyway, I met this dude while walking my dog. He was all cheese-like, dripping <span style="color: red">blood</span> and not breathing and shit. Whole things straight messed up bro.</p>`,
		image: screen,
		imageAlt: "Karin ice fishing"
	},
	*/
], action) => {
	switch (action.type) {
		case FETCH_ARTICLES:
			return [
				...action.payload
			]
		case FETCH_ARTICLE:
			//console.log("AritcleReducer", action)
			return [
				{ ...action.payload } //overwrite article list with a single record
			]
		case FETCH_SPECIAL_ARTICLES:
			return [
				...action.payload //overwrite
			]
		case DELETE_ARTICLE:
			return state.filter( article => article.id !== action.payload)
		default:
			return state;
	}
}
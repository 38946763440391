import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import { Link } from '@reach/router'
//import { PATH_VIEW } from '../../constants'
import { SquareLink } from '../dashboard/SquareLink'
import { TextLink } from '../dashboard/TextLink'
import { TextNonLink } from '../dashboard/TextNonLink'
import { DeleteButton } from '../dashboard/DeleteButton'
import { deleteArticle, fetchFilters } from '../../redux/actions'
import { COLOR_GREEN } from '../../constants'
import FlexRow from '../common/flex/FlexRow'
import { Image } from '../common/Image'
import { SquareButton } from '../dashboard/SquareButton'
import { Modal } from '../common/Modal'

//Todo: This uses dangerouslySetInnerHTML. I expect to use Draft.JS to create the data later, which saves in JSON format. I'll just have to figure out how to display it right!

const Card = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: white;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	padding: 16px;
	>*
	{
		margin-right: 16px;
		&:last-child
		{
			margin-right: 0;
		}
	}
`

const CardLink = styled(Link)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: white;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	padding: 16px;
	>*
	{
		margin-right: 16px;
		&:last-child
		{
			margin-right: 0;
		}
	}
`

const ContentWrapper = styled(FlexRow)`
	width: 100%;
	align-items: normal;
`

const TextContainer = styled.div`
	width: 100%;
`

const FlexBetween = styled(FlexRow)`
	width: 100%;
	justify-content: space-between;
`

const Underlined = styled.p`
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0.15px;
	text-decoration-line: underline;
	color: ${COLOR_GREEN};
`

const Title = styled(Underlined).attrs({as: "h4"})`
	margin: 0;
	flex-shrink: 0;
	/*width: 25%;*/
	margin-right: 8px;
	margin-bottom: 8px;
	align-self: flex-start;
	flex-shrink: 1;
	font-size: 16px;
`

const Description = styled.p`
	font-size: 14px;
	letter-spacing: 0.25px;
	color: rgba(0, 0, 0, 0.87);;
	margin: 0;
	width: 100%;
`

const MaxImage = styled(Image)`
	width: 100px;
	margin-left: 8px;
`

const Filters = styled.div`
	margin: 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 8px;
	>*
	{
		&:last-child
		{
			margin-right: 0;
		}
	}
`

const Filter = styled(Underlined)`
	margin: 0;
	margin-bottom: 4px;
	margin-right: 8px;
`

const Buttons = styled(FlexRow).attrs({
	margin: "8px",
	gap: "4px"
})`
	flex-shrink: 0;
`

class Contents extends React.Component {
	state = {
		openModal: false
	}
	
	componentDidMount() {
		if (!Object.values(this.props.stateFilters).length > 0) {
			this.props.fetchFilters()
		}
	}
	
	render() {
		const { t, id, title, description, filters = {}, imageUrl, edit, viewPath, deleteArticle, removable, stateFilters } = this.props
		
		//console.log({ props: this.props })
		
		const { goal, ...restFilters } = filters
		
		//console.log({goal, restFilters})
		
		return (
			<ContentWrapper>
				{
					this.state.openModal && (
						<Modal
							onClose={() => this.setState({ openModal: false})}
							title={`${t("article.list.delete.title")} ${title}?`}
							text={t("article.list.delete.text")}
							buttons={
								<SquareButton onClick={() => deleteArticle(id)}>
									{t("article.list.delete.confirm")}
								</SquareButton>
							}
							cancelButton={SquareButton}
							cancelText={t("article.list.delete.cancel")}
						/>
					)
				}
				<TextContainer>
					<FlexBetween>
						<Title>{title}</Title>
						<Buttons>
							{
								edit && (
									<>
										{
											removable && <DeleteButton onClick={() => {
												console.log("open modal")
												this.setState({ openModal: true})}
											} />
										}
										<SquareLink to={`./${id}`}>{t("articles.edit")}</SquareLink>
									</>
								)
							}
							{
								edit ?
									<TextLink to={`.${viewPath}/${id}`}>{t("articles.view")}</TextLink> :
									<TextNonLink>{t("articles.view")}</TextNonLink>
							}
						</Buttons>
					</FlexBetween>
					<Description>{description }</Description>
					{
						filters && stateFilters && Object.keys(stateFilters).length > 0 && Object.keys(filters).length > 0 && (
							<Filters>
								{
									restFilters && Object.values(restFilters).map( (filterGroup, index) => {
										const group = Object.keys(restFilters)[index]
										//console.log({ group, stateFilters, stateGroup: stateFilters[group]})
										return filterGroup.map(id => {
											return (
												<Filter key={id}>
													{t(`filters.${stateFilters[group][id].translationKey}`)}
												</Filter>
											)
										})
									})
								}
							</Filters>
						)
					}
				</TextContainer>
				{ imageUrl && <MaxImage src={imageUrl} alt="" /> }
			</ContentWrapper>
		)
	}
}


const ArticleListImageItemComponent = ({ children, ...rest}) => {
	//const ArticleListImageItemComponent = ({ children, edit, viewPath, deleteArticle }) => {
	const realProps = { ...rest, ...children }
	//console.log({ children, ...rest })
	if (!rest.edit) {
		return (
			<CardLink to={`./${children.id}`}>
				<Contents
					{...realProps}
				/>
			</CardLink>
		)
	}
	return (
		<Card>
			<Contents
					{...realProps}
			/>
		</Card>
	)
}

const mapStateToProps = state => {
	return {
		stateFilters: state.filters
	}
}

export const ArticleListImageItem = connect(mapStateToProps, { deleteArticle, fetchFilters })(
	withTranslation()(ArticleListImageItemComponent)
)
import styled from 'styled-components'

import logo from '../../img/lebensborn_black_500.png'
//import logo from '../../img/edu-g-h.png'
//import logo from '../../img/logo-edu-1x.png'

export const LogoLarge = styled.img.attrs({
	src: logo,
	alt: "My Child Lebensborn EDU"
})`
	max-width: 100%;
	width: 500px;
	height: auto;
	filter: drop-shadow(0 0 1px rgba(0,0,0,0.8)) drop-shadow(0 0 8px rgba(255,255,255,0.5));
`
import React from 'react'

const Separator = ({color="black", thickness="1px"}) => (
	<div style={{
		width: "100%",
		backgroundColor: color,
		height: thickness
	}}></div>
)

export default Separator;
import React from 'react'

import { ValidationForm, FORM_ACTION_POST } from '../forms/ValidationForm'
import { StyledForm } from '../common/forms/StyledForm'
import FlexRow from '../common/flex/FlexRow'
import { SquareButton } from './SquareButton'

import { PrettyField } from '../forms/PrettyField'
import { Editor } from './Editor'

export const SpecialEditor = ({ initialValues, language, path, operation={FORM_ACTION_POST}, onSuccess, submitButtonText}) => (
	<ValidationForm
		enableReinitialize
		initialValues={{
			title: "",
			description: "",
			data: "",
			...initialValues
		}}
		submitModifier={(values) => ({
			...values,
			language,
			hidden: true
		})}
		operation={operation}
		path={path}
		onSuccess={response => onSuccess(response.data.data)}
		render={ ({values, touched, errors, setFieldValue}) => {
			return (
				<StyledForm>
					<PrettyField
						name="title"
						i18nPath="admin.special"
						touched={touched}
						errors={errors}
					/>
					
					<Editor
						data={values.data}
						setFieldValue={setFieldValue}
					/>
					
					<FlexRow>
						<SquareButton type="submit" margin="20px">
							{submitButtonText}
						</SquareButton>
					</FlexRow>
				</StyledForm>
			)
		}}
	/>
)
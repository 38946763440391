import React from 'react'
import { connect } from 'react-redux'

import Title from '../../common/text/Title'
import Subtitle from '../../common/text/Subtitle'
//import { CircularButton } from '../../dashboard/CircularButton'
import TopBarFiller from '../../dashboard/TopBarFiller'
import { ProfileForm } from './ProfileForm'

import { fetchProfile } from '../../../redux/actions'
import { ChangePassword } from './ChangePassword'
//import { PopIn } from '../../common/PopIn';
import { withTranslation } from 'react-i18next';

import styled from 'styled-components'
import { COLOR_GREEN } from '../../../constants'
//import { MaxWidth } from '../../common/MaxWidth'

const Wrapper = styled.div`
	max-width: 800px;
	width: 100%;
	/*margin: 0 8px;*/
	/*display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;*/
	@media screen and (min-width: 800px)
	{
		width: 90%;
		margin: 0 auto;
	}
	@media screen and (min-width: 900px)
	{
		width: 80%;
	}
	@media screen and (min-width: 1000px)
	{
		width: 70%;
	}
	@media screen and (min-width: 1200px)
	{
		width: 60%;
		margin: 0 auto;
	}
`
/*
const StyledPopIn = styled(PopIn)`
	background: slategray;
	margin-left: auto;
`*/

const TextButton = styled.button`
	font-weight: 500;
	font-size: 14px;
	cursor: pointer;
	text-align: center;
	letter-spacing: 1.25px;
	text-transform: uppercase;
	background: transparent;
	border: none;
	color: ${props => props.color || COLOR_GREEN};
	padding: 8px;
	${props => {
		if (props.align) {
			switch (props.align.toLowercase()) {
				case "left":
					return (`
						margin-left: 0;
						margin-right: auto;
					`)
				case "right":
					return (`
						margin-left: auto;
						margin-right: 0;
					`)
				case "center":
					return (`
						margin-left: auto;
						margin-right: auto;
					`)
				default:
					return "margin: 0;"
			}
		}
	}}
	/*margin-left: ${props => (props.align && props.align.toLowercase() === "left" && "auto") || 0}
	margin-right: ${props => (props.align && props.align.toLowercase() === "left") || 0}*/
`

const RightTextButton = styled(TextButton)`
	display: block;
	margin-left: auto;
	margin-right: 0;
`

class ProfileComponent extends React.Component {
	
	state = {
		openPasswordChange: false
	}
	
	componentDidMount() {
		this.props.fetchProfile()
	}
	
	render() {
		const { profile, t } = this.props
		
		//console.log("profile", profile, !profile)
		if (!Object.keys(profile).length) return (
			<div>
				<TopBarFiller />
				
				<Title>...loading</Title>
			</div>
		)
		const { name, email } = profile//, address, city, postalCode, country } = profile
		return (
			<div>
				<TopBarFiller />
				
				<Wrapper>
					<Title>{name}</Title>
					<Subtitle>{email}</Subtitle>
					
					<ProfileForm
						profile={profile}
					>
						{
							!this.state.openPasswordChange && (
								<RightTextButton
									onClick={() => this.setState({openPasswordChange: true})}
								>
									{t("dashboard.profile.change-password")}
								</RightTextButton>
							)
						}
					</ProfileForm>
					
					{
						this.state.openPasswordChange && (
							<ChangePassword
								onComplete={() => this.setState({ openPasswordChange: false })}
							/>
						)
					}
					
				</Wrapper>
				
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		profile: state.profile
	}
}

export const Profile = connect(mapStateToProps, { fetchProfile })(
	withTranslation()(ProfileComponent)
)
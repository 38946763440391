export const arrayIncludesAll = (arrayToSearch, itemsToFind) => {
	console.log("ArrayIncludesAll")
	const itemsToFindArray = [...itemsToFind]
	const arrayCastArrayToSearch = [...arrayToSearch]
	//const stringCastArrayToSearch = arrayCastArrayToSearch.forEach( (item) => item.toString())
	//console.log(stringCastArrayToSearch)
	const failedToFindAllItems = itemsToFindArray.some(item => {
		return !arrayCastArrayToSearch.includes(item.toString())
	}) //list of true or false. If there is a false, then array does not include all elements
	
	if (failedToFindAllItems) {
		return false
	}
	return true
}
import React from 'react'
import { PopIn } from '../PopIn'
import { Formik, Form, Field } from 'formik'

import styled from 'styled-components'

const Div = styled.div.attrs({ className: "label-and-field"})``

const Button = styled.button``
/*
const Div = ({children, ...rest}) => (
	<div {...rest}>
		{children}
	</div>
)

const Button = ({children, ...rest}) => (
	<button {...rest}>
		{children}
	</button>
)*/

export const EditorPopIn = ({
	initialValue="",
	onSubmit,
	buttonText,
	button,
	formButtonText,
	formButton=Button,
	label,
	labelWrapper=Div,
	//height,
	...rest}) => {
	//const Wrapper = formWrapper || Div
	const FormButton = formButton// || Button
	const LabelWrapper = labelWrapper// || Div
	if (!onSubmit) throw Error("No submit value provided")
	if (!formButtonText) throw Error("No form button text provided")
	return (
		<PopIn
			render={(onComplete) => {
				return (
					<Formik
						initialValues={{
							text: initialValue// || ""
						}}
						onSubmit={(values, actions) => {
							//console.log(values, actions)
							onSubmit(values.text)
							onComplete()
						}}
						render={props => (
							<Form {...rest}>
								<LabelWrapper>
									{ label && <label htmlFor="text">{label}</label> }
									<Field type="text" name="text" autoFocus />
								</LabelWrapper>
								<FormButton
									type="submit"
								>
									{formButtonText}
								</FormButton>
							</Form>
						)}
					/>
				)
			}}
			buttonText={buttonText}
			button={button || Button}
		/>
	)
}
	
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { DISPLAY_NORMAL } from '../../redux/actions/types'
import { setChosenCommentDisplay } from '../../redux/actions'
import TopBar from '../dashboard/TopBar'

const StyledTopBar = styled(TopBar)`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
`

// bottom: ${ props => props.scroll ? "unset" : "0" };
const CommentDisplayWrapper = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
`

const Wrapper = styled(CommentDisplayWrapper)`
	background-color: #FEFDF9;
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	@media print
	{
        height: 100%;
	}
`

class FullscreenComponent extends React.Component {
	state = {
		fullscreen: false
	}
	
	escapeFullscreen = async () => {
		if (this.state.fullscreen) {
			console.log("Exiting fullscreen")
			this.setState({fullscreen: false})
			await document.exitFullscreen()
		}
	}
	
	toggleFullscreen = async () => {
		if (!this.state.fullscreen) {
			console.log("Entering fullscreen")
			this.setState({fullscreen: true})
      await document.querySelector("#root").requestFullscreen()
		}
		else {
			this.escapeFullscreen()
		}
	}
	
	render() {
		const { children, setChosenCommentDisplay, topBarComponent, ...rest} = this.props
		
		//const TopBar = this.props.topBarComponent
		//console.log("FUllscrene", rest)
		const FullscreenTopBar = topBarComponent || StyledTopBar
		
		if (this.state.fullscreen) {
			return (
				<Wrapper {...rest}>
					<FullscreenTopBar
						//noBackButton
						onClickBack={() => {
							setChosenCommentDisplay(DISPLAY_NORMAL)
							this.escapeFullscreen()
						}}
						fullscreen
						onClickFullscreen={() => this.toggleFullscreen()}
					/>
					{children}
				</Wrapper>
			)
		}
		return (
			<Wrapper {...rest}>
				<FullscreenTopBar
					//noBackButton
					onClickBack={() => {
						setChosenCommentDisplay(DISPLAY_NORMAL)
						this.escapeFullscreen()
					}}
					print
					fullscreen
					onClickFullscreen={() => this.toggleFullscreen()}
				/>
				{children}
			</Wrapper>
		)
	}
}
/*
const mapStateToProps = state => {
	
}*/

export const FullscreenScroll = connect(() => ({ scroll: true }), { setChosenCommentDisplay })(FullscreenComponent)

export const Fullscreen = connect(null, { setChosenCommentDisplay })(FullscreenComponent)

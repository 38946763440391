import React from "react"
import { connect } from "react-redux"
import { Router } from "@reach/router"
import { withTranslation } from "react-i18next"

//import Splash from './views/Splash';
//import Menu from './views/Menu'
import Login from "./views/Login"
import { DashboardLanding } from "./views/DashboardLanding"
//import FullScreen from './views/FullScreen';
import Users from "./views/Users"
import UserData from "./views/UserData"
import Customers from "./views/Customers"
import LicenseStats from "./views/LicenseStats"
import LicenseData from "./views/LicenseData"
import Articles from "./views/Articles"
import Article from "./views/Article"
import AskPlayers from "./views/AskPlayers"
import About from "./views/About"
import HowItWorks from "./views/HowItWorks"
import Landing from "./views/Landing"
import Feedback from "./views/Feedback"
import Licenses from "./views/Licenses"
import LicenseSingle from "./views/LicenseSingle"
import Order from "./views/Order"
import ChooseGroups from "./views/ChooseGroups"
import Question from "./views/Question"
import { GetStarted } from "./views/GetStarted"

import setAcceptLanguage from "../apis/setAcceptLanguage"
import AdminArticles from "./views/AdminArticles"
import { Dashboard } from "./views/Dashboard"
import { Admin } from "./views/Admin"
import { AdminArticleEdit } from "./views/AdminArticleEdit"
import { AdminArticleCreate } from "./views/AdminArticleCreate"
//import { ArticleEditor } from './articles/ArticleEditor';
import { fetchLocalAuth, setChosenLanguage } from "../redux/actions"
import Profile from "./views/Profile"
import { AdminGuide } from "./views/AdminGuide"
import UserRegistration from "./views/UserRegistration"
import {
    GUIDE_ARTICLE_EN,
    GUIDE_ARTICLE_NO,
    PATH_LICENSES,
    PATH_SUBJECT_MATERIAL,
    PATH_QUESTIONS,
    PATH_CHAPTERS,
    PATH_UPGRADE,
    PATH_INVOICES,
    PATH_PROFILE,
    PATH_DASHBOARD,
    PATH_ADMIN,
    PATH_ARTICLES,
    PATH_USERS,
    PATH_CUSTOMERS,
    PATH_LICENSES_STATS,
    PATH_CREATE,
    PATH_GUIDE_ADMIN_EN,
    PATH_GUIDE_ADMIN_NO,
    PATH_REGISTER,
    PATH_CHOOSE_GROUP,
    PATH_ORDER,
    PATH_LOGIN,
    PATH_GUIDE,
    PATH_VIEW,
    PATH_RESOURCES_ADMIN_EN,
    PATH_RESOURCES_ADMIN_NO,
    RESOURCES_ARTICLE_EN,
    RESOURCES_ARTICLE_NO,
    PATH_RESOURCES,
    PATH_SPECIAL_ARTICLES,
    PATH_FORGOT_PASSWORD,
    PATH_FEIDE_DESTINATION,
    PATH_FEIDE_ERROR,
    PATH_LANDING_GUIDE,
    PATH_LANDING_ABOUT,
    PATH_TERMS,
} from "../constants"
import { Upgrade } from "./views/Upgrade"
import { Invoices } from "./views/Invoices/Invoices"
import { AdminResources } from "./views/AdminResources"
import { Resources } from "./views/Resources"
import { SELECTED_LANGUAGE } from "../redux/actions/types"
import AdminSpecialArticles from "./views/AdminSpecialArticles"
import { AdminSpecialArticleEditor } from "./views/AdminSpecialArticleEditor"
import { ForgotPassword } from "./views/ForgotPassword"
import { FeideLoginDestination } from "./views/FeideLoginDestination"
import Terms from "./views/Terms"

class Routes extends React.Component {
    componentDidMount() {
        const { i18n } = this.props

        let language = localStorage.getItem(SELECTED_LANGUAGE)
        if (language) {
            i18n.changeLanguage(language)
            //this.props.fetchLocalLanguage()
            console.log(`Routes: Changed language to ${language}`)
        } else {
            language = i18n.language
            console.log(`Routes: Defaulted language to ${language}`)
        }
        setAcceptLanguage(language)
        setChosenLanguage(language)
    }

    render() {
        return (
            <Router>
                <Landing path="/" />
                <Landing path={PATH_FEIDE_ERROR} error={true} />
                <Order path={PATH_ORDER} />
                <HowItWorks path={PATH_LANDING_GUIDE} />
                <About path={PATH_LANDING_ABOUT} />
                <Login path={PATH_LOGIN} />
                <ForgotPassword path={`${PATH_FORGOT_PASSWORD}/:token`} />
                <UserRegistration path={`${PATH_REGISTER}/:token`} />
                <FeideLoginDestination
                    path={`${PATH_FEIDE_DESTINATION}/:token`}
                />
                <Terms path={PATH_TERMS} />
                <Dashboard path={PATH_DASHBOARD}>
                    <DashboardLanding path="/" />
                    <ChooseGroups path={PATH_CHOOSE_GROUP} />
                    <Feedback path={PATH_CHAPTERS} />
                    <AskPlayers path={PATH_QUESTIONS} />
                    <Question path={`${PATH_QUESTIONS}/:questionId`} />
                    <Articles path={PATH_SUBJECT_MATERIAL} />
                    <Article path={`${PATH_SUBJECT_MATERIAL}/:id`} />
                    <GetStarted path={PATH_GUIDE} />
                    <Resources path={PATH_RESOURCES} />
                    <Licenses path={`${PATH_LICENSES}`} />
                    <LicenseSingle path={`${PATH_LICENSES}/:licenseId`} />
                    <Upgrade path={`${PATH_LICENSES}/:id${PATH_UPGRADE}`} />
                    <Invoices path={`${PATH_LICENSES}/:id${PATH_INVOICES}`} />
                    <Profile path={PATH_PROFILE} />
                </Dashboard>
                <Admin path={PATH_ADMIN}>
                    <AdminArticles path="/" />
                    <AdminSpecialArticles path={PATH_SPECIAL_ARTICLES} />
                    <AdminSpecialArticleEditor
                        path={`${PATH_SPECIAL_ARTICLES}/:id`}
                    />
                    <Article
                        path={`${PATH_SPECIAL_ARTICLES}${PATH_VIEW}/:id`}
                        backPath="../../"
                    />
                    <AdminSpecialArticleEditor
                        blank
                        path={`${PATH_SPECIAL_ARTICLES}${PATH_CREATE}`}
                    />
                    <AdminArticleCreate path={PATH_CREATE} />
                    <AdminGuide
                        path={PATH_GUIDE_ADMIN_EN}
                        id={GUIDE_ARTICLE_EN}
                    />
                    <AdminGuide
                        path={PATH_GUIDE_ADMIN_NO}
                        id={GUIDE_ARTICLE_NO}
                    />
                    <AdminResources
                        path={PATH_RESOURCES_ADMIN_EN}
                        id={RESOURCES_ARTICLE_EN}
                    />
                    <AdminResources
                        path={PATH_RESOURCES_ADMIN_NO}
                        id={RESOURCES_ARTICLE_NO}
                    />
                    <Profile path={PATH_PROFILE} />
                    <AdminArticleEdit path="/:id" />
                    <Article path={`${PATH_VIEW}/:id`} backPath="../../" />
                    <Users path={PATH_USERS} /> 
                    <UserData path={`${PATH_USERS}/:id`} backPath="../../" />
                    <Customers path={PATH_CUSTOMERS} /> 
                    <UserData path={`${PATH_CUSTOMERS}/:id`} backPath="../../" />
                    <LicenseStats path={PATH_LICENSES_STATS} />
                    <LicenseData path={`${PATH_LICENSES_STATS}/:id`}  backPath="../../" />
                </Admin>
            </Router>
        )
    }
}

export default connect(null, { fetchLocalAuth })(withTranslation()(Routes))

import React from 'react'
import styled from 'styled-components'

/*const Title = ({text, children, ...rest}) => (
	<h1 style={{
		fontSize: "43px",
		textAlign: "center",
		fontWeight: "bold",
		letterSpacing: "0.510899px",
		color: "black",
		margin: "0",
		marginBottom: "25px"
	}} {...rest}>{text ? text : children}</h1>
)*/

export const Title = styled.h1`
	font-size: ${props => props.fontSize || "43px"};
	text-align: ${props => props.align || "center"};
	color: ${props => props.color || "black"};
	font-weight: bold;
	letter-spacing: 0.5px;
	margin: 0;
	margin-bottom: 24px;
	text-shadow: ${props => props.shadow || "none"};
`

const TitleComponent = ({ text, children, ...rest }) => (
	<Title {...rest}>
		{text || children}
	</Title>
)

export default TitleComponent

//export default Title
export const GUIDE_ARTICLE_NO = "1"
export const GUIDE_ARTICLE_EN = "2"

export const RESOURCES_ARTICLE_NO = "3"
export const RESOURCES_ARTICLE_EN = "4"

export const PATH_GUIDE_ADMIN_EN = `/${GUIDE_ARTICLE_EN}`
export const PATH_GUIDE_ADMIN_NO = `/${GUIDE_ARTICLE_NO}`

export const PATH_RESOURCES_ADMIN_EN = `/${RESOURCES_ARTICLE_EN}`
export const PATH_RESOURCES_ADMIN_NO = `/${RESOURCES_ARTICLE_NO}`

export const PATH_LANDING_GUIDE = "/guide"
export const PATH_LANDING_ABOUT = "/about"
export const PATH_TERMS = "/betingelser"

export const PATH_ORDER = "/order"
export const PATH_LOGIN = "/login"
export const PATH_FORGOT_PASSWORD = "/forgot-password"

export const PATH_FEIDE_DESTINATION = "/feide"
export const PATH_FEIDE_ERROR = "/feide_error"

export const PATH_ADMIN = "/admin"
export const PATH_DASHBOARD = "/dashboard"

export const PATH_USERS = "/users"
export const PATH_CUSTOMERS = "/customers"
export const PATH_LICENSES_STATS = "/licensestats"

export const PATH_CREATE = "/create"
export const PATH_VIEW = "/view"

export const PATH_ARTICLES = "/articles"
export const PATH_SPECIAL_ARTICLES = "/pages"

export const PATH_CHOOSE_GROUP = "/choose"
export const PATH_GUIDE = "/guide"
export const PATH_RESOURCES = "/resources"
export const PATH_REGISTER = "/register"
export const PATH_CHAPTERS = "/chapters"
export const PATH_QUESTIONS = "/questions"
export const PATH_LICENSES = "/licenses"
export const PATH_SUBJECT_MATERIAL = "/material"
export const PATH_UPGRADE = "/upgrade"
export const PATH_INVOICES = "/invoices"
export const PATH_PROFILE = "/profile"

export const COLOR_GREEN = "#3B7F83"
export const COLOR_GREY ="#C0C0C0"
export const COLOR_RED = "#A22C26" //unused

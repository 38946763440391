import styled from 'styled-components'
import { ReadOnlyField } from "./ReadOnlyField"

export const FixedField = styled(ReadOnlyField)`
	background: rgba(0, 0, 0, 0.04);
	padding: 10px 12px;
	border-bottom: 1px solid #9E9E9E;
	border-radius: 4px 4px 0px 0px;
	margin-bottom: 20px;
	width: 100%;

	label
	{
		width: 100%;
		font-size: 12px;
		letter-spacing: 0.4px;
		color: rgba(0, 0, 0, 0.54);
	}

	input
	{
		margin-top: 4px;
		width: 100%;
		font-size: 16px;
		letter-spacing: 0.15px;
		color: rgba(0, 0, 0, 0.87);
		border: 0;
		/*border-bottom: 1px solid #9E9E9E;*/
		background-color: transparent;
		background-color: rgba(0,0,0,0.1);
		/*text-decoration: underline;*/
		padding: 4px 6px;
		
	}

`
import React from 'react'
import styled from 'styled-components'

import { CheckCircle } from '@material-ui/icons';
import { COLOR_GREEN } from '../../constants';

const Wrapper = styled.div`
	position: relative;
	background: ${props => props.selected ? "rgba(59, 127, 131, 0.12)" : "white"};
	border: ${props => props.selected ? "1px solid transparent" : "1px solid #E5E5E5"};
	/*opacity: 0.12;*/
	border-radius: 4px;
	color: ${props => props.selected ? "rgba(0, 0, 0, 0.87)" : "rgba(0, 0, 0, 0.6)"};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	cursor: pointer;
	@media screen and (min-width: 800px)
	{
		padding: 16px 32px;
	}
	@media screen and (min-width: 1200px)
	{
		padding: 32px 64px;
	}
`

const Checkmark = styled(CheckCircle)`
	position: absolute;
	top: 12px;
	right: 12px;
	width: 100px;
	height: auto;
	color: ${COLOR_GREEN};
`

export const SelectableItem = ({ selected, children, ...rest }) => (
	<Wrapper selected={selected} {...rest} >
		{ selected && <Checkmark /> }
		{children}
	</Wrapper>
)
import React from 'react'
import { CardBase } from './CardBase'
import CardContents from './CardContents'
import CardButton from './CardButton';

const ImageOverflowCard = ({title, text, buttonText, buttonTo, image}) => (
	<CardBase style={{ position: "relative", margin: "30px 0" }}>
		<div style={{
			width: "calc(33.333% - 41px)"
		}}>
			<CardContents title={title} text={text} />
			<CardButton text={buttonText} to={buttonTo} />
		</div>
		<div style={{
			position: "absolute",
			top: "-20px",
			bottom: "-20px",
			right: "0",
			width: "66.666%",
			display: "flex",
			alignItems: "center",
			justifyContent: "center"
		}}>
			<img
				src={image}
				alt=""
				style={{
					width: "auto",
					height: "100%"
				}}
			/>
		</div>
	</CardBase>
)

export default ImageOverflowCard
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import CustomersListItem from './CustomersListItem'
import { Item, ItemWide, Centered, Right } from './common'

const CustomersListStyle = styled.div`

`

const CustomersHeader = styled.div`
	display: flex;
	padding: 20px 26px;
	font-weight: 500;
	font-size: 16px;
	text-transform: uppercase;
	color: #828282;
`

const CustomersList = ({children}) => {
	const { t } = useTranslation()
	return (
		<CustomersListStyle>
			<CustomersHeader>
				<ItemWide>
					<Centered>
						{t("admin.customers.list.name")}
					</Centered>
				</ItemWide>
				
				<ItemWide>
					<Centered>
						{t("admin.customers.list.email")}
					</Centered>
				</ItemWide>
				
				<Item>
					<Centered>
						{t("admin.customers.list.customer")}
					</Centered>
				</Item>
			</CustomersHeader>
			{children.map(
				({id, firstName, lastName, email, companyName, is_private}) => (
					<CustomersListItem
						key={id}
						id={id}
						firstName={firstName}
						lastName={lastName}
						is_private={is_private}
						companyName={companyName}
						name={!is_private ? companyName : firstName + " " + lastName}
						email={email}
					/>
				)
			)}
		</CustomersListStyle>
	)
}

export default CustomersList
import React from "react"
import styled from "styled-components"

const H2 = styled.h2`
    font-size: ${(props) => props.fontSize || "43px"};
    text-align: center;
    font-weight: 300;
    letter-spacing: 0.510899px;
    color: black;
    margin: 0;
    margin-bottom: 25px;
`
const Subtitle = ({ text, children, fontSize, ...rest }) => (
    <H2 fontSize={fontSize} {...rest}>
        {text ? text : children}
    </H2>
)

/*
const Subtitle = ({text, children, fontSize, ...rest}) => (
	<h2 style={{
		fontSize: fontSize || "43px",
		textAlign: "center",
		fontWeight: "300",
		letterSpacing: "0.510899px",
		color: "black",
		margin: "0",
		marginBottom: "25px"
	}} {...rest}>{text ? text : children}</h2>
)*/

export default Subtitle

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { ReactComponent as GroupStatistics } from '../../img/icons/group-statistics.svg'

const Wrapper = styled.div`
	width: 100%;
	background-color: white;
	border-bottom: 1px solid #E5E5E5;
	padding: 32px 48px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	/*margin-bottom: 48px;*/
`

const IconWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 17px;
	font-weight: bold;
	color: #424242;
	margin-right: 48px;
	text-transform: uppercase;
	p
	{
		margin: 0;
		margin-top: 8px;
	}
`

const Content = styled.div`
	p
	{
		font-size: 24px;
		font-weight: normal;
		color: black;
	}
`

const NoStatisticsText = styled.p`
	font-size: 28px;
	font-weight: 500;
	font-style: italic;
	margin: 0;
`

const RenderStatistics = ({statistics}) => {
	const { t } = useTranslation()
	if (!statistics.length) return (
		<NoStatisticsText>
			{t("dashboard.statistics.no-statistics")}
		</NoStatisticsText>
	)
	return (
		statistics.map( ({value, translationKey}, index) => {
			return <p key={index}><b>{value}</b> {t(`statistics.${translationKey}`)}</p>
		})
	)
}

export const Statistics = ({children, ...rest}) => {
	//console.log(children)
	const { t } = useTranslation()
	
	/*
	if (!children.length) return (
		<NoStatisticsText>
			{t("dashboard.statistics.no-statistics")}
		</NoStatisticsText>
	)*/
	
	return (
		<Wrapper {...rest}>
			<IconWrapper>
				<GroupStatistics />
				<p>{t("dashboard.statistics.shared")}</p>
			</IconWrapper>
			<Content>
				<RenderStatistics statistics={children} />
			</Content>
		</Wrapper>
	)
}
import React from 'react'
import { connect } from 'react-redux'

import TopBar from '../../dashboard/TopBar'
import { ChapterSelect } from './ChapterSelect'
import { fetchGroup } from '../../../redux/actions'
import { withTranslation } from 'react-i18next';
import { Chapter } from './Chapter';

class FeedbackContentComponent extends React.Component {
	
	componentDidMount() {
		const { fetchGroup, groupId, group } = this.props
		!group && fetchGroup(groupId)
	}
	
	render() {
		const { chapterNumber, group } = this.props
		//console.log("FeedbackContent: chapterNumber", chapterNumber)
		return (
			<>
				<TopBar>
					{ group && <ChapterSelect /> }
				</TopBar>
				{ group && <Chapter chapterNumber={chapterNumber} group={group} /> }
			</>
		)
	}
}

const mapStateToProps = state => {
	const groupId = state.selection.group
	const group = state.groups[groupId]
	const chapterNumber = state.selection.chapter
	
	//console.log("group.chapters", group && group.chapters)
	//const chapterNumber = group && group.chapters.indexOf(chapterId) + 1
	//console.log("chapter", chapterNumber)
	/*const chapters = group.chapters.map( chapterId => {
		return state.chapters[chapterId]
	})
	const chapter = state.chapters[state.selection.chapter]*/
	
	return {
		groupId,
		chapterNumber,
		group
	}
}

export const FeedbackContent = connect( mapStateToProps, { fetchGroup })(
	withTranslation()(FeedbackContentComponent)
)
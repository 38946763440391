import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { deleteGroup } from '../../../redux/actions'
import { DeleteButton } from '../../dashboard/DeleteButton'

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`

const Text = styled.p`
	font-size: 16px;
	letter-spacing: 0.15px;
	color: black;
	margin: 0;
	margin-bottom: 12px;
`

const Group = ({children, deleteGroup}) => {
	const { id, name, isEmpty, supergroupID } = children
	//console.log("Group", children)
	return (
		<Wrapper>
			<Text>
				{name}
			</Text>
			{isEmpty && (
				<DeleteButton onClick={() => deleteGroup(id, supergroupID)} />
			)}
		</Wrapper>
	)
}

export default connect(null, { deleteGroup })(Group)
import React from 'react'
import styled from 'styled-components'

import Header from '../../views/Landing/Header'
import Footer from '../../views/Landing/Footer'

const LandingPage = styled.div`
  background-color: #FFF9EE;
`

const Content = styled.div`
  padding: 0 16px;
  @media screen and (min-width: 800px)
  {
    padding-left: 32px;
    padding-right: 32px;
  }
  @media screen and (min-width: 1200px)
  {
    padding-left: 8rem;
    padding-right: 8rem;
  }
`

const StaticPage = (props) => {
  return (
    <LandingPage>
      <Header />
      <Content>
        {props.children || 'Static Page Template'}
      </Content>
      <Footer />
    </LandingPage>
  );
}

export default StaticPage
import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
	width: 100%;
	border: none;
	border-radius: 4px;
	color: white;
	background-color: slategray;
	cursor: pointer;
`

/* Button which turns into a custom component when clicked. Component must be able to run their onComplete prop if they should be able to turn back to the button again */

/*
	Example usage:
	
	<StyledPopIn
		render={(onComplete) => <ChangePassword onComplete={onComplete} />}
		buttonText={t("dashboard.profile.change-password")}
		button={RightTextButton}
	/>
*/
export class PopIn extends React.Component {
	state = {
		open: false
	}
	
	render() {
		const { render, buttonText, button, ...rest } = this.props
		const { open } = this.state
		//console.log("PopIn", this.props)
		const PropButton = button || Button
		if (!render) throw new Error("No render function was provided to this PopIn!")
		if (!buttonText) throw new Error("No button text was provided to PopIn!")
		if (!open) {
			return (
				<PropButton {...rest} onClick={() => this.setState({open: true})}>
					{buttonText}
				</PropButton>
			)
		}
		return render(() => this.setState({ open: false }))
	}
}
import React, { Suspense } from 'react'
import { connect } from 'react-redux'
import { fetchLocalAuth} from '../redux/actions'
import { BreakpointProvider, setDefaultBreakpoints } from 'react-socks'
import { Helmet } from 'react-helmet'

import Routes from './Routes'

import sharing from '../img/Sharing.png'

//import { LANDING, LOGIN, DASHBOARD, ASK_PLAYERS, TEACHER_GUIDANCE } from './routes'

import './i18n'
import './App.scss'
//import i18n from './i18n';

//import Rull from './views/Kake';
setDefaultBreakpoints([
	{ mobile: 0 },
	{ tablet: 800 },
	{ desktop: 1400 }
]);
class App extends React.Component {
	
	componentDidMount() {
		this.props.fetchLocalAuth()
		
		//const { i18n } = this.props
		
		//const language = localStorage.getItem(SELECTED_LANGUAGE)
		//i18n.changeLanguage(language)
		//this.props.fetchLocalLanguage()
	}
	
	render() {
		return (
			<Suspense fallback={null}>
				<BreakpointProvider>
					<Helmet>
						<meta property="og:url"                content="https://www.mychildlebensbornedu.no" />
						<meta property="og:type"               content="website" />
						<meta property="og:title"              content="My Child Lebensborn EDU" />
						<meta property="og:description"        content="Et innovativt, digitalt læremiddel" />
						<meta property="og:image"              content={sharing} />
					</Helmet>
					<Routes />
				</BreakpointProvider>
			</Suspense>
		)
	}
}

export default connect(null, { fetchLocalAuth })(App)
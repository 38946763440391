import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

import bg from '../../img/Outside_summer_2_1920.png'
import { LogoLarge } from '../landing/LogoLarge'

const Wrapper = styled.div`
	background-image: url(${bg});
	background-size: cover;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly; /* Won't work in edge? */
`

const LoginBackground = ({ children }) => {
	return (
		<Wrapper>
			<Link to="/">
				<LogoLarge />
			</Link>
			{children}
		</Wrapper>
	);
};

export default LoginBackground;
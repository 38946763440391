import {
	FETCH_FAVOURITES,
	SET_FAVOURITE,
	DELETE_FAVOURITE
} from '../actions/types';

export default (state = [], action) => {
	switch (action.type) {
		case FETCH_FAVOURITES:
			return [
				...action.payload
			]
		case SET_FAVOURITE:
			return [
				...state,
				action.payload
			]
		case DELETE_FAVOURITE:
			return state.filter( item => item.articleID !== action.payload)
		default:
			return state;
	}
}
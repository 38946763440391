import React from "react"
//import { Link } from '@reach/router';
//import { useTranslation } from 'react-i18next';
import styled from "styled-components"
import { Helmet } from "react-helmet"

import { PATH_ORDER } from "../../../constants"

import Title from "../../common/text/Title"
import Subtitle from "../../common/text/Subtitle"
import ShrinkableDiv from "../../common/ShrinkableDiv"
//import ExternalLink from '../../common/ExternalLink';

import fishingWithKarin from "../../../img/screenshots/Fishing-with-Karin.jpg"
import imgTheShop from "../../../img/screenshots/theshop.jpg"
import dashboardScreenshot from "../../../img/screenshots/dash-preview.png"

//import TutorialStep from '../../steps/TutorialStep';
import ResponsiveColumns from "../Landing/ResponsiveColumns"

import StaticPage from "../../templates/StaticPage"
import style from "./HowItWorks.module.scss"
import { TextLink } from "../../landing/TextLink"

const ContentWrapper = styled.div`
    margin: 4rem 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 1200px) {
        margin: 0;
    }
`

/*const IngressParagraph = styled.p`
  text-align: center;
  font-size: 1.2rem;
`;*/

const TextContent = styled.p`
    font-size: 1.1rem;
    line-height: 1.4;
    font-weight: 400;
`

/*const StepDescription = styled.p`
  line-height: 1.5;
  font-size: 1.1rem;
`*/

const ParagraphHeading = styled.h2`
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 0.510899px;
    color: black;
    margin: 0;
    margin-bottom: 1rem;
`

const HowItWorks = () => {
    //const { t } = useTranslation();
    return (
        <StaticPage>
            <Helmet>
                <title>Slik fungerer det</title>
                <meta
                    property="og:url"
                    content="https://www.mychildlebensbornedu.no/guidance"
                />
                <meta property="og:title" content="Slik fungerer det" />
                <meta
                    property="og:description"
                    content="Undervisningsopplegget er designet for å gi en god klasseroms- og gruppeopplevelse av spillet My Child Lebensborn, og tilrettelegger for at alle i spillergruppa kan få delta aktivt i undervisningen."
                />
                <meta property="og:image" content={dashboardScreenshot} />
            </Helmet>
            <ContentWrapper>
                <section>
                    <Title>Slik fungerer det</Title>
                    <Subtitle fontSize="1.7rem">
                        Dette undervisningsopplegget er designet for å gi en god
                        klasseroms- og gruppeopplevelse av spillet «My Child
                        Lebensborn», og tilrettelegger for at alle i
                        spillergruppa kan få delta aktivt i undervisningen.
                    </Subtitle>
                </section>
            </ContentWrapper>

            <ContentWrapper>
                <section>
                    <ResponsiveColumns
                        breakpoint="600px"
                        right={
                            <img
                                className={style.dashboardScreenshotImg}
                                src={dashboardScreenshot}
                                alt="Screenshot of Klaus at home"
                            />
                        }
                        left={
                            <ShrinkableDiv>
                                <section className={style.sectionPadded}>
                                    <ParagraphHeading>
                                        Enkelt og raskt
                                    </ParagraphHeading>
                                    <TextContent>
                                        Det er raskt å komme i gang på
                                        Android/Chrome OS. Lisens kan kjøpes
                                        på{" "}
                                        <TextLink to={PATH_ORDER}>
                                            denne siden
                                        </TextLink>{" "}
                                        (med tilsendt faktura eller kredittkort)
                                        og gir tilgang for lærer/gruppeleder med
                                        en gang.
                                    </TextContent>
                                    <TextContent>
                                        Elever / deltagere kan laste ned appen
                                        gratis på egen, privat mobil eller
                                        nettbrett med Android, eller den kan
                                        installeres på klassesett av
                                        Android-utstyr eller Chrome OS.
                                    </TextContent>
                                    <TextContent>
                                        Det har dessverre ikke vært mulig å
                                        få EDU ut på AppStore. Løsningen er
                                        ikke tilgjengelig for bruk med utstyr
                                        som bruker iOS (som iPhones eller iPads).
                                    </TextContent>
                                    {/* <TextContent>
                                        For Apple /iOS-utstyr er appen
                                        tilgjengelig for skoler som er brukere
                                        av Apple School Manager (ASM). Søk frem
                                        «My Child Lebensborn EDU» i ASM for
                                        nedlasting av appen til iPads eller
                                        iPhones som skolen administrerer
                                        sentralt. (Ved kjøp av mer enn 20
                                        lisenser i bulk gis 50% rabatt og
                                        iOS-appen koster derved NOK 16,50,-.
                                        Dette gir varig tilgang til spillet,
                                        uavhengig av gyldig lisens på
                                        nettsiden. Gratisappen hos Android kan
                                        gir kun tilgang til spillet sammen med
                                        gyldig lisens på denne siden.)
                                    </TextContent>
                                    <TextContent>
                                        Vi klarer dessverre ikke å tilby gratis
                                        nedlasting til elevers private iPhones /
                                        iPads på linje med Android.
                                        Skoler/enheter som bruker Apple Business
                                        Manager kan kjøpe apper for privat
                                        utstyr som administreres sentralt.
                                        Kontakt oss hvis dette er et alternativ.
                                    </TextContent> */}
                                    <TextContent>
                                        Når deltagerne har fått installert appen
                                        trengs det kun en felles kode fra
                                        lærer/gruppeleder for å knytte appen til
                                        EDU-løsningen. Denne koden ligger på
                                        dette nettstedet og kan skrives på
                                        tavlen eller leses opp. Klassen kan være
                                        i gang på kort tid. Erfaring viser at
                                        elevene synes det er lett å registrere
                                        kode og brukernavn for å komme i gang.
                                    </TextContent>
                                </section>
                            </ShrinkableDiv>
                        }
                    />
                </section>
            </ContentWrapper>

            <ContentWrapper>
                <section>
                    <ResponsiveColumns
                        breakpoint="600px"
                        left={
                            <img
                                className={style.columnImg}
                                src={fishingWithKarin}
                                alt="Screenshot of Klaus at home"
                            />
                        }
                        right={
                            <ShrinkableDiv>
                                <section>
                                    <ParagraphHeading>
                                        Ta løsningen i bruk
                                    </ParagraphHeading>
                                    <TextContent>
                                        Ved første gangs bruk må
                                        lærer/gruppeleder opprette en ny gruppe
                                        for klassen. Det forklares i detalj i
                                        vår «Kom i gang»-guide. Klikk på «Velg
                                        gruppe» og «pluss» for å opprette en ny,
                                        eller velg en eksisterende gruppe hvis
                                        du deler lisens med andre lærere.
                                    </TextContent>
                                    <TextContent>
                                        Deretter behøver du kun å oppgi navnet
                                        på gruppa til elevene, slik at de kan
                                        knytte seg til riktig gruppe.
                                    </TextContent>
                                </section>
                            </ShrinkableDiv>
                        }
                    />
                </section>
            </ContentWrapper>

            <ContentWrapper>
                <section style={{ textAlign: "center" }}>
                    <ParagraphHeading>Da er dere i gang!</ParagraphHeading>
                </section>
            </ContentWrapper>

            <ContentWrapper>
                <section>
                    <ParagraphHeading>
                        Det er mye dere kan gjøre i klassen!
                    </ParagraphHeading>

                    <ul>
                        <li>
                            <TextContent>
                                Be elevene spille spillet på mobiltelefonene. Gi
                                elevene et spørsmål de skal besvare etter hvert
                                kapittel. Det fins egne kommentarfelt som kan
                                brukes når et kapittel er ferdigspilt. (Det kan
                                også være lekse å spille og så legge inn
                                kapittelkommentar).
                            </TextContent>
                        </li>
                        <li>
                            <TextContent>
                                Vis statistikken over gruppas spillervalg for
                                hele klassen og diskuter erfaringene fra det
                                ferdigspilte kapittelet.
                            </TextContent>
                        </li>
                        <li>
                            <TextContent>
                                Bruk ett av de over 70 ferdiglagde
                                undervisningsoppleggene i klassen. Du kan søke
                                frem relevant opplegg med å filtrere på
                                læringsmål, fag, tema og alderstrinn.
                            </TextContent>
                        </li>
                        <li>
                            <TextContent>
                                Still spørsmål som alle elevene kan svare på
                                mobilen i «Spillerspørsmål». Svarene fra elevene
                                kan vises som ordsky eller «post-it»-lapper.
                            </TextContent>
                        </li>
                        <li>
                            <TextContent>
                                Det fins også en chat-funksjon i mobilappen som
                                lar alle i spillergruppa diskutere. Bruk den til
                                en løpende diskusjon mens alle spiller eller til
                                å gi beskjeder til alle spillerne.
                            </TextContent>
                        </li>
                    </ul>
                </section>
            </ContentWrapper>

            <ContentWrapper>
                <section>
                    <ResponsiveColumns
                        breakpoint="600px"
                        right={
                            <img
                                className={style.columnImg}
                                src={imgTheShop}
                                alt="Screenshot of Klaus at home"
                            />
                        }
                        left={
                            <ShrinkableDiv>
                                <section>
                                    <ParagraphHeading>
                                        Samarbeid på tvers av fag
                                    </ParagraphHeading>
                                    <TextContent>
                                        Alle lisenser unntatt den minste lar
                                        lisenseier invitere så mange lærere /
                                        gruppeledere man vil inn i løsningen.
                                        Dette er for å forenkle muligheten for å
                                        samarbeide på tvers med bruk av spillet.
                                        Kanskje samme klasse kan bruke spillet i
                                        flere fag med flere lærere, eller at
                                        flere lærere ønsker å kjøre samme
                                        opplegg for flere klasser?
                                    </TextContent>
                                    <TextContent>
                                        Vi håper dere kan få engasjerende
                                        undervisning og god læring med dette
                                        læremiddelet.
                                    </TextContent>
                                </section>
                            </ShrinkableDiv>
                        }
                    />
                </section>
            </ContentWrapper>

            <ContentWrapper>
                <section style={{ textAlign: "center" }}>
                    <TextContent>
                        <strong>Mvh.</strong>
                    </TextContent>
                    <TextContent>
                        <span className={style.signature}>
                            Produsent Elin Festøy og konsulent/lærer Terje
                            Pedersen
                        </span>
                    </TextContent>
                </section>
            </ContentWrapper>
        </StaticPage>
    )
}

/*
const HowItWorksAlternative = () => {
  const { t } = useTranslation();
  return (
    <StaticPage>
      <section>
        <Title>Kom i gang</Title>
        <Subtitle fontSize="1.7rem">
          Lærer/gruppeleder behøver å:
        </Subtitle>
      </section>

      <ContentWrapper>
        <section>

          <TutorialStep title="Ha tilgang til dette nettstedet">
            Dette er alt på plass!
          </TutorialStep>

          <TutorialStep title="Opprette en spillgruppe">
            Gå til <strong>denne</strong> siden og klikk "ny gruppe".<br />
            Gi gruppen et unikt navn. Klikk lagre.
          </TutorialStep>

          <TutorialStep title="Be elevene laste ned mobilappen">
            Be elevene om å leste ned appen "My Child Lebensborn EDU" fra AppStore eller Google Play.<br />
            Dette er gratis.<br />
            NB! Pass på at de velger EDU-varianten av spillet.
          </TutorialStep>

          <TutorialStep title="Gi elevene/deltagerne lisenskoden som aktiverer spillappene">
            Lisenskoden finner du på <strong>lisenssiden</strong> <br />
            Der kan du også se hvor mange nedlastinger som gjenstår på lisensen.<br />
            Hvis det ikke er nok spill til gruppen må lisensen utvides av lisenseier.
          </TutorialStep>

          <TutorialStep title="Be elevene om å legge inn et kallenavn">
            Bruk av kallenavn sikrer personvernet.<br />
            For å kunne identifisere brukerne kan lærer/gruppeleder vurdere å gi alle en unik ID.<br />
            Et eksempel på dette kan være "Superhelt04".
          </TutorialStep>

          <TutorialStep title="Laste inn og aktivisere app på egen mobil">
            Dette gir tilgang både til spillet og den enkle chaten for gruppa, som kun er tilgjengelig i appen.
          </TutorialStep>

          <TutorialStep title="Velg ett av undervisningsoppleggene - eller lag ditt eget.">
            Ved å klikke på lenken <strong>navn på lenke</strong> til venstre i margen kommer du til filteret med undervisningsopplegg. Det er over 70 forskjellige forslag i løsningen.<br /><br />
            Velg en eller flere filtreringer for å redusere lista til stadig mer relevante opplegg for din målgruppe. Ved å klikke på stjernen oppe til høyre på det undervisningsopplegget du velger vil det bli lagret en snarvei dit i venstremargen.
          </TutorialStep>

        </section>
        </ContentWrapper>
        
        <section>
          <Subtitle fontSize="1.7rem">Elever/deltagere behøver å:</Subtitle>
        </section>

        <ContentWrapper>
          <section className={style.section}>

            <TutorialStep title="Installere spillappen på sine mobiler eller nettbrett">
              Last ned appen "My Child Lebensborn EDU" fra AppStore eller GooglePlay
            </TutorialStep>

            <TutorialStep title="Skrive inn lisenskoden for å aktivisere appen">
              Lærer deler ut lisenskoden - som er identisk for alle deltagere.
            </TutorialStep>

            <TutorialStep title="Skriv inn navnet på spillergruppen som lærer/gruppeleder har opprettet.">
              Navnet må skrives presist for å havne i riktig gruppe.
            </TutorialStep>

            <TutorialStep title="Gi deg selv et kallenavn (ev. med gjenkjennelseskode fra lærer).">
              Navnet kan ikke endres, så gjenkjennelseskoder bør introduseres før dette trinnet.
            </TutorialStep>

          </section>
        </ContentWrapper>

        <section>
          <Title>Ta i bruk</Title>
          <IngressParagraph>Ved vellykket installering skal alle nå ha tilgang til følgende i sin mobilapp: spillet "My Child Lebensborn", chat og kommunikasjonsfelt hvor man kan svare på spørsmål fra lærer/gruppeleder.</IngressParagraph>
        </section>

        <ContentWrapper>
          <section className={style.section} >

            <ResponsiveColumns
              breakpoint="1200px"
              left={
                <ShrinkableDiv>
                  <StepDescription>
                    <p>Ved vellykket installering skal alle nå ha tilgang til følgende i sin mobilapp:</p>
                    <div className={style.listWrap}>
                      <ul className={style.checkList}>
                        <li>Spillet "My Child Lebensborn"</li>
                        <li>Chat</li>
                        <li>kommunikasjonsfelt</li>
                      </ul>
                    </div>
                  </StepDescription>
                </ShrinkableDiv>
              }
              right={
                <ShrinkableDiv>
                  <StepDescription>
                    <p>På nettsiden har lærer tilgang til:</p>
                    <div className={style.listWrap}>
                      <ul className={style.checkList}>
                        <li>Mange ulike undervisningsopplegg</li>
                        <li>Å sende ut spørsmål til hele spillergruppa</li>
                        <li>Å vise frem svarene fra spillergruppa på storskjerm.</li>
                        <li>Å vise frem gruppas spillerstatistikk etter hvert kapittel i spillet</li>
                        <li>Å vise frem spillernes kommentarer etter hvert kapittel</li>
                      </ul>
                    </div>
                  </StepDescription>
                </ShrinkableDiv>
              }
            />

          </section>
        </ContentWrapper>

      </StaticPage>
  );
}*/

export default HowItWorks

import React from 'react'
import styled, { keyframes } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FlexRow } from '../../common/flex'

const fadeOut = keyframes`
	0% {
		opacity: 1;
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
`

const Text = styled.p`

`

const Wrapper = styled.div`
	width: 500px;
	max-width: 90%;
	
	padding: 12px 24px;
	
	position: fixed;
	z-index: 1000;
	top: 96px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(255, 100, 100);
	animation: 8s ${fadeOut};
	opacity: 0;
	${Text}
	{
		font-size: 20px;
		color: white;
	}
`

export const ErrorPopup = () => {
	const { t } = useTranslation()
	return (
		<FlexRow>
			<Wrapper>
				<Text>
					{t("landing.error")}
				</Text>
			</Wrapper>
		</FlexRow>
	)
}
	
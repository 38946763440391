import React from 'react'
import styled from 'styled-components'
import { Link as ReachLink } from '@reach/router';
import { Item, ItemSlim, ItemWide, Fat, Right, Centered } from './common'
import { useTranslation } from 'react-i18next';
import { COLOR_GREEN } from '../../../constants';
import { Edit} from '@material-ui/icons'

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	background-color: white;
	border-bottom: 2px solid #E5E5E5;
	padding: 20px 26px;
	font-size: 16px;
	margin-bottom: 16px;
`

const Link = styled(ReachLink)`
	text-decoration: none;
	color: ${COLOR_GREEN};
	font-weight: 500;
	letter-spacing: 0.5px;
`

const LicenseStatsListItem = ({id, license, devices_used, devices_allowed, admins, date, owner, email}) => {
	const { t } = useTranslation()
	return (
		<Wrapper>
			<ItemWide>
				<Centered>
					{owner}
				</Centered>
			</ItemWide>

			<ItemWide>
				<Centered>
					{email}
				</Centered>
			</ItemWide>

			{/* <Item>
				<Centered>
					<Fat>
						{license}
					</Fat>
				</Centered>
			</Item> */}
			
			<Item>
				<Centered>
					{devices_used}
				</Centered>
			</Item>

			<Item>
				<Centered>
					{devices_allowed}
				</Centered>
			</Item>

			<Item>
				<Centered>
					{admins}
				</Centered>
			</Item>

			<Item>
				<Centered>
					{date}
				</Centered>
			</Item>
			
			<ItemSlim>
				<Link to={`./${id}`}>
					<Fat>
						<Centered>
							<Edit/>
							{/* {t("admin.licenses.list.view")} */}
						</Centered>
					</Fat>
				</Link>
			</ItemSlim>
		</Wrapper>
	)
}

export default LicenseStatsListItem
import backend from './backend';

const setAuthToken = token => {
	console.log("setAuthToken", token);
	if (token) {
		// Apply authorization token to every request if logged in
		backend.defaults.headers.common["Authorization"] = `bearer ${token}`;
		//axios.setToken(token, 'Bearer');
	} else {
		// Delete auth header
		delete backend.defaults.headers.common["Authorization"];
		//axios.setToken();
	}
};

export default setAuthToken;
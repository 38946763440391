import styled from 'styled-components'

export const ValidationError = styled.p`
	color: red;
	margin: 0;
	margin-bottom: 8px;
	text-transform: lowercase;
	&::first-letter
	{
		text-transform: uppercase;
	}
`

import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"

import Title from "../../common/text/Title"
import Subtitle from "../../common/text/Subtitle"

const Wrapper = styled.div`
    background-color: #fff9ee;
    padding: 8px;
`

const Content = styled.div`
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
`

const TextContent = styled.p`
    font-size: 1.1rem;
    line-height: 1.5;
`

export const Terms = () => {
    return (
        <Wrapper>
            <Helmet>
                <title>Vilkår og betingelser</title>
                <meta
                    property="og:url"
                    content="https://www.mychildlebensbornedu.no/betingelser"
                />
                <meta property="og:title" content="Vilkår og betingelser" />
                <meta
                    property="og:description"
                    content="Dette digitale læremiddelet er produsert av Teknopilot AS, ved produsent Elin Festøy. Lærer Terje Pedersen ved Rothaugen Skole har vært konsulent og utvikler av undervisningsoppleggene."
                />
            </Helmet>
            <Content>
                <Title>Vilkår og betingelser</Title>
                <Subtitle fontSize="1.3rem">
                    Dette digitale læremiddelet er produsert av Teknopilot AS,
                    ved produsent Elin Festøy. Lærer Terje Pedersen ved
                    Rothaugen Skole har vært konsulent og utvikler av
                    undervisningsoppleggene.
                </Subtitle>
                <TextContent>
                    Undervisningsløsningen «My Child Lebensborn EDU» består av
                    to deler – et nettsted med undervisningsmateriell og en app
                    for mobiler, nettbrett og Chromebooks. Appen inneholder det
                    prisbelønte mobilspillet «My Child Lebensborn» og
                    kommunikasjonsfunksjonalitet tilpasset spilling i gruppe.
                </TextContent>
                <TextContent>
                    Ved igangsetting benytter lærer/gruppeleder nettstedet til å
                    etablere en spillergruppe og til å hente en
                    aktiviseringskode som muliggjør tilknytning av brukere/apper
                    til spillergruppa. Det etableres toveis kommunikasjon mellom
                    nettsted og deltagere i spillergruppa samt
                    chat-funksjonalitet mellom appene i spillergruppa.
                </TextContent>
                <TextContent>
                    Løsningen lagrer e-post og faktureringsdata om lisenskjøper
                    samt innloggingsdata om lærere/gruppeledere, men ingen andre
                    persondata ut over dette. Feide-innlogging kan også
                    benyttes. For app-brukerne lagres maskinvare-ID for det
                    fysiske apparatet samt brukernavnet som benyttes i
                    spillergruppa. Vi oppfordrer aktivt til bruk av fiktive
                    brukernavn.
                </TextContent>
                <TextContent>
                    Ved spilling registreres spillervalg sentralt på server for
                    å kunne generere statistikk over gruppas samlede spill for
                    diskusjon i plenum. Disse dataene består av rene
                    interaksjonsvalg i tilknytning til spillingen og inkluderer
                    ikke noen mulighet for tekstinput eller lignende fra
                    brukerne.
                </TextContent>
                <TextContent>
                    I tillegg lagres de tekstbaserte spørsmålene og svarene som
                    lærer/grupperleder og spillere utveksler ved bruk av
                    kommuniksjonsfunksjonaliteten og den tekstbaserte chat-en
                    mellom deltagerne i spillergruppa. Det samles ikke inn noen
                    annen informasjon fra verken app eller nettsted enn det som
                    er nevnt ovenfor. Ved slik bruk av løsningen vil kun ID til
                    de benyttede nettbrett, mobiler eller Chromebooks kunne
                    identifiseres og ingen personlig informasjon vil deles.
                    Lisenseier er ansvarlig for at løsningen ikke blir benyttet
                    til annen dialog enn det som er relatert til
                    undervisningsoppleggene som er inkludert i løsningen.
                </TextContent>
                <TextContent>
                    Teknopilot AS tar personvern på alvor og oppfordrer til å
                    unngå all bruk av kommunikasjonsløsningen som kan føre til
                    deling av personsensitiv informasjon. Teknopilot AS kan ikke
                    holdes ansvarlig for eventuelle konsekvenser av slik misbruk
                    av løsningen. Teknopilot AS behandler alle data med
                    konfidensialitet og velger underleverandører med tanke på
                    høy datasikkerhet og gode interne datarutiner. Løsningen er
                    utviklet i Norge av Teknopilot AS, med app-programmering fra
                    Sarepta Studio AS og nettløsningsutvikling av Ways AS. Data
                    lagres kryptert hos Google Cloud i Finland. Når en
                    lisensperiode går ut slettes alle data tilknyttet bruk av
                    lisensen.
                </TextContent>
                <TextContent>
                    Nettløsningen er tilpasset universell utforming med god
                    kontrast/lesbarhet på tekst. Generisk
                    forstørrelsesfunksjonalitet kan benyttes i mobiler og
                    nettbrett for økt lesbarhet på spillet. Spillet kan
                    dessverre ikke benyttes med automatisk tekstopplesning.
                </TextContent>
                <TextContent>
                    Spillet «My Child Lebensborn» er basert på faktiske
                    opplevelser for norske tyskerbarn og er representativt for
                    deres opplevelser i etterkrigstiden. Det er, sammen med
                    dokumentarfilmen «Wars Don’t End», denne EDU-tilpasningen
                    for grupper/skoler og etablering av stiftelsen Children Born
                    of War Project, del av et transmedieprosjekt skapt for å
                    kommunisere historiene om norske tyskerbarn og gi kunnskap
                    om og støtte for dagens krigsbarn – Children Born of War.
                    Teknopilot AS og med-produsent Sarepta Studio AS donerer
                    deler av omsetningen til spillet til dette formål. Les mer
                    på www.cbowproject.org.
                </TextContent>
                <TextContent>
                    Både spillet og klipp fra filmen inneholder på grunn av
                    dette krevende tematikk som kan være opprørende for noen. Vi
                    forutsetter at løsningen benyttes under oversyn av
                    lærer/gruppeleder og anbefaler alle gruppeledere/lærere å
                    sette seg inn i beskrivelsen av spillets innhold. En
                    beskrivelse finnes øverst på siden Undervisningsopplegg >
                    Ressurser i nettløsningen. Det er lisenseiers ansvar å sørge
                    for forsvarlig bruk av «My Child Lebensborn EDU» og
                    tilstrekkelig oppfølging av spillerne. Teknopilot AS kan
                    ikke holdes ansvarlig for uansvarlig bruk av løsningen.
                </TextContent>
            </Content>
        </Wrapper>
    )
}

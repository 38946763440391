import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
//import { Link } from '@reach/router'
import { Edit, Delete } from '@material-ui/icons'

import { editQuestion, deleteQuestion } from '../../../redux/actions'
import { Item, Fill } from './common'
import { ListCard } from '../../common/ListCard';
import { InlinePopInEditor } from '../../common/InlinePopInEditor';
import { TextLink } from '../../dashboard/TextLink';
import { COLOR_GREEN } from '../../../constants';


const QuestionListCard = styled(ListCard)`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
`

const RightItem = styled(Item)`
	margin-left: 32px;
	flex-shrink: 0;
`

const Green = styled.div`
	color: ${COLOR_GREEN};
`

const Red = styled.div`
	color: #EB5757;
`

const Button = styled.button`
	margin: 0;
	padding: 0;
	border: 0;
	background: transparent;
	cursor: pointer;
`

/*
const Input = styled.input`
	width: 100%;
`*/

class QuestionComponent extends React.Component{
	/*
	state = {
		editing: false,
		title: this.props.title
	}*/
	/*
	renderEditing = () => (
		<>
			<Input
				type="text"
				autoFocus
				value={this.state.title}
				onChange={ (e) => {
					this.setState({title: e.target.value})
				}}
			/>
			<button
				onClick={ () => {
					console.log("Clicked save")
					this.props.editQuestion(this.props.id, this.state.title)
					this.setState({editing: false})
				}}
				buttonText="Save changes"
			>
				Save change
			</button>
		</>
	)
	
	renderNormal = () => {
		const { title } = this.props
		return (
			<>
				<Fill>
					{title}
				</Fill>
				<RightItem>
					<Button onClick={ () => {
						console.log("Clicked edit button")
						this.setState({editing: true})
					}}>
						<Green>
							<Edit />
						</Green>
					</Button>
				</RightItem>
			</>
		)
	}*/
	
	render() {
		const { id, t, deleteQuestion, editQuestion, title } = this.props
		return (
			<QuestionListCard key={id}>
				<InlinePopInEditor
					initialValue={this.props.title}
					onSubmit={(title) => {
						editQuestion(id, title)
					}}
					buttonText={t("dashboard.questions.edit-confirm")}
				>
					{
						(onClick) => (
							<>
								<Fill>
									{title}
								</Fill>
								<RightItem>
									<Button onClick={ () => {
										onClick()
									}}>
										<Green>
											<Edit />
										</Green>
									</Button>
								</RightItem>
							</>
						)
					}
				</InlinePopInEditor>
				{
					/*
					this.state.editing
						?
							this.renderEditing()
						:
							this.renderNormal()
					*/
				}
				<RightItem>
					<Button
						onClick={ () => {
							console.log(`Clicked to delete question ${id}`)
							deleteQuestion(id)
						}}
					>
						<Red>
							<Delete />
						</Red>
					</Button>
				</RightItem>
				<RightItem>
					<TextLink to={`./${id}`}>
						{t("dashboard.ask-players.show")}
					</TextLink>
				</RightItem>
			</QuestionListCard>
		)
	}
}

export const Question = connect(null, { editQuestion, deleteQuestion })(
	withTranslation()(QuestionComponent)
)
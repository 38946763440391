import React from 'react'
import styled from 'styled-components'

const SectionWrapper = styled.div`

`

const SectionTitle = styled.h4`
	border-bottom: 1px solid #E5E5E5;
	padding: 24px;
	width: 100%;
`

const SectionContent = styled.div`
	padding: 12px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	@media screen and (min-width: 800px)
	{
		padding: 24px;
		flex-direction: row;
	}
`

export const CardSection = ({title, children, ...rest}) => (
	<SectionWrapper {...rest}>
		<SectionTitle>
			{title}
		</SectionTitle>
		<SectionContent>
			{children}
		</SectionContent>
	</SectionWrapper>
)
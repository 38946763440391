import React from "react"
//import { connect } from 'react-redux'
import styled from "styled-components"
import { navigate } from "@reach/router"
import { withTranslation } from "react-i18next"
import { Form, Field } from "formik"
import * as Yup from "yup"

//import { fetchBundles } from '../../redux/actions'
import { PATH_TERMS } from "../../constants"

import { Bundle } from "./Bundle"
//import backend from '../../apis/backend';
import { SquareButton } from "../dashboard/SquareButton"
import FlexRow from "../common/flex/FlexRow"
import { PrettyField } from "../forms/PrettyField"
import { Loading } from "../dashboard/Loading"
import { ValidationForm, FORM_ACTION_POST } from "../forms/ValidationForm"
import { SelectableItem } from "./SelectableItem"
import { Checkbox } from "../articles/Checkbox"
import { TextLink } from "../dashboard/TextLink"
//import { PATH_REGISTER } from '../../constants';
//import { RadioBox } from '../forms/RadioBox'

const PAYMENT_INVOICE = "PAYMENT_INVOICE"
const PAYMENT_CARD = "PAYMENT_CARD"

const Wrapper = styled.div`
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
`

const SectionWrapper = styled.div``

const SectionTitle = styled.h4`
    border-bottom: 1px solid #e5e5e5;
    padding: 24px;
    width: 100%;
`

const SectionContent = styled.div`
    padding: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (min-width: 800px) {
        padding: 24px;
        flex-direction: row;
    }
`

const BundleWrapper = styled.div`
    display: grid;
    grid-gap: 16px 12px;
    width: 100%;
    grid-template-columns: 1fr;
    @media screen and (min-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (min-width: 1400px) {
        grid-template-columns: repeat(3, 1fr);
    }
`

const StyledForm = styled(Form)`
    width: 100%;
    word-break: break-word;
`

const PostalPlaceWrapper = styled.div`
    display: flex;
    flex-direction: row;
    > *:first-of-type {
        margin-right: 20px;
    }
`

const Link = styled(TextLink).attrs({
    as: "a",
    target: "_blank",
    rel: "noopener noreferrer",
})`
    text-decoration: underline;
`

const CheckboxLabel = styled.label`
    display: block;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
    width: 100%;
    margin-bottom: 24px;
`

const Section = ({ title, children, ...rest }) => (
    <SectionWrapper>
        <SectionTitle>{title}</SectionTitle>
        <SectionContent>{children}</SectionContent>
    </SectionWrapper>
)

const PaymentMethods = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    > * {
        margin-bottom: 12px;
    }
    @media screen and (min-width: 800px) {
        flex-direction: row;
        justify-content: flex-start;
        > * {
            margin-right: 24px;
            margin-bottom: 0;
            &:last-child {
                margin-right: 0;
            }
        }
    }
`

const PaymentType = styled(SelectableItem)`
    padding: 12px 64px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.25px;
    font-size: 24px;
    @media screen and (min-width: 800px) {
        font-size: 28px;
    }
    @media screen and (min-width: 1400px) {
        font-size: 34px;
    }
`

const OrderField = ({ name, ...rest }) => {
    //console.log(rest)
    return <PrettyField name={name} i18nPath="form.fields" {...rest} />
}

const OrderSchema = Yup.object().shape({
    acceptTerms: Yup.boolean()
        .required("validation.required")
        .oneOf([true], "For å kunne bruke tjenesten må du godta betingelsene"),
})

class OrderFormComponent extends React.Component {
    state = {
        selectedBundle: undefined, //, //this.props.bundles && this.props.bundles[0]
        //lastCurrency: undefined,
        //currencyChanged: false
        selectedPayment: PAYMENT_CARD,
    }

    componentDidUpdate() {
        const { selectedBundle } = this.state || {}
        const { bundles } = this.props || {}
        if (!selectedBundle && bundles?.length) {
            this.setState({ selectedBundle: bundles[0].id })
        }
        /*if (this.state.lastCurrency !== this.props.currency) {
			this.setState({currencyChanged: true})
		}*/
        //this.setState({lastCurrency: this.props.currency})
    }

    componentDidMount() {
        //this.props.fetchBundles()
        const { selectedBundle } = this.state || {}
        const { bundles } = this.props || {}
        if (!selectedBundle && bundles?.length) {
            this.setState({ selectedBundle: bundles[0].id })
        }
    }

    render() {
        const {
            t,
            bundles = [],
            currency,
            profile,
            pathInvoice,
            pathCard,
            successURL,
            errorURL,
        } = this.props || {}
        const { selectedBundle } = this.state || {}
        //console.log("OrderForm", { bundles, selectedBundle: this.state.selectedBundle, profile})
        if (!selectedBundle) return <Loading /> //remove this to make testing possible
        let letIsCompany = false //default
        let letRestProfile = {} //default empty
        if (profile) {
            //console.log("company is ", profile.isCompany)
            const { isCompany, ...restProfile } = profile
            //console.log({ isCompany, restProfile})
            letIsCompany = isCompany
            letRestProfile = restProfile
        }
        //console.log("la", letIsCompany, letRestProfile)

        return (
            <Wrapper>
                <Section title={t("ordering.form.choose-bundle")}>
                    <BundleWrapper>
                        {bundles.map((bundle, index) => (
                            <Bundle
                                key={bundle.id}
                                bundle={bundle}
                                selected={bundle.id === selectedBundle}
                                onClick={() =>
                                    this.setState({ selectedBundle: bundle.id })
                                }
                                currency={currency}
                            />
                        ))}
                    </BundleWrapper>
                </Section>

                {
                    <Section title={t("ordering.form.payment")}>
                        <PaymentMethods>
                            <PaymentType
                                selected={
                                    this.state.selectedPayment ===
                                    PAYMENT_INVOICE
                                }
                                onClick={() =>
                                    this.setState({
                                        selectedPayment: PAYMENT_INVOICE,
                                    })
                                }
                            >
                                {t("ordering.form.payment.invoice")}
                            </PaymentType>

                            <PaymentType
                                selected={
                                    this.state.selectedPayment === PAYMENT_CARD
                                }
                                onClick={() =>
                                    this.setState({
                                        selectedPayment: PAYMENT_CARD,
                                    })
                                }
                            >
                                {t("ordering.form.payment.card")}
                            </PaymentType>
                        </PaymentMethods>
                    </Section>
                }

                <Section title={t("ordering.form.order-license")}>
                    <ValidationForm
                        initialValues={{
                            userType: letIsCompany ? "company" : "private",
                            //isCompany: isCompany || false,
                            firstName: "",
                            lastName: "",
                            email: "",
                            address: "",
                            city: "",
                            postalCode: "",
                            country: "",
                            orgNumber: "",
                            companyName: "",
                            acceptTerms: false,
                            ...letRestProfile,
                        }}
                        operation={FORM_ACTION_POST}
                        path={
                            this.state.selectedPayment === PAYMENT_INVOICE
                                ? pathInvoice
                                : pathCard
                        }
                        submitModifier={(values) => {
                            const { userType, ...restValues } = values
                            //console.log("SubmitModifier", userType, userType === "company", restValues)
                            return {
                                licenseBundleID: selectedBundle,
                                currency,
                                successURL,
                                errorURL,
                                user: {
                                    isCompany: userType === "company",
                                    ...restValues,
                                },
                            }
                        }}
                        onSuccess={(data) => {
                            navigate(data.redirectURL)
                        }}
                        validationSchema={OrderSchema}
                        render={(props) => {
                            //console.log("Rendering orderForm: Props.values", props, props.values)
                            /*if (this.state.currencyChanged) {
								this.setState({currencyChanged: false, lastCurrency: this.props.currency})
								props.setFieldValue("country", "Norway")
							}*/
                            return (
                                <StyledForm>
                                    {/*
										
										<Switch
											name="isCompany"
											touched={props.touched}
											errors={props.errors}
											values={props.values}
										/>
										
										*/}

                                    {/*
										<RadioBox
											label="Company"
											groupName="userType"
											name="company"
											values={props.values}
										/>
										
										<RadioBox
											label="Private"
											groupName="userType"
											name="private"
											values={props.values}
										/>
										*/}

                                    {!profile && (
                                        <>
                                            <label>
                                                <Field
                                                    type="radio"
                                                    name="userType"
                                                    value="company"
                                                    checked={
                                                        props.values[
                                                            "userType"
                                                        ] === "company"
                                                    }
                                                />
                                                {t(
                                                    "form.fields.userType.company"
                                                )}
                                            </label>

                                            <label>
                                                <Field
                                                    type="radio"
                                                    name="userType"
                                                    value="private"
                                                    checked={
                                                        props.values[
                                                            "userType"
                                                        ] !== "company"
                                                    }
                                                />
                                                {t(
                                                    "form.fields.userType.private"
                                                )}
                                            </label>
                                        </>
                                    )}

                                    <OrderField
                                        name="firstName"
                                        touched={props.touched}
                                        errors={props.errors}
                                    />

                                    <OrderField
                                        name="lastName"
                                        touched={props.touched}
                                        errors={props.errors}
                                    />

                                    <OrderField
                                        name="email"
                                        type="email"
                                        touched={props.touched}
                                        errors={props.errors}
                                    />

                                    <OrderField
                                        name="address"
                                        touched={props.touched}
                                        errors={props.errors}
                                    />

                                    <PostalPlaceWrapper>
                                        <OrderField
                                            name="postalCode"
                                            touched={props.touched}
                                            errors={props.errors}
                                        />
                                        <OrderField
                                            name="city"
                                            touched={props.touched}
                                            errors={props.errors}
                                        />
                                    </PostalPlaceWrapper>

                                    <OrderField
                                        name="country"
                                        touched={props.touched}
                                        errors={props.errors}
                                        //value={currency === "nok" ? "Norge" : this.props.handleChange}
                                        //disabled={currency === "nok"}
                                    />

                                    {props.values.userType === "company" && (
                                        <>
                                            <OrderField
                                                name="orgNumber"
                                                touched={props.touched}
                                                errors={props.errors}
                                            />
                                            <OrderField
                                                name="companyName"
                                                touched={props.touched}
                                                errors={props.errors}
                                            />
                                        </>
                                    )}

                                    <Checkbox
                                        name="acceptTerms"
                                        touched={props.touched}
                                        errors={props.errors}
                                        labelComponent={CheckboxLabel}
                                        label={
                                            <>
                                                Jeg bekrefter å ha lest{" "}
                                                <Link href={PATH_TERMS}>
                                                    vilkårene for bruk
                                                </Link>{" "}
                                                av «My Child Lebensborn EDU»
                                            </>
                                        }
                                    />

                                    <FlexRow>
                                        <SquareButton
                                            type="submit"
                                            margin="20px"
                                        >
                                            {t("ordering.form.submit")}
                                        </SquareButton>
                                    </FlexRow>

                                    {/*JSON.stringify(props.values, null, 3)*/}
                                </StyledForm>
                            )
                        }}
                    />
                </Section>
            </Wrapper>
        )
    }
}
/*
const mapStateToProps = state => {
	const bundles = state.bundles && Object.keys(state.bundles).length ? Object.values(state.bundles) : []
	return {
		bundles
	}
}*/

//export const OrderForm = connect(mapStateToProps, { fetchBundles })(
export const OrderForm = withTranslation()(OrderFormComponent)
//)


import React from 'react'
import { connect } from 'react-redux'
import { FilterList } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { toggleFilterView } from '../../redux/actions'

//import FlexRow from '../common/flex/FlexRow'

const Button = styled.button`
	display: flex;
	cursor: pointer;
	border: 0;
	background: transparent;
	padding: 8px;
	font-size: 20px;
	font-weight: bold;
	letter-spacing: 0.15px;
`

const Text = styled.p`
	margin: 0;
`

const ArticleFilterButton = ({ showFilter, toggleFilterView }) => {
	const { t } = useTranslation()
	return (
		<Button
			onClick={toggleFilterView}
		>
			<Text>
				{
					showFilter ?
					t("dashboard.articles.filter.hide-filter") :
					t("dashboard.articles.filter.show-filter")
				}
			</Text>
			<FilterList />
		</Button>
	)
}

const mapStateToProps = state => {
	return {
		showFilter: state.showFilterView
	}
}

export default connect(mapStateToProps, { toggleFilterView })(ArticleFilterButton)
import styled from 'styled-components'

import { COLOR_GREEN } from '../../constants'

export const TextExternalLink = styled.a`
	margin: 0;
	padding: 0;
	border: 0;
	text-decoration: none;
	font-weight: 500;
	font-size: 14px;
	letter-spacing: 1.25px;
	text-transform: uppercase;
	color: ${COLOR_GREEN};
	flex-shrink: 0;
`
import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
//import { Link } from '@reach/router'
import styled from 'styled-components'

//import LanguageSwitch from '../../LanguageSwitch'

import { loginFeide } from '../../../redux/actions'

import logo from '../../../img/lebensborn_black_1000.png'
//import logo from '../../../img/edu-g-s@3x.png'
//import logo from '../../../img/logo-edu-3x.png'
//import { ReactComponent as Burger } from '../../../img/icons/burger.svg'
//import { ReactComponent as ShoppingCart } from '../../../img/icons/shopping-cart.svg'
//import { ReactComponent as Book } from '../../../img/icons/book.svg'
import klaus from '../../../img/klaus_crop.png'
//import bg from '../../../img/landing-title-background.png'
import bg from '../../../img/Outside_summer_2_1920.png'

//import style from './Header.module.scss'
//import LoginButton from './LoginButton'
import { OrderButton } from './buttons/OrderButton'
import { GuidelineButton } from './buttons/GuidelineButton';
import { VersionNumber } from '../../landing/VersionNumber';

import { SquareLink } from '../../dashboard/SquareLink'
//import { SquareButton } from '../../dashboard/SquareButton'
import { FeideLogin as FeideLoginButton } from './buttons/FeideLogin'

const breakpoints = {
	desktop: "800px",
	mobileLarge: "500px",
	mobileHeight: "500px"
}

const Nav = styled.nav`
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	flex-wrap: wrap;
	@media screen and (min-width: ${breakpoints.desktop})
	{
		position: absolute;
		z-index: 10;
		top: 24px;
		right: 24px;
	}
	.icon
	{
		margin-right: 8px;
	}
	.displayDesktop
	{
		display: none;
		@media screen and (min-width: ${breakpoints.desktop})
		{
			display: block;
		}
	}
	p
	{
		font-weight: 900;
		font-size: 24px;
		letter-spacing: 0.92px;
		color: white;
	}
	&:hover
	{
		color: grey;
	}
	.navElement
	{
		cursor: pointer; /* nav elements are clickable */
		padding: 8px; /* for easier clicking */
		color: white;
	}
	
	>*
	{
		margin-right: 0;
		&:not(:last-child)
		{
			margin-right: 8px;
			margin-bottom: 8px;
		}
	}
`

/*
const NavElement = styled.div`
	>*
	{
		color: white;
		font-weight: bold;
		font-size: 24px;
		letter-spacing: 0.92px;
		text-decoration: underline;
		padding: 8px;
		margin: 0;
	}
`*/

const topGradient = "linear-gradient(180deg, black, transparent, transparent)"
const rightGradient = "linear-gradient(-80deg, black, transparent, transparent)"

const Wrapper = styled.header`
	position: relative;
	height: 100vh;
	
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	margin-bottom: 48px;
	
	background-image: url(${klaus}), ${topGradient}, url(${bg});
	background-size: 65%, auto, cover;
	background-repeat: no-repeat;
	background-position: right bottom, top, top center;
	padding: 24px;
	@media screen and (min-width: 500px)
	{
		background-image: url(${klaus}), ${topGradient}, url(${bg});
		background-size: auto 65vh, auto, cover;
		background-position: 90% bottom, top, top left;
	}
	@media screen and (min-width: 800px)
	{
		background-image: url(${klaus}), ${topGradient}, ${rightGradient}, url(${bg});
		background-size: auto 90vh, auto, auto, cover;
		background-position: 90% bottom, top, top right, top left;
	}
	@media screen and (max-height: 500px) and (orientation: landscape)
	{
		background-image: url(${klaus}), ${topGradient}, url(${bg});
		background-size: 60vh, auto, cover;
		background-position: right 40vh, center, top left;
	}
`

const LogoWrapper = styled.div`
	position: relative;
	z-index: 3;
	
	display: flex;
	justify-content: center;
	@media screen and (min-width: 800px)
	{
		justify-content: flex-start;
	}
`

const Buttons = styled.div`
	position: absolute;
	z-index: 1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 24px;
	display: flex;
	flex-direction: row;
	align-content: flex-end;
	justify-content: flex-start;
	flex-wrap: wrap;
`

const Logo = styled.img`
	width: 100%;
	@media screen and (min-width: 800px)
	{
		width: 50vw;
		height: auto;
	}
	@media screen and (max-height: 500px) and (orientation: landscape)
	{
		width: auto;
		height: 45vh;
	}
`

const Link = styled(SquareLink)`
	font-size: 20px;
	max-width: 100%;
	white-space: normal;
`

const FeideLogin = styled(FeideLoginButton)`
	font-size: 20px;
	max-width: 100%;
	white-space: normal;
`

const Header = ({ loginFeide }) => {
	const { t } = useTranslation()
	return (
		<Wrapper>
			<VersionNumber version={process.env.BUILD_NUMBER} />
			<LogoWrapper>
				<Logo src={logo} alt="" />
			</LogoWrapper>
			<Nav>
				{
					/*
						<div className={style.navElement}>
							<LanguageSwitch text="Change language" className={style.displayDesktop} style={{marginRight: "24px"}} />
						</div>
						<div className={style.navElement}>
							<Link to="/login">Login</Link>
						</div>
					*/
				}
				{/*
				<NavElement>
					<LanguageSwitch />
				</NavElement>
				*/}
				<FeideLogin />
				<Link to="/login">{t("buttons.login")}</Link>
				{
					/*
						<NavElement>
							<NavLink to="/login">{t("landing.login")}</NavLink>
						</NavElement>
					*/
				}

			</Nav>
			{
				/*
				
				<LoginButton />
				<div className={style.button}>
					<Link to="/order">
						<div className={style.orderButton}>
							<ShoppingCart className={style.icon} />
							<span>{t("landing.order-license")}</span>
						</div>
					</Link>
				</div>
				
				*/
			}
			<Buttons>
				<GuidelineButton />
				<OrderButton />
			</Buttons>
			
		</Wrapper>
	)
}
export default connect(null, { loginFeide })(Header)
import React from 'react'
import { connect } from 'react-redux'

//import TopBar from '../../dashboard/TopBar'
//import Title from '../../common/text/Title';
//import { FourOhFour } from '../../common/FourOhFour';
//import { Comments } from '../../comments/Comments';
import { fetchLocalSelection } from '../../../redux/actions'
import { QuestionContent } from './QuestionContents';

export class QuestionComponent extends React.Component {
	componentDidMount() {
		//fetch question
		const { fetchLocalSelection } = this.props
		fetchLocalSelection()
		//fetchQuestions(questionId)
		//this.props.fetchComments(this.props.question.comments)
	}
	
	render() {
		const { questionId, groupId } = this.props
		return (
			<>
				{ groupId && <QuestionContent id={questionId} groupId={groupId} /> }
			</>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		groupId: state.selection && state.selection.group, //always defined
		//comments: state.comments
	}
}

export const Question = connect(mapStateToProps, { fetchLocalSelection })(QuestionComponent)
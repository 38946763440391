import { FETCH_CHAPTER, SET_CHOSEN_GROUP } from "../actions/types";

export default (state = {
	/*
	1: {
		id: 1,
		number: 1,
		stats: [
			{
				value: "30%",
				description: "id: 1, number: 1"
			},
			{
				value: "99%",
				description: "feiret bursdag (resten var født på skuddårsdag)"
			}
		],
		comments: [
			1,2
		]
	},
	2: {
		id: 2,
		number: 2,
		stats: [
			{
				value: "80%",
				description: "id: 2, number: 2"
			},
			{
				value: "92%",
				description: "valgte et tilfeldig alternativ"
			}
		],
		comments: [
			3,4
		]
	},
	3: {
		id: 3,
		number: 3,
		stats: [
			{
				value: "100%",
				description: "id: 3, number: 3"
			},
			{
				value: "0%",
				description: "av utviklerne bryr seg"
			}
		],
		comments: [
			5,6,7
		]
	},
	4: {
		id: 4,
		number: 1,
		stats: [
			{
				value: "100%",
				description: "id: 4, number: 1"
			},
			{
				value: "0%",
				description: "av utviklerne bryr seg"
			}
		],
		comments: [
			8
		]
	},
	5: {
		id: 5,
		number: 2,
		stats: [
			{
				value: "100%",
				description: "id: 5, number: 2"
			},
			{
				value: "0%",
				description: "av utviklerne bryr seg"
			}
		],
		comments: [
			8
		]
	}
	*/
}, action) => {
	switch (action.type) {
		case SET_CHOSEN_GROUP:
			return {} //wipe chapters on choosing a new group
		case FETCH_CHAPTER:
			return {
				...state,
				[action.payload.number]: {
					...action.payload
				}
			}
		default:
			return state;
	}
}
import React from 'react'
import { connect } from 'react-redux'

import Group from './Group'
import { createGroup, fetchGroups, deleteSupergroup } from '../../../redux/actions'
//import { InputButton } from './InputButton'
import { getGroups } from '../../groups/getGroups'

import styled from 'styled-components'

import { ListCard } from '../../common/ListCard'
import { StyledEditorPopIn } from '../../common/forms/StyledEditorPopIn';
import { SquareButton } from '../../dashboard/SquareButton';
import { withTranslation } from 'react-i18next';
import { DeleteButton } from '../../dashboard/DeleteButton'

const Wrapper = styled(ListCard)`
	flex-direction: column;
	justify-content: flex-start;
`

const TitleBar = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`

const Title = styled.h3`
	font-weight: bold;
	font-size: 16px;
	letter-spacing: 0.15px;
	color: black;
	margin: 0;
	margin-bottom: 16px;
`

//this component must only be run after ensuring license and supergroup is both updated!
class Supergroup extends React.Component {
	state = {
		inputValue: ""
	}
	
	componentDidMount() {
		//fetch supergroups
		const { supergroup, fetchGroups } = this.props
		fetchGroups(supergroup.id)
	}
	
	renderGroups = () => {
		//console.log("Rendergroups", this.props.groups)
		return this.props.groups.map( (group) => {
			//console.log(group, this.props.isOwner)
			return <Group key={group.id}>{group}</Group>
		})
	}
	
	handleSubmit = text => {
		const { createGroup, supergroup } = this.props
		createGroup(supergroup.id, text)
	}
	
	render() {
		//console.log("supergroup", this.props.supergroup)
		//console.log("this.props", this.props)
		const { groups, t, isOwner, deleteSupergroup } = this.props
		//console.log("Supergroup render", groups)
		//if(!supergroup) return <div>Loading or something for later</div>
		const { id, name, licenseID } = this.props.supergroup
		//console.log("supergroup is", this.props.supergroup)
		return (
			<Wrapper>
				<TitleBar>
					<Title>{name}</Title>
					{isOwner && !groups.length && (
						<DeleteButton onClick={() => deleteSupergroup(id, licenseID)} />
					)}
				</TitleBar>
				<div>
					{groups.length > 0 && this.renderGroups()}
				</div>
				{ 
					//<InputButton onSubmit={this.handleSubmit}>Add new group</InputButton>
				}
				<StyledEditorPopIn
					onSubmit={this.handleSubmit}
					buttonText={t("dashboard.licenses.single.add-group")}
					formButtonText={t("dashboard.licenses.single.save-group")}
					button={SquareButton}
					formButton={SquareButton}
				/>
			</Wrapper>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	//const supergroup = state.supergroups[ownProps.id]
	const groups = getGroups(state, ownProps.supergroup.id)
	
	//console.log(groups)
	return {
		//supergroup,
		groups
	}
}

export default connect(
	mapStateToProps,
	{
		createGroup,
		fetchGroups,
		deleteSupergroup
	}
)(
	withTranslation()(Supergroup)
)
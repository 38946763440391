import _ from 'lodash';
import {
	FETCH_QUESTIONS, CREATE_QUESTION, EDIT_QUESTION, DELETE_QUESTION, SET_CHOSEN_GROUP,
} from '../actions/types';

export default (state = {
	/*
	1: {
		id: 1,
		title: "Why do you like me so much?",
		comments: [ 10,11,12 ]
	},
	2: {
		id: 2,
		title: "Where would you like to eat?",
		comments: [ 13,14 ]
	},
	3: {
		id: 3,
		title: "How do we hide the evidence?",
		comments: [ 15,16,17,18 ]
	}
	*/
}, action) => {
	switch (action.type) {
		case FETCH_QUESTIONS:
			return {
				...state,
				...action.payload
			}
		case CREATE_QUESTION:
			return {
				...state,
				[action.payload.id]: {
					...action.payload,
					comments: []
				}
			}
		case EDIT_QUESTION:
			return {
				...state,
				[action.payload.id]: {
					...action.payload
				}
			}
		case DELETE_QUESTION:
			return {
				..._.omit(state, action.payload)
			}
		//there were two ways to do this, either slightly complex updating and extra fetching to get slightly better performance, or this, which is faster to implement.
		case SET_CHOSEN_GROUP: //if we have changed group, then all questions are invalid
			return {} //therefore, wipe state
		default:
			return state;
	}
}
import React from 'react';
import StaticPage from '../../templates/StaticPage';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Helmet } from 'react-helmet'

import Title from '../../common/text/Title';
import Subtitle from '../../common/text/Subtitle';
import ShrinkableDiv from '../../common/ShrinkableDiv';

import ResponsiveColumns from '../Landing/ResponsiveColumns';

const ContentWrapper = styled.div`
margin: 2rem 5rem;
display: flex;
flex-direction: column;
justify-content: center;
`

const TextContent = styled.p`
font-size: 1.1rem;
line-height: 1.5;
`

const About = () => {
  const { t } = useTranslation();
  return(
    <StaticPage>
        <Helmet>
          <title>Om oss</title>
          <meta property="og:url"                content="https://www.mychildlebensbornedu.no/about" />
          <meta property="og:title"              content="Om oss" />
          <meta property="og:description"        content="Dette digitale læremiddelet er produsert av Teknopilot AS, ved produsent Elin Festøy. Lærer Terje Pedersen ved Rothaugen Skole har vært konsulent og utvikler av undervisningsoppleggene." />
        </Helmet>
        <section>
          <Title>{t("about.title")}</Title>
          <Subtitle fontSize="1.7rem">
            Dette digitale læremiddelet er produsert av Teknopilot AS, ved produsent Elin Festøy. Lærer Terje Pedersen ved Rothaugen Skole har vært konsulent og utvikler av undervisningsoppleggene.
          </Subtitle>
        </section>

        <ContentWrapper>
          <section>

            <TextContent>
              Tilrettelegging av mobilspillet til klasserom er utført av Sarepta Studio AS og nettside med integrering mot app og betalingsløsning er utviklet av Ways AS.
            </TextContent>
            
            <TextContent>
              Mobilspillet «My Child Lebensborn» er produsert av Teknopilot AS, ved produsent Elin Festøy, i co-produksjon med Sarepta Studio og Lead Game Designer Catharina Due-Bøhler.
            </TextContent>

            <TextContent>
              Løsningen inkluderer klipp fra dokumentarfilmen «De Uønskede», produsert av UpNorth og co-produsert av Teknopilot AS. Filmen viser intervjuer med mange av Lebensborn-barna som har hjulpet til i utviklingen av spillet.
            </TextContent>

            <TextContent>
              <strong>Krevende tematikk</strong><br />
              Spillet er basert på de sanne historiene til norske Lebensborn-barn og utviklet i samarbeid med disse. Det er historisk representativt. Dette betyr at spillet behandler tung tematikk som mobbing og misbruk av barn. Brukere av undervisningsløsningen bes være oppmerksom på dette. Utgiver tar ikke ansvar for bruk av undervisningsopplegget uten tilstrekkelig pedagogisk oppfølging. Se også veiledning om bruk under lenken Ressurser i dashboard i denne løsningen.
            </TextContent>

          </section>

          <section>
            <ResponsiveColumns
              breakpoint="600px"
              left={
                <ShrinkableDiv>
                  <TextContent>
                    <strong>Kontakt</strong><br />
                    For spørsmål om lisenser eller bruk, kontakt: support@teknopilot.no.
                  </TextContent>
                  <TextContent>
                    For andre henvendelser om løsningen, kontakt produsent Elin Festøy: elin.festoy@teknopilot.no
                  </TextContent>
                  <TextContent>
                    Tusen takk til støtte fra Kulturrådet, Kreativt Norge, for bidrag til finansiering av løsningen.
                  </TextContent>
                  <TextContent>
                    Copyright: Teknopilot AS
                  </TextContent>
                </ShrinkableDiv>
              }
            />
          </section>
        </ContentWrapper>

    </StaticPage>
  );
}

export default About;
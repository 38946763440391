import { groupsAreInState } from './groupsAreInState'

export const getGroups = (state, supergroupId) => {
	//console.log("getGroups", state, supergroupId)
	const supergroup = state.supergroups[supergroupId]
	if (Object.keys(state.groups).length <= 0) return [] //short circuit if state groups is empty
	
	const stateGroups = Object.keys(state.groups)
	//console.log(supergroup, stateGroups)
	const groupsInState = groupsAreInState(supergroup, stateGroups)
	//console.log(groupsInState)
	if (!groupsInState) {
		return [] //empty array won't break maps
	}
	
	return supergroup.groups.map( (groupId) => (
		state.groups[groupId]
	))
}
import React from 'react'
import { connect } from 'react-redux'
import ReactWordcloud from 'react-wordcloud'
import styled from 'styled-components'

import { setChosenCommentDisplay } from '../../redux/actions'
//import { Statistics } from './Statistics'
import { Fullscreen } from './Fullscreen'

const ItemWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

const CloudWrapper = styled.div`
	height: 100%;
`

const StyledCloud = styled(ReactWordcloud)`
	height: 100%;
`

/*
const WordCloudComponent = ({ words, statistics }) => (
	<Fullscreen>
		<ItemWrapper>
			<CloudWrapper>
				<StyledCloud
					options={{
						rotations: 1,
						rotationAngles: [0],
						fontSizes: getFontSizes()
					}}
					//size={[800,500]}
					words={words}
				/>
			</CloudWrapper>
			{statistics && <Statistics>{statistics}</Statistics>}
		</ItemWrapper>
	</Fullscreen>
)*/

class WordCloudComponent extends React.Component {
	state = {
		windowWidth: document.body.clientWidth
	}
	
	mediaQuery = {
		desktop: 1200,
		tablet: 500
	}
	
	updateWidth = () => {
		this.setState({windowWidth: document.body.clientWidth});
	}
	
	componentDidMount() {
		window.addEventListener('resize', this.updateWidth);
	}
	
	componentWillUnmount = async () => {
		window.removeEventListener('resize', this.updateWidth);
		//await this.escapeFullscreen()
	}
	
	getFontSizes() {
		let max = 32;
		let min = 14;
		const windowWidth = this.state.windowWidth;
		const { desktop, tablet } = this.mediaQuery;
		if (!windowWidth) {
			return [min, max];
		}
		if (windowWidth > tablet) {
			max = 64;
			min = 16;
		}
		if (windowWidth > desktop) {
			max = 128;
			min = 20;
		}
		return [min, max];
	}
	
	render() {
		const { words } = this.props
		return (
			<Fullscreen>
				<ItemWrapper>
					<CloudWrapper>
						<StyledCloud
							options={{
								rotations: 1,
								rotationAngles: [0],
								fontSizes: this.getFontSizes()
							}}
							//size={[800,500]}
							words={words}
						/>
					</CloudWrapper>
					{/*statistics && <Statistics>{statistics}</Statistics>*/}
				</ItemWrapper>
			</Fullscreen>
		)
	}
}

export const WordCloud = connect(null, { setChosenCommentDisplay })(
	WordCloudComponent
)
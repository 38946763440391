import styled from 'styled-components'

export const Item = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`

export const Fill = styled(Item)`
	width: 100%;
	justify-content: flex-start;
	letter-spacing: 0.5px;
	font-size: 16px;
`
import styled from 'styled-components'
import { EditorPopIn } from './EditorPopIn';

export const StyledEditorPopIn = styled(EditorPopIn)`
	border-radius: 4px 4px 0px 0px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background: rgba(0, 0, 0, 0.04);
	border-radius: 4px 4px 0px 0px;
	border-bottom: 1px solid #9E9E9E;
	/*padding: 12px;*/
	.label-and-field
	{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		margin-right: 8px;
		width: 100%;
	}
	label
	{
		font-size: 12px;
		letter-spacing: 0.4px;
		color: rgba(0, 0, 0, 0.54);
	}
	input[type=text]
	{
		background: rgba(0,0,0,0.05);
		border: none;
		font-size: 16px;
		letter-spacing: 0.15px;
		width: 100%;
	}
	
`
import React from 'react'
import { Print, Fullscreen, KeyboardArrowLeft } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import FlexAroundRow from '../common/flex/FlexAroundRow'
import { TopBarLink } from './TopBarLink'
import { TopBarButton } from './TopBarButton'

const Text = styled.p`
	font-weight: bold;
	font-size: 20px;
	letter-spacing: 0.15px;
	margin: 0;
`

const FlexCenter = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`

const Wrap = styled(FlexCenter)`
	margin-left: 30px;
	margin-right: 30px;
	padding: 8px; /* Hitbox helper */
	cursor: pointer;
`

const TopBarStyle = styled(FlexCenter)`
	justify-content: space-between;
	height: 80px;
	@media print
	{
		display: none;
	}
	
	font-weight: bold;
	font-size: 20px;
	letter-spacing: 0.15px;
	margin: 0;
	/*background-color: white;
	border-bottom: 1px solid #E5E5E5;*/
`

const Back = ({ path, onClick }) => {
	const { t } = useTranslation()
	if (!onClick) return (
		<TopBarLink to={ path } >
			<KeyboardArrowLeft />
			<Text>{t("topbar.back")}</Text>
		</TopBarLink>
	)
	return (
		<TopBarButton onClick={onClick} >
			<KeyboardArrowLeft />
			<Text>{t("topbar.back")}</Text>
		</TopBarButton>
	)
}

const TopBar = ({
	children,
	print,
	fullscreen,
	onClickPrint,
	onClickFullscreen,
	noBackButton,
	backPath="../",
	onClickBack,
	...rest
}) => {
	const { t } = useTranslation();
	return (
		<TopBarStyle {...rest}>
			<FlexAroundRow>
				{ !noBackButton && <Back path={backPath} onClick={onClickBack}/> }
			</FlexAroundRow>
	
			{children}
			
			{
				(print || fullscreen) && (
					<FlexAroundRow>
						{
							print ?
								<Wrap onClick={() => {
									console.log("Clicked print button")
									if (onClickPrint) onClickPrint()
									else window.print()
									
								}}>
									<Text>
										{t("topbar.print")}
									</Text>
									<Print />
								</Wrap> : <></>
						}
						{
							fullscreen ?
								<Wrap onClick={() => {
									console.log("Clicked fullscreen button")
									onClickFullscreen()
								}}>
									<Text>
										{t("topbar.full-screen")}
									</Text>
									<Fullscreen />
								</Wrap> : <></>
						}
					</FlexAroundRow>
				)
			}

		</TopBarStyle>
	)
}

export default TopBar
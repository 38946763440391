import React, { useState } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
//import { Link } from '@reach/router'
import { Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import { Helmet } from "react-helmet"

import { performLogin } from "../../redux/actions"

//import bg from '../../img/Outside_summer_2_1920.png'
//import bg from '../../img/landing-title-background.png'
//import logo from '../../img/edu-g-s.png'
//import logo from '../../img/logo-edu-1x.png'
//import logo from '../../img/LogoEduLarge.png'
import backend from "../../apis/backend"
//import { ValidationField } from '../forms/ValidationField';
//import { SquareButton } from '../dashboard/SquareButton';
import { LoginButton } from "../landing/buttons/LoginButton"
import { ValidationMessage } from "../ValidationMessage"
//import { LogoLarge } from '../landing/LogoLarge'
import { PATH_FORGOT_PASSWORD } from "../../constants"
import { SquareButton } from "../dashboard/SquareButton"
import { LoginField } from "../landing/LoginField"
import { LoginTitle } from "../landing/LoginTitle"
import LoginBackground from "../landing/LoginBackground"

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${ValidationMessage} {
        background-color: white;
        border-radius: 8px;
        padding: 12px 16px;
    }
`
/*
const Logo = styled.img.attrs({ src: logo, alt: "My Child Lebensborn EDU" })`
	max-width: 100%;
`*/

/*const Logo = () => (
	<Img src={logo} alt="My Child Lebensborn EDU" />
)*/

const LoginSchema = Yup.object().shape({
    email: Yup.string()
        .email("validation.email")
        .max(254, "validation.too-long")
        .required("validation.required"),
    password: Yup.string()
        //.min(8, "validation.too-short")
        .max(255, "validation.too-long")
        .required("validation.required"),
})

const LoginPathField = ({ name, ...rest }) => (
    <LoginField name={name} i18nPath="login" {...rest} />
)

const Login = ({ performLogin, location }) => {
    const { t } = useTranslation()
    const [forgotMessage, setForgotMessage] = useState(undefined)
    if (forgotMessage)
        return (
            <LoginBackground>
                <LoginTitle>{forgotMessage}</LoginTitle>
            </LoginBackground>
        )
    return (
        <LoginBackground>
            <Helmet>
                <title>Logg inn</title>
                <meta
                    property="og:url"
                    content="https://www.mychildlebensbornedu.no/login"
                />
                <meta property="og:title" content="Login" />
                <meta
                    property="og:description"
                    content="Innloggingsside for My Child Lebensborn EDU."
                />
            </Helmet>
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                }}
                validationSchema={LoginSchema}
                onSubmit={(values, actions) => {
                    console.log("Submitted login form")
                    //console.log(values, actions)

                    backend
                        .post("/login", {
                            email: values.email,
                            password: values.password,
                        })
                        .then((response) => {
                            console.log("Successful response", response)
                            const { token, isAdmin } = response.data.data
                            const userIsAdmin = isAdmin ? true : false
                            console.log("token:", token, "isAdmin:", isAdmin)
                            performLogin(token, userIsAdmin)
                        })
                        .catch((error) => {
                            console.log("Failure response", error.response)
                            actions.resetForm({ ...values })
                            let errs = {}

                            for (const i in error?.response?.data?.errors ??
                                []) {
                                const e = error?.response?.data?.errors?.[i]
                                //console.log("Looping", e)

                                if (e.code === 10000) {
                                    //console.log("Code is 10000, setting status: ", e.message)
                                    actions.setStatus(e.message)
                                    break
                                } else {
                                    //console.log("Code is not 10000, setting error: e.field", e.field, "e.message", e.message)
                                    errs = {
                                        ...errs,
                                        [e.field]: e.message,
                                    }
                                }
                            }
                            actions.setErrors(errs)
                        })
                }}
                render={(props) => {
                    const { values, /*errors,*/ touched, status, dirty } = props
                    //console.log("dirty", dirty, "props", props, "errors", errors, "touched",touched, "Status", status)
                    return (
                        <StyledForm>
                            <LoginPathField
                                name="email"
                                touched={touched}
                                // errors={errors}
                                //i18nPath="login"
                                type="email"
                            />

                            <LoginPathField
                                name="password"
                                touched={touched}
                                // errors={errors}
                                //i18nPath="login"
                                type="password"
                            />

                            {status && !dirty && (
                                <ValidationMessage>{status}</ValidationMessage>
                            )}
                            {status && !dirty && (
                                <SquareButton
                                    margin="16px"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        backend
                                            .post("/request-new-password", {
                                                email: values.email,
                                                resetPasswordUrl: `${location.origin}${PATH_FORGOT_PASSWORD}`,
                                            })
                                            .then((response) => {
                                                console.log(
                                                    "response:",
                                                    response
                                                )
                                                setForgotMessage(
                                                    response.data.data.message
                                                )
                                            })
                                            .catch((error) => {
                                                console.error(error)
                                            })
                                    }}
                                >
                                    {t("login.forgot-password")}
                                </SquareButton>
                            )}

                            <LoginButton type="submit">
                                {t("login.submit")}
                            </LoginButton>
                        </StyledForm>
                    )
                }}
            />
        </LoginBackground>
    )
}

export default connect(null, { performLogin })(Login)

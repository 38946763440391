import React from 'react'
import styled from 'styled-components'
import { Add } from '@material-ui/icons'
import { COLOR_GREEN } from '../../constants'

const Button = styled.button`
	background: ${COLOR_GREEN};
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 1px 10px rgba(0, 0, 0, 0.2);
	border-radius: 100px;
	color: white;
	border: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 12px 20px;
	cursor: pointer;
	white-space: ${ props => props.wrap ? "wrap" : "nowrap"};
`

const Text = styled.p`
	margin: 0;
	padding: 0;
	font-weight: 500;
	font-size: 14px;
	text-align: center;
	letter-spacing: 1.25px;
	text-transform: uppercase;
	text-decoration: none;
`

const AddStyled = styled(Add)`
	margin: -4px;
	margin-left: -8px;
	margin-right: 12px;
`

export const CircularButton = ({text, onClick, children, ...rest}) => {
	//console.log("Text:",text)
	//console.log("Children:",children)
	return (
		<Button
			onClick={onClick ? () => onClick() : undefined}
			{...rest}
		>
			<AddStyled />
			<Text>
				{text ? text : children}
			</Text>
		</Button>
	)
}
import styled from 'styled-components'

export const StyledLabelWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-right: 8px;
	width: 100%;
	label
	{
		font-size: 12px;
		letter-spacing: 0.4px;
		color: rgba(0, 0, 0, 0.54);
	}
	input[type=text]
	{
		background: rgba(0,0,0,0.05);
		border: none;
		font-size: 16px;
		letter-spacing: 0.15px;
		width: 100%;
	}
`
import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { Questions } from './Questions'
import Title from '../../common/text/Title'
import { fetchLocalSelection } from '../../../redux/actions'
//import { Link } from '@reach/router';
import { MustChooseGroup } from '../../dashboard/MustChooseGroup'
import { Loading } from '../../dashboard/Loading'
import TopBar from '../../dashboard/TopBar'
import { Tip } from '../../common/text/Tip'

class AskPlayersComponent extends React.Component{
	
	/*state = {
		triedFetching: false
	}*/
	
	componentDidMount() {
		//fetch data if there are none in state
		const { groupId, fetchLocalSelection } = this.props
		//console.log("componentDidMount", groupId, "!groupId", !groupId)
		if (groupId === undefined) {
			fetchLocalSelection()
			//this.setState({triedFetching: true})
		}
	}
	
	render() {
		const { t, groupId, groupName } = this.props
		if (groupId === undefined) return <Loading />
		if (groupId === null) return <MustChooseGroup />
		
		return (
			<div>
				<TopBar />
				
				<Title>{t("dashboard.ask-players")} {groupName}</Title>
				<Tip>{t("dashboard.ask-players.tip")}</Tip>
				
				{
					//render question component if group is fetched. Question will then 
					groupId && <Questions groupId={groupId} />
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		groupId: state.selection.group,
		groupName: state.selection.groupName
	}
}

export const AskPlayers = connect(mapStateToProps, { fetchLocalSelection })(
	withTranslation()(AskPlayersComponent)
)
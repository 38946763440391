export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'

export const SET_CHOSEN_LANGUAGE = 'SET_CHOSEN_LANGUAGE'
export const FETCH_LOCAL_LANGUAGE = 'FETCH_LOCAL_LANGUAGE'

export const SELECTED_GROUP = 'SELECTED_GROUP'
export const SELECTED_GROUP_NAME = 'SELECTED_GROUP_NAME'
export const SELECTED_CHAPTER = 'SELECTED_CHAPTER'
export const SELECTED_COMMENT_DISPLAY = 'SELECTED_COMMENT_DISPLAY'
export const SELECTED_LANGUAGE = 'SELECTED_LANGUAGE'
export const FETCH_LOCAL_SELECTION = 'FETCH_LOCAL_SELECTION'

export const SET_CHOSEN_GROUP = 'SET_CHOSEN_GROUP'
export const SET_CHOSEN_CHAPTER = 'SET_CHOSEN_CHAPTER'
export const SET_CHOSEN_COMMENT_DISPLAY = 'SET_CHOSEN_COMMENT_DISPLAY'

export const WIPE_SELECTIONS = 'WIPE_SELECTIONS'

export const DISPLAY_NORMAL = 'DISPLAY_NORMAL'
export const DISPLAY_CLOUD = 'DISPLAY_CLOUD'
export const DISPLAY_BOARD = 'DISPLAY_BOARD'
export const DISPLAY_STATISTICS = 'DISPLAY_STATISTICS'

export const SET_LANGUAGE = 'SET_LANGUAGE'

export const FETCH_LICENSES = 'FETCH_LICENSES'

export const FETCH_SUPERGROUPS = 'FETCH_SUPERGROUPS'
export const CREATE_SUPERGROUP = 'CREATE_SUPERGROUP'
export const DELETE_SUPERGROUP = 'DELETE_SUPERGROUP'

export const FETCH_GROUPS = 'FETCH_GROUPS'
export const FETCH_GROUP = 'FETCH_GROUP'
export const CREATE_GROUP = 'CREATE_GROUP'
export const DELETE_GROUP = 'DELETE_GROUP'

export const FETCH_CHAPTER = 'FETCH_CHAPTER'

export const FETCH_QUESTIONS = 'FETCH_QUESTIONS'
export const CREATE_QUESTION = 'CREATE_QUESTION'
export const EDIT_QUESTION = 'EDIT_QUESTION'
export const DELETE_QUESTION = 'DELETE_QUESTION'
export const DELETE_QUESTION_ERROR = 'DELETE_QUESTION_ERROR'

export const FETCH_COMMENTS = 'FETCH_COMMENTS'
export const EDIT_COMMENT = 'EDIT_COMMENT'

export const TOGGLE_FILTER_VIEW = 'TOGGLE_FILTER_VIEW'
export const FETCH_FILTERS = 'FETCH_FILTERS'
export const TOGGLE_FILTER = 'TOGGLE_FILTER'

export const FETCH_ARTICLES = 'FETCH_ARTICLES'
export const FETCH_ARTICLE = 'FETCH_ARTICLE'
export const CREATE_ARTICLE = 'CREATE_ARTICLE'
export const EDIT_ARTICLE = 'EDIT_ARTICLE'
export const DELETE_ARTICLE = 'DELETE_ARTICLE'

export const FETCH_SPECIAL_ARTICLES = 'FETCH_SPECIAL_ARTICLES'

export const FETCH_PROFILE = 'FETCH_PROFILE'
export const EDIT_PROFILE = 'EDIT_PROFILE'

export const FETCH_FAVOURITES = 'FETCH_FAVOURITES'
export const SET_FAVOURITE = 'SET_FAVOURITE'
export const DELETE_FAVOURITE = 'DELETE_FAVOURITE'

export const FETCH_BUNDLES = 'FETCH_BUNDLES'

export const FETCH_USERS = 'FETCH_USERS'
export const FETCH_USERDATA = 'FETCH_USERDATA'
export const EDIT_USERDATA = 'EDIT_USERDATA'
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS'
export const FETCH_LICENSESTATS = 'FETCH_LICENSESTATS'
export const FETCH_LICENSEDATA = 'FETCH_LICENSEDATA'
export const EDIT_LICENSEDATA = 'EDIT_LICENSEDATA'

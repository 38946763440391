export const flexCenter = {
	display: "flex",
	alignItems: "center",
	justifyContent: "center"
}

export const flexColumn = {
	flexDirection: "column"
}

export const fullWidth = {
	width: "100%"
}

export const noMargins = {
	margin: 0,
	padding: 0
}
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import LicenseStatsListItem from './LicenseStatsListItem'
import { Item, ItemSlim, ItemWide, Centered, Right } from './common'

const LicenseStatsListStyle = styled.div`
	overflow-x: auto;
`

const LicenseStatsHeader = styled.div`
	display: flex;
	padding: 20px 26px;
	font-weight: 500;
	font-size: 16px;
	text-transform: uppercase;
	color: #828282;
`

const LicenseStatsList = ({children}) => {
	const { t } = useTranslation()

	console.log("Children", children)
	
	return (
		<LicenseStatsListStyle>
			<LicenseStatsHeader>
				<ItemWide>
					<Centered>
						{t("admin.licenses.list.owner")}
					</Centered>
				</ItemWide>

				<ItemWide>
					<Centered>
						{t("admin.licenses.list.email")}
					</Centered>
				</ItemWide>

				{/* <Item>
					<Centered>
						{t("admin.licenses.list.license")}
					</Centered>
				</Item> */}

				<Item>
					<Centered>
						{t("admin.licenses.list.devicesUsed")}
					</Centered>
				</Item>

				<Item>
					<Centered>
						{t("admin.licenses.list.devicesAllowed")}
					</Centered>
				</Item>

				<Item>
					<Centered>
						{t("admin.licenses.list.administrators")}
					</Centered>
				</Item>

				<Item>
					<Centered>
						{t("admin.licenses.list.expiry")}
					</Centered>
				</Item>

				<ItemSlim>
					<Centered>
						{t("admin.licenses.list.view")}
					</Centered>
				</ItemSlim>
			</LicenseStatsHeader>
			{children.map(
				({id, license, devices_used, devices_allowed, admins, expires_at, first_name, last_name, email, company_name, is_private}) => (
					<LicenseStatsListItem
						key={id}
						id={id}
						license={license}
						devices_used={devices_used}
						devices_allowed={devices_allowed}
						admins={admins}
						expires_at={expires_at}
						date={new Date(expires_at).toLocaleDateString('no-NO',  {dateStyle: 'short'})}
						first_name={first_name} 
						last_name={last_name}
						is_private={is_private}
						company_name={company_name}
						owner={!is_private ? company_name : first_name + " " + last_name}
						email={email}
					/>
				)
			)}
		</LicenseStatsListStyle>
	)
}

export default LicenseStatsList
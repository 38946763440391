import React from 'react'
import styled from 'styled-components'
import { Base } from './Base'
//import { Book } from '@material-ui/icons'
import { useTranslation } from 'react-i18next';
import { ReactComponent as ShoppingCart } from '../../../../img/icons/shopping-cart.svg'
import { PATH_ORDER } from '../../../../constants';

export const ButtonStyle = styled(Base)`
	/*background-color: #F7E2B8;
	border-color: #F4D8A4;*/
`

export const OrderButton = () => {
	const { t } = useTranslation()
	return <ButtonStyle icon={ShoppingCart} text={t("landing.order-license")} to={PATH_ORDER} />
}
import React from 'react'
//import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'

/*const StyledField = styled(Field)`
	width: 100%;
`*/

/*
	This was built to be fully reusable! If you want to use it in more than one place, extend it with style!
*/


export class PopInEditor extends React.Component {
	state = {
		editing: false,
		text: this.props.initialValue || ""
	}
	
	renderEditor() {
		const { onSubmit, initialValue, buttonText, label } = this.props
		return (
			<Formik
				initialValues={{
					text: initialValue || ""
				}}
				onSubmit={(values, actions) => {
					//console.log(values, actions)
					this.setState({editing: false})
					onSubmit(values.text)
				}}
				render={props => (
					<Form>
						<div className="label-and-input">
							{ label && <label htmlFor="name">{label}</label> }
							<Field type="text" name="text" autoFocus />
						</div>
						<button
							type="submit"
						>
							{buttonText}
						</button>
					</Form>
				)}
			/>
		)
	}
	
	render() {
		const { children, className } = this.props
		return (
			<div className={className}>
				{
					this.state.editing
						? this.renderEditor()
						: children( () => this.setState({editing: true}) )
				}
			</div>
		)
	}
}
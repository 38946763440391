import styled from 'styled-components'
import { ListCard } from './ListCard'

export const ListCardHeader = styled(ListCard)`
	background-color: transparent;
	border: none;
	text-transform: uppercase;
	color: #4F4F4F;
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 8px;
`
import React from 'react'
import styled from 'styled-components'
import { Wrapper, CommentItem, UsernameItem, CheckboxItem, SwitchItem } from './common';
import { useTranslation } from 'react-i18next';

const HeaderWrapper = styled(Wrapper)`
	background-color: transparent;
	border: none;
	text-transform: uppercase;
	color: #4F4F4F;
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 8px;
`

export const CommentTitleBar = () => {
	const { t } = useTranslation()
	return (
		<HeaderWrapper>
			<UsernameItem>{t("dashboard.comments.headers.username")}</UsernameItem>
			<CommentItem>{t("dashboard.comments.headers.comment")}</CommentItem>
			<CheckboxItem>{t("dashboard.comments.headers.hide")}</CheckboxItem>
			<SwitchItem>{t("dashboard.comments.headers.anonymous")}</SwitchItem>
		</HeaderWrapper>
	)
}
import { itemsAreInState } from './itemsAreInState'

export const getItemsFromState = (state, itemKeys, stateKey) => {
	if (!itemsAreInState(state, itemKeys, stateKey)) {
		//console.log("Items not in state:", itemKeys)
		return [] //empty array won't break maps
	}
	return itemKeys.map( (itemKey) => (
		state[stateKey][itemKey]
	))
}
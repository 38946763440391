import React from 'react'
import { connect } from 'react-redux'

import TopBarFiller from '../../dashboard/TopBarFiller';
import UsersList from './UsersList';
import { fetchUsers } from '../../../redux/actions'
import { withTranslation } from 'react-i18next';
import Title from '../../common/text/Title';
import { SearchBar} from './common';

class Users extends React.Component {

	state = {
		searchValue: ''
	}

	handleOnChange = e => {
		const {value, name} = e.currentTarget;
	  
		this.setState({...this.state, [name]: value});
	};

	componentDidMount() {
		this.props.fetchUsers()
	}

	render() {
		const { t } = this.props
		console.log("Fetch Users Props", this.props)

		const filteredUsers = this.props.users.filter(user => {
			return user.email.toLowerCase().includes(this.state.searchValue.toLowerCase()) ||
			user.name?.toLowerCase().includes(this.state.searchValue.toLowerCase());
		})

		const usersToRender = !this.state.searchValue ? this.props.users: filteredUsers

		return (
			<div>
				<SearchBar 
					placeholder="Search"
					type = "text"
					name = "searchValue"
					value={this.state.searchValue}
					onChange={this.handleOnChange}
				/>
				<TopBarFiller />
				<Title>{t("admin.users")}</Title>
				<UsersList>
					{usersToRender}
				</UsersList>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		isAdmin: state.auth.isAdmin,
		users: Object.values(state.users)
	}
}

export default connect(mapStateToProps, { fetchUsers })(
	withTranslation()(Users)
)
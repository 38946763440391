import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Title from '../common/text/Title'
import { toggleFilter } from '../../redux/actions'
import { SquareButton } from '../dashboard/SquareButton'
import { FlexRow } from '../common/flex'
import { Clear } from '@material-ui/icons'
import { COLOR_GREEN } from '../../constants'

const Wrapper = styled.div`
	position: absolute;
	z-index: 1000;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	padding-top: 32px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
`

const Modal = styled.div`
	background-color: white;
	border-radius: 32px;
	padding: 32px;
	box-shadow: 5px 5px 25px black;
`

const CheckboxStyle = styled.div.attrs( ({selected}) => ({
	selected: selected
}))`
	/*cursor: pointer;*/
	position: relative;
	display: flex;
	align-items: center;
	*
	{
		cursor: pointer;
	}
	label
	{
		user-select: none;
		display: flex;
		align-items: center;
		/*margin: 4px;*/
	}
	span
	{
		color: ${props => props.selected ? "white" : COLOR_GREEN};
		background-color: ${props => props.selected ? COLOR_GREEN : "transparent"};
		text-decoration: underline;
		padding-top: 4px;
		padding-bottom: 4px;
		padding-right: 4px;
	}
	svg
	{
		color: ${COLOR_GREEN};
	}
	input
	{
		text-decoration: underline;
		
		/* Hide checkbox */
		position: absolute;
		opacity: 0;
		height: 0;
		width: 0;
	}
	&:after
	{
		
	}
`

const Checkbox = ({label, name, selected, onChange, children}) => (
	<CheckboxStyle selected={selected}>
		<label className={selected ? "selected" : ""}>
			<span>{label || children}</span>
			<input type="checkbox" name={name} checked={selected} onChange={onChange}/>
			{selected && <Clear />}
		</label>
	</CheckboxStyle>
)

const GoalsModalComponent = ({ goals, onClose, toggleFilter }) => {
	const { t } = useTranslation()
	//console.log({ goals })
	return (
		<Wrapper onClick={() => onClose()}>
			<Modal onClick={(e) => e.stopPropagation()}>
				<Title>{t("filters.goal")}</Title>
				{
					Object.keys(goals).map(index => {
						const { id, selected, translationKey } = goals[index]
						return (
							<Checkbox
								key={index}
								name={id}
								selected={selected}
								onChange={() => {
									//console.log("onChange", id)
									toggleFilter("goal", index)
								}}
							>
								{t(`filters.${translationKey}`)}
							</Checkbox>
						)
					})
				}
				<FlexRow>
					<SquareButton onClick={() => onClose()}>
						{t("filters.goals.modal.close-button")}
					</SquareButton>
				</FlexRow>
			</Modal>
		</Wrapper>
	)
}

const mapStateToProps = state => {
	//console.log("mapState", state, ownProps)
	//console.log("ownProps.category", ownProps.category)
	//console.log("state.filters[ownProps.category]", state.filters[ownProps.category])
	return {
		goals: state.filters["goal"]
	}
}

export const GoalsModal = connect(mapStateToProps, { toggleFilter })(GoalsModalComponent)
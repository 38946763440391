import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	@media screen and (min-width: ${props => props.breakpoint || "500px"})
	{
		/*padding: 64px;*/
		flex-wrap: nowrap;
		div:first-child
		{
			/*margin-right: 8px;*/
		}
		div:last-child
		{
			/*margin-left: 8px;*/
		}
	}
	
`

const Column = styled.div`
	display: flex;
	justify-content: center;
	/*flex: 1;
	flex-shrink:1;*/
	width: 100%;
	margin-bottom: 16px;
	flex-shrink: 1;

	@media screen and (min-width: ${props => props.breakpoint || "500px"})
	{
		&:last-of-type
		{
			margin-left: 8px;
			margin-right: 0;
		}
		&:first-of-type
		{
			margin-right: 8px;
			margin-left: 0;
		}
		${props => props.reverse && css`
			&:last-of-type
			{
				order: -1;
				margin-right: 8px;
				margin-left: 0;
			}
			&:first-of-type
			{
				order: 1;
				margin-left: 8px;
				margin-right: 0;
			}
		`}
	}
	img
	{
		max-width: 100%;
	}
	&:last-of-type
	{
		margin-bottom: 0;
	}
`

const ResponsiveColumns = ({left, right, reverseAfterBreak=false, breakpoint, ...rest}) => {
	if (!breakpoint) {
		throw new Error("WTF! No breakpoint provided to RESPONSIVE column! Hellooooo?!")
	}
	return (
		<Wrapper breakpoint={breakpoint} {...rest}>
			<Column breakpoint={breakpoint} reverse={reverseAfterBreak}>
				{left}
			</Column>
			
			<Column breakpoint={breakpoint} reverse={reverseAfterBreak}>
				{right}
			</Column>
		</Wrapper>
	)
}

export default ResponsiveColumns